import React, { useContext, useState, useEffect } from "react";
import { useMany, HttpError, BaseRecord } from "@refinedev/core";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";

//Local Components
import { ActivityAttendeesReport } from "components/reports/activityAttendeesReport";
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { Link } from "react-router-dom";

export const ActivityAttendeesReportPage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);

  const [ids, setIds] = useState([]);
  const { data, isLoading, isError, isFetching } = useMany<any, HttpError>({
    resource: "activities",
    ids,
  });
  const activities = data?.data ?? [];

  useEffect(() => {
    setBreadCrumbsTitle("Reports");
    setBreads([{ label: "Export Reports", path: "/reports?activityattendees=true" }]);
  }, []);
  if (isFetching === true ) {
      return (
        <>
          <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
          <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
            <div className="flex-row-fluid" id="content">
              <Box sx={{ display: "flex" }}>
                <CircularProgress color="primary" /> <span className="buildingreportmessage">Generating Report</span>
              </Box>
            </div>
          </main>
        </>
      );
    }

  if (isError) {
    return (
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <div>Error: Could not load T/TA Activity data.</div>;
        </div>
      </main>
    );
  }
  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <div className="content flex-row-fluid" id="content">
            <div className="card">
              <div className="card-header card-header-stretch">
                <div className="card-title d-flex align-items-center">
                  <span className="fw-bold m-0 text-gray-800 h3">Export Reports</span>
                </div>

                <div className="card-toolbar m-0">
                  <ul className="nav nav-stretch nav-line-tabs fw-semibold border-transparent flex-nowrap" role="tablist" id="report_tabs">
                    <li className="nav-item px-0" role="presentation">
                      <Link to={"/reports"} className="nav-link fs-4 text-dark" data-bs-toggle="tab" role="tab" aria-selected="true" aria-controls="service_request" id="tab_1" tabIndex={0}>
                        Service Request
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link to={"/reports/activityReport"} className="nav-link fs-4 text-dark" data-bs-toggle="tab" role="tab" aria-selected="true" tabIndex={-1} aria-controls="ta_activities" id="tab_2">
                        T/TA Activities
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link fs-4 text-dark active" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1} aria-controls="staff" id="tab_3">
                        Attendees
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card-body">
                <div className="tab-content">
                  <div id="sr_export" className="card-body p-0 tab-pane fade fade" role="tabpanel" aria-labelledby="tab_1"></div>

                  <div id="ta_export" className="card-body p-0 tab-pane fade" role="tabpanel" aria-labelledby="tab_2">
                    Add Table Here 2
                  </div>

                  <div id="attendee_export" className="card-body p-0 tab-pane active show" role="tabpanel" aria-labelledby="tab_3">
                    <ActivityAttendeesReport data={activities} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
