import React, { useContext, useState, useEffect } from "react";
import { useMany, HttpError } from "@refinedev/core";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";

//Local Components
import { DashboardList } from "components/dashboard/list";
import { BreadcrumbContext } from "contexts/breadcrumbContext";
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { useFormState } from "react-hook-form";

export const DashboardListPage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);
  const [isFilterChecked, setIsFilterChecked] = useState<boolean>(false);
  const [isExpenseChecked, setIsExpenseChecked] = useState<boolean>(false);
  const [ids, setIds] = useState([]);

  //const { data, isLoading, isError } = useMany<any, HttpError>({
  //  resource: "activities",
  //  ids,
  //});
//
  //const activities = data?.data ?? [];

  useEffect(() => {
    setBreadCrumbsTitle("Dashboard");
    setBreads([{ label: "Dashboard", path: "/dashboard" }]);
  }, []);

  const onFilterChange = () => {
    if (isFilterChecked === false) {
      setIsFilterChecked(true);
    } else {
      setIsFilterChecked(false);
    }
  };

  const onExpenseChange = () => {
    if (isExpenseChecked === false) {
      setIsExpenseChecked(true);
    } else {
      setIsExpenseChecked(false);
    }
  };
  const handleFilterResults = () => {
    setIsFilterChecked(false);
  };

  //if (isError) {
  //  return <div>Error: Could not load T/TA Activity data for Dashboard.</div>;
  //}

  return (
    <>
      <Breadcrumbs
        breadcrumbsTitle={breadCrumbsTitle}
        breadcrumbs={breads}
        onFilterClicked={() => {
          //console.log("here123", "here132");
          onFilterChange();
        }}
        onExpenseClicked={() => {
          //console.log("here123", "here132");
          onExpenseChange();
        }}
      />

      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <DashboardList
            
            
            isFilterExpanded={isFilterChecked}
            isExpenseExpanded={isExpenseChecked}
            onFilterResults={handleFilterResults}
          />
        </div>
      </main>
    </>
  );
};
