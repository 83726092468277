import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import ExportDataGrid from "components/datagrid/exportDataGrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge } from "components";
import { BreadcrumbContext } from "contexts/breadcrumbContext";
import { hsToStringCurrency, parseCamelCaseToSpaces } from "utils/strings";

const NO_ROWS_LABEL = "No T/TA Activities.";

type ActivityListProps = {
  data: BaseRecord[];
};

export const ActivityReport: React.FC<ActivityListProps> = ({ data }) => {
  const [activityData, setActivityData] = useState<BaseRecord[]>([]);
  const [filteredApprovalsData, setFilteredApprovalsActivityData] = useState<BaseRecord[]>([]);
  const [filteredDataFromOdata, setFilteredActivityDataFromOdata] = useState<BaseRecord[] | null>(null);
  const [filteredDataForGrid, setFilteredActivityDataForGrid] = useState<BaseRecord[]>([]);
  const [noRowsLabel, setNoRowsLabel] = useState<string>(NO_ROWS_LABEL);
  const { filterActvitiesByPendingApprovals, setFilterActvitiesByPendingApprovals: setShouldFilterPendingApprovals, getRecordStatusesForUserApproval } = useContext(LoggedInUserContext);
  const { getLookupDescription, getLookupDescriptions } = useContext(LookupsContext);
  const { setBreadcrumbs } = useContext(BreadcrumbContext);

  
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 100,

      renderCell: function (params: any) {
        return (
          <Link to={`/activities/read/${encodeURIComponent(encryptData(params.row.id))}`} className="text-gray-700 text-hover-primary mb-1">
            TA-{params.row.id}
          </Link>
        );
      },
      valueGetter(params) {
        return "TA-" + params.row.id;
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      headerClassName: "fw-bold cell-align-top",
      flex: 1,
      minWidth: 200,
      cellClassName: "wrap-text cell-align-top",

      renderCell: (params) => {
        return <div>{params.row.title}</div>;
      },
    },

    {
      field: "descriptionNeedProblem",
      headerName: "NEED OR PROBLEM",
      minWidth: 300,
      cellClassName: "wrap-text cell-align-top",
      hide: true,
      renderCell: (params) => {
        return <div>{params.row.descriptionNeedProblem}</div>;
      },
    },
    {
      field: "description",
      headerName: "ACTIVITY DESCRIPTION",
      minWidth: 300,
      cellClassName: "wrap-text cell-align-top",
      hide: true,
      renderCell: (params) => {
        return <div>{params.row.description}</div>;
      },
    },
    {
      field: "serviceRequest_Lookup_ServiceTopics",
      headerName: "TOPIC AREA",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      renderCell: function (params: any) {
        return <div>{getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ")}</div>;
      },
      valueGetter(params) {
        return getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ");
      },
      hide: true,
    },
    {
      field: "taMethods_VirtualCode",
      headerName: "T/TA METHOD (VIRTUAL)",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescription(params.row.taMethods_VirtualCode);
      },
      hide: true,
    },
    {
      field: "taMethods_FaceToFaceCode",
      headerName: "T/TA METHOD (FACE TO FACE)",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescription(params.row.taMethods_FaceToFaceCode);
      },
      hide: true,
    },
    {
      field: "lookup_SamahsaPriorities",
      headerName: "SAMHSA PRIORITIES",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_SamhsaPriorities).join(" | ");
      },
      hide: true,
    },
    {
      field: "lookup_ServiceCategories",
      headerName: "ACTIVITY CATEGORY",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_ServiceCategories).join(" | ");
      },
      hide: true,
    },
    {
      field: "activityStartDate",
      headerName: "START DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      valueGetter(params) {
        if (dayjs(params.row.activityStartDate).isValid() === true) {
          return dayjs(params.row.activityStartDate).format("MM/DD/YYYY");
        }
      },
      renderCell: (params) => {
        if (dayjs(params.row.activityStartDate).isValid() === true) {
          return <div>{dayjs(params.row.activityStartDate).format("MM/DD/YYYY")}</div>;
        }

        return <div></div>;
      },
      hide: true,
    },
    {
      field: "activityEndDate",
      headerName: "END DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",

      valueGetter(params) {
        if (dayjs(params.row.activityEndDate).isValid() === true) {
          return dayjs(params.row.activityEndDate).format("MM/DD/YYYY");
        }
      },
      renderCell: (params) => {
        if (dayjs(params.row.activityEndDate).isValid() === true) {
          return <div>{dayjs(params.row.activityEndDate).format("MM/DD/YYYY")}</div>;
        }
      },
      hide: true,
    },
    {
      field: "projectOfficerApprovalDeadline",
      headerName: "DEADLINE DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,

      valueGetter(params) {
        if (dayjs(params.row.projectOfficerApprovalDeadline).isValid() === true) {
          return dayjs(params.row.projectOfficerApprovalDeadline).format("MM/DD/YYYY");
        }
      },
      renderCell: (params) => {
        if (dayjs(params.row.projectOfficerApprovalDeadline).isValid() === true) {
          return <div>{dayjs(params.row.projectOfficerApprovalDeadline).format("MM/DD/YYYY")}</div>;
        }

        return <div></div>;
      },
      hide: true,
    },
    {
      field: "lookup_ServiceAudience_Populations",
      headerName: "POPULATIONS",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_ServiceAudience_Populations).join(" | ");
      },
      hide: true,
    },
    {
      field: "lookup_ServiceAudience_Additional",
      headerName: "ADDITIONAL AUDIENCE",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_ServiceAudience_Additional).join(" | ");
      },
      hide: true,
    },
    {
      field: "coachingForSuccess",
      headerName: "COACHING FOR SUCCESS?",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return params.row.coachingForSuccess === true ? "Yes" : "";
      },
      hide: true,
    },
    {
      field: "clientsInvited",
      headerName: "CLIENTS INVITED",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        let finalGranteeSelections: string[] = [];

        for (const sel of params.row.granteeSelections) {
          finalGranteeSelections.push(
            `${getLookupDescription(sel.regionCode)} > ${getLookupDescription(sel.granteeTypeCode)} > ${getLookupDescription(sel.granteeCode)} > ${getLookupDescription(sel.grantProgramCode)}`
          );
        }

        return finalGranteeSelections.join(" | ");
      },
      hide: true,
    },
    
    {
      field: "managingStaffUserName",
      headerName: "STAFF PLANNING AND DELIVERING THE ACTIVITY",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return params.row?.managingStaffUserName;
      },
      hide: true,
    },
    {
      field: "teamCode",
      headerName: "T/TA ACTIVITY TEAM",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter: (params) => {
        return getLookupDescription(params.row?.teamCode);
      },
      hide: true
    },

    {
      field: "numStaffHours",
      headerName: "STAFF ACTUAL HOURS",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,

      renderCell: (params) => {
        return <div>{params.row.numStaffHours}</div>;
      },
    },
    //{
    //  field: "staffExpenses",
    //  headerName: "STAFF ACTUAL RATES",
    //  flex: 1,
    //  cellClassName: "wrap-text cell-align-top",
    //  minWidth: 200,
//
    //  renderCell: (params) => {
    //    return <div>{hsToStringCurrency(params.row.staffExpenses)}</div>;
    //  },
    //},
//
    

{
      field: "nonStaffExpenses",
      headerName: "ACTIVITY ACTUAL EXPENSES",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      renderCell: (params) => {
        return <div>{hsToStringCurrency(params.row.nonStaffExpenses)}</div>;
      },
    },
    {
      field: "materialsCost",
      headerName: "MATERIALS COST",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      renderCell: (params) => {
        return <div>{hsToStringCurrency(params.row?.materialsCost)}</div>;
      },

      hide: true
    },
    {
      field: "actualActivityHours",
      headerName: "ACTUAL DELIVERY HOURS",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter: (params) => {
        return params.row?.deliveryHours;
      },
      hide: true
    },
    {
      field: "granteeInvolvement",
      headerName: "GRANTEE INVOLVEMENT",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return params.row.granteeInvolvement;
      },
      hide: true,
    },
    {
      field: "specialNeeds",
      headerName: "SPECIAL NEEDS",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return params.row.specialNeeds;
      },
      hide: true,
    },
    {
      field: "focusArea",
      headerName: "STRATEGIC PREVENTION FRAMEWORK FOCUS AREAS",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.focusArea?.lookup_FocusAreas)?.join(" | ");
      },
      hide: true,
    },
    {
      field: "focusAreaAssessment",
      headerName: "ASSESSMENT",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Assessment != null) {
          params.row?.focusArea?.lookup_FocusAreas_Assessment.forEach((fa: string, idx: any) => {
            if (fa === "FA_Assessment_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.assessment_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },
    {
      field: "focusAreaCapacity",
      headerName: "CAPACITY",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Capacity != null) {
          params.row?.focusArea?.lookup_FocusAreas_Capacity.forEach((fa: string, idx: any) => {
            if (fa === "FA_Capacity_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.capacity_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },
    {
      field: "focusAreaEvaluation",
      headerName: "EVALUATION",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Evaluation != null) {
          params.row?.focusArea?.lookup_FocusAreas_Evaluation.forEach((fa: string, idx: any) => {
            if (fa === "FA_Evaluation_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.evaluation_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },
    {
      field: "focusAreaImplementation",
      headerName: "IMPLEMENTATION",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Implementation != null) {
          params.row?.focusArea?.lookup_FocusAreas_Implementation.forEach((fa: string, idx: any) => {
            if (fa === "FA_Implementation_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.implementation_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },

    {
      field: "focusAreaPlanning",
      headerName: "PLANNING",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Planning != null) {
          params.row?.focusArea?.lookup_FocusAreas_Planning.forEach((fa: string, idx: any) => {
            if (fa === "FA_Planning_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.planning_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },
    {
      field: "approvalWorkflowState",
      flex: 1,
      headerName: "APPROVAL STATUS",
      cellClassName: "cell-align-top",
      minWidth: 200,
      renderCell: function (params: any) {
        return <ApprovalBadge status={params.row.approvalWorkflowState?.currentStatus} />;
      },
      valueGetter(params) {
        return parseCamelCaseToSpaces(params.row.approvalWorkflowState?.currentStatus);
      },
    },
  ];

  useEffect(() => {
    setActivityData(data); //save fuller data set to state
  }, [data]);
  return <ExportDataGrid rows={activityData} columns={columns} noRowsLabel={noRowsLabel} fileName={"activitiesreport"} 
  sortModel={[{field: 'id', sort: 'asc'}]} />;
};
