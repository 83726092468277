import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { HSStatFormat, RecordStatus } from "types/enums";

import { LookupsContext } from "contexts/lookupsContext";
import HighchartsReact from "highcharts-react-official";
import { HSStat } from "components/charts/HSStat";
import { Control } from "react-hook-form";
import { ActivityTotals } from "types/chartData";
type ActivityChartProps = {
  activityTotals?: ActivityTotals[];
  filterForm?: Control;
  isLoading?: boolean;
};



export const ActivityActualHoursStat: React.FC<ActivityChartProps> = ({ activityTotals, filterForm, isLoading }) => {
  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const { getLookupDescription } = useContext(LookupsContext);
  const [statVal, setStatVal] = useState<number>(0);
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  
  useEffect(() => {
  
    let val = 0;



    if (activityTotals != null)
    {
    
      val = activityTotals?.find(at => at?.type === "DeliveryHours")?.value ?? 0;
    }



    setStatVal(val);
  }, [activityTotals, isLoading]);

  let val = 0;

  return <HSStat reportCode="ACTIVITYNUMSTAT" val={statVal} isLoading={isLoading} hsFormat={HSStatFormat.HSFormattedInteger} />;
};
