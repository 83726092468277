import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import { axiosInstance } from "index";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { ChartData } from "types/chartData";
import { API_URL } from "App";
import { Control } from "react-hook-form";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { HSFilterCriteria } from "types/filterCriteria";
import ServerSideDataGrid from "components/datagrid/serverSideDataGrid";

type ActivityChartProps = {
  filterForm?: Control;
  isLoading?: boolean;
  hsFilters?: HSFilterCriteria;

  filteredDataForGrid: BaseRecord[];

  updateGrid: (searchVal: string, page: number, columnOrderActive: string, columnSortDir: string) => void;

  totalNumActivities?: number;
};

export const ActivityListChart: React.FC<ActivityChartProps> = ({ hsFilters, filteredDataForGrid, updateGrid, isLoading, totalNumActivities }) => {
  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);

  const { getLookupDescription, getLookupDescriptions } = useContext(LookupsContext);

  const [page, setPage] = useState<number>(0);

  const [searchVal, setSearchVal] = useState<string | undefined>("");
  const [totalNumRows, setTotalNumRows] = useState<number>(1);

  const [columnOrderActive, setColumnOrderActive] = useState<string>("createDate");
  const [columnSortDir, setColumnSortDir] = useState<string>("desc");

  const [activityIsLoading, setActivityIsLoading] = useState<boolean>(false);

  const onColumnOrderChange = (orderColumnName?: string, orderDir?: string) => {
    setColumnOrderActive(orderColumnName ?? "");

    setColumnSortDir(orderDir ?? "");
  };

  useEffect(() => {
    updateGrid(searchVal ?? "", page, columnOrderActive, columnSortDir);

    let pendingCSV: string = "";
  }, [searchVal, page, columnOrderActive, columnSortDir]);

  useEffect(() => {}, [filteredDataForGrid]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      flex: 0.75,
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return (
          <Link to={`/activities/read/${encodeURIComponent(encryptData(params.id))}`} className="text-gray-700 text-hover-primary mb-1">
            TA-{params.row.id}
          </Link>
        );
      },
      valueGetter(params) {
        return "TA-" + params.row.id;
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      headerClassName: "fw-bold",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return <div>{params.row.title}</div>;
      },
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      minWidth: 300,
      cellClassName: "wrap-text cell-align-top",

      renderCell: function (params: any) {
        return <div>{params.row.description}</div>;
      },
    },
    {
      field: "activityStartDate",
      headerName: "DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {(params.row.activityStartDate ? dayjs(params.row.activityStartDate).format("MM/DD/YYYY") : "") +
              (params.row.activityEndDate ? " - " : "") +
              (params.row.activityEndDate ? dayjs(params.row.activityEndDate).format("MM/DD/YYYY") : "")}
          </>
        );
      },
      valueGetter(params) {
        return (
          (params.row.activityStartDate ? dayjs(params.row.activityStartDate).format("MM/DD/YYYY") : "") +
          (params.row.activityEndDate ? " - " : "") +
          (params.row.activityEndDate ? dayjs(params.row.activityEndDate).format("MM/DD/YYYY") : "")
        );
      },
    },
    {
      field: "serviceRequest_Lookup_ServiceTopics",
      headerName: "TOPIC",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return <>{getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ")}</>;
      },
      valueGetter(params) {
        return getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ");
      },
    },
    {
      field: "taMethods_Description",
      headerName: "TA METHOD",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {(params.row.taMethods_VirtualCode ? getLookupDescription(params.row.taMethods_VirtualCode) : "") +
              (params.row.taMethods_FaceToFaceCode ? getLookupDescription(params.row.taMethods_FaceToFaceCode) : "")}
          </>
        );
      },
      valueGetter(params) {
        return (
          (params.row.taMethods_VirtualCode ? getLookupDescription(params.row.taMethods_VirtualCode) : "") +
          (params.row.taMethods_FaceToFaceCode ? getLookupDescription(params.row.taMethods_FaceToFaceCode) : "")
        );
      },
    },
    {
      field: "approvalStatus",
      flex: 1,
      headerName: "APPROVAL STATUS",
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return <ApprovalBadge status={params.row.approvalStatus} />;
      },
      valueGetter(params) {
        return params.row.approvalStatus;
      },
    },
  ];

  return (
    <ServerSideDataGrid
      rows={filteredDataForGrid ?? []}
      columns={columns}
      pageSize={25}
      noRowsLabel={"No Activity Rows"}
      sortModel={[{ field: "createDate", sort: "desc" }]}
      setPage={setPage}
      onSearch={setSearchVal}
      searchVal={searchVal}
      totalNumRows={totalNumActivities}
      page={page}
      setOrder={onColumnOrderChange}
      isLoading={isLoading ?? false}
    />
  );
};
