import React, { useState, useEffect } from "react";

import { useMany, HttpError } from "@refinedev/core";

//import { API_URL, axiosInstance } from "App";


//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";


//Local Components
import { ServiceRequestList } from "components";

import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";




export const ServiceRequestListPage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);
  
  const [isFilterChecked, setIsFilterChecked] = useState<boolean>(false);

  const [ids, setIds] = useState([]);
  //const { data, isLoading, isError } = useMany<any, HttpError>({
  //  resource: "servicerequests",
  //  ids,
  //});
  //const serviceRequests = data?.data ?? [];

  
  
  useEffect(() => {
    setBreadCrumbsTitle("Service Request");
    setBreads([{ label: "Service Requests", path: "/servicerequests" }]);
  }, []);

  const onFilterChange = () => {
    if (isFilterChecked === false) {
      setIsFilterChecked(true);
    } else {
      setIsFilterChecked(false);
    }
  };

  const handleFilterResults = () => {
    setIsFilterChecked(false);
  };

  //if (isLoading) {
  //  return (
  //    <>
  //      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} showAddServiceRequest={true} onFilterClicked={onFilterChange} />
  //      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
  //        <div className="flex-row-fluid" id="content">
  //          <Box sx={{ display: "flex" }}>
  //            <CircularProgress color="primary" />
  //          </Box>
  //        </div>
  //      </main>
  //    </>
  //  );
  //}

  //if (isError) {
  //  return (
  //    <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
  //      <div>Error: Could not load Service Request data.</div>
  //    </main>
  //  );
  //}

  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} showAddServiceRequest={true} onFilterClicked={onFilterChange} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <ServiceRequestList isFilterExpanded={isFilterChecked} onFilterResults={handleFilterResults} />
        </div>
      </main>
    </>
  );
};
