import React, { useState, useEffect, useContext } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import ExportDataGrid from "components/datagrid/exportDataGrid";
import { encryptData } from "utils/crypto";
import { LookupsContext } from "contexts/lookupsContext";

import { ApprovalBadge } from "components";
import { parseCamelCaseToSpaces } from "utils/strings";

const NO_ROWS_LABEL = "No T/TA Activities.";

type ServiceRequestListProps = {
  data: BaseRecord[];
};

export const ServiceRequestReport: React.FC<ServiceRequestListProps> = ({ data }) => {
  const [serviceRequestData, setServiceRequestData] = useState<BaseRecord[]>([]);

  const { getLookupDescription, getLookupDescriptions } = useContext(LookupsContext);
  
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      flex: 0.75,
      cellClassName: "cell-align-top",
      minWidth: 100,
      renderCell: function (params: any) {
        return (
          <Link to={`/servicerequests/read/${encodeURIComponent(encryptData(params.row.id))}`} className="text-gray-700 text-hover-primary mb-1">
            SR-{params.row.id}
          </Link>
        );
      },
      valueGetter(params) {
        return "SR-" + params.row.id;
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      headerClassName: "fw-bold",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,

      renderCell: (params) => {
        return <div>{params.row.title}</div>;
      },
    },

    {
      field: "descriptionNeedProblem",
      headerName: "DESCRIPTION OF NEED OR PROBLEM",
      minWidth: 300,
      cellClassName: "wrap-text cell-align-top",
      renderCell: (params) => <div>{params.value}</div>,
      hide: true,
    },
    {
      field: "description",
      headerName: "DESCRIPTION OF SERVICE",
      minWidth: 300,
      cellClassName: "wrap-text cell-align-top",
      renderCell: (params) => <div>{params.value}</div>,
      hide: true,
    },
    {
      field: "lookup_TAMethods_Virtual",
      headerName: "T/TA METHODS (VIRTUAL)",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_TAMethods_Virtual).join(" | ");
      },
      hide: true,
    },
    {
      field: "lookup_TAMethods_FaceToFace",
      headerName: "T/TA METHODS (FACE TO FACE)",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_TAMethods_FaceToFace).join(" | ");
      },
      hide: true,
    },
    {
      field: "lookup_ServiceTopics",
      headerName: "TOPIC AREA",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      renderCell: function (params: any) {
        return <>{getLookupDescriptions(params.row.lookup_ServiceTopics).join(" | ")}</>;
      },
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_ServiceTopics).join(" | ");
      },
      hide: true,
    },
    {
      field: "lookup_SamhsaPriorities",
      headerName: "SAMHSA PRIORITIES",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_SamhsaPriorities).join(" | ");
      },
      hide: true,
    },

    {
      field: "lookup_ServiceCategory",
      headerName: "SERVICE CATEGORY",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row?.lookup_ServiceCategories).join(" | ");
      },
      hide: true,
    },

    {
      field: "serviceYearStartDate",
      headerName: "SERVICE YEAR START DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        if (dayjs(params.row.serviceYearStartDate).isValid() === true) {
          return dayjs(params.row.serviceYearStartDate).format("YYYY");
        }

        return "";
      },

      renderCell: (params: any) => {
        if (dayjs(params.row.serviceYearStartDate).isValid() === true) {
          return <span>{dayjs(params.row.serviceYearStartDate).format("YYYY")}</span>;
        }

        return "";
      },
    },

    {
      field: "serviceYearEndDate",
      headerName: "SERVICE YEAR END DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        if (dayjs(params.row.serviceYearEndDate).isValid() === true) {
          return dayjs(params.row.serviceYearEndDate).format("YYYY");
        }

        return "";
      },

      renderCell: (params: any) => {
        if (dayjs(params.row.serviceYearEndDate).isValid() === true) {
          return <span>{dayjs(params.row.serviceYearEndDate).format("YYYY")}</span>;
        }

        return "";
      },
    },

    {
      field: "fiscalYearsFormatted",
      headerName: "FISCAL YEAR (QUARTER)",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        let finalFiscalYears = [];

        if (params.row.fiscalYears != null) {
          for (const y of params.row.fiscalYears) {
            const tmpQuarters: string[] = [];
            if (y.q1 === true) {
              tmpQuarters.push("Q1");
            }
            if (y.q2 === true) {
              tmpQuarters.push("Q2");
            }
            if (y.q3 === true) {
              tmpQuarters.push("Q3");
            }
            if (y.q4 === true) {
              tmpQuarters.push("Q4");
            }

            finalFiscalYears.push(`${y.fiscalYear != null ? dayjs(y.fiscalYear).format("YYYY") : ""} ${tmpQuarters != null && tmpQuarters.length > 0 ? `(${tmpQuarters.join(",")})` : ""}`);
          }
        }

        return finalFiscalYears.join(" | ");
      },

      renderCell: (params: any) => {
        let finalFiscalYears = [];

        if (params.row.fiscalYears != null) {
          for (const y of params.row.fiscalYears) {
            const tmpQuarters: string[] = [];
            if (y.q1 === true) {
              tmpQuarters.push("Q1");
            }
            if (y.q2 === true) {
              tmpQuarters.push("Q2");
            }
            if (y.q3 === true) {
              tmpQuarters.push("Q3");
            }
            if (y.q4 === true) {
              tmpQuarters.push("Q4");
            }

            finalFiscalYears.push(`${y.fiscalYear != null ? dayjs(y.fiscalYear).format("YYYY") : ""} ${tmpQuarters != null && tmpQuarters.length > 0 ? `(${tmpQuarters.join(",")})` : ""}`);
          }
        }

        return finalFiscalYears.join(" | ");
      },
      hide: true,
    },
    {
      field: "granteeSelections",
      headerName: "CLIENTS - REGION & GRANTEE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        if (params.row.granteeSelections != null) {
          let finalGranteeSelections: string[] = [];

          for (const sel of params.row.granteeSelections) {
            finalGranteeSelections.push(
              `${getLookupDescription(sel.regionCode)} > ${getLookupDescription(sel.granteeTypeCode)} > ${getLookupDescription(sel.granteeCode)} > ${getLookupDescription(sel.grantProgramCode)}`
            );
          }

          return finalGranteeSelections.join(" | ");
        }
      },

      renderCell(params) {
        if (params.row.granteeSelections != null) {
          let finalGranteeSelections: string[] = [];

          for (const sel of params.row.granteeSelections) {
            finalGranteeSelections.push(
              `${getLookupDescription(sel.regionCode)} > ${getLookupDescription(sel.granteeTypeCode)} > ${getLookupDescription(sel.granteeCode)} > ${getLookupDescription(sel.grantProgramCode)}`
            );
          }

          return finalGranteeSelections.join(" | ");
        }
      },
      hide: true,
    },
    {
      field: "lookup_ServiceAudience_Populations",
      headerName: "POPULATIONS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_ServiceAudience_Populations).join(" | ");
      },
      hide: true,
    },

    {
      field: "lookup_ServiceAudience_Additional",
      headerName: "ADDITIONAL AUDIENCE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_ServiceAudience_Additional).join(" | ");
      },
      hide: true,
    },

    {
      field: "coachingForSuccess",
      headerName: "COACHING FOR SUCCESS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return params.row.coachingForSuccess != null && params.row.coachingForSuccess === true ? "Yes" : "No";
      },
      hide: true,
    },

    {
      field: "primaryGranteeName",
      headerName: "PRIMARY GRANTEE SERVICE RECIPIENT NAME",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      hide: true,
    },
    {
      field: "primaryGranteeEmail",
      headerName: "PRIMARY GRANTEE SERVICE RECIPIENT EMAIL",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      hide: true,
    },
    {
      field: "sendEvaluation",
      headerName: "SEND 60 DAY EVALUATIONS?",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return params.row.sendEvaluation === "True" ? "Yes" : "No";
      },
      renderCell(params) {
        return params.row.sendEvaluation === "True" ? "Yes" : "No";
      },
      hide: true,
    },

    {
      field: "managingStaffUserName",
      headerName: "SPTAC STAFF MANAGING THE SERVICE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      hide: true,
    },

    {
      field: "initiatingRegionalTeamCode",
      headerName: "INTIATING TEAM",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescription(params.row?.initiatingRegionalTeamCode);
      },
      hide: true,
    },

    {
      field: "lookup_RegionalTeams",
      headerName: "OTHER TEAMS INVOLVED IN SERVICE DELIVERY",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_RegionalTeams).join(" | ");
      },
      hide: true,
    },

    {
      field: "focusArea",
      headerName: "STRATEGIC PREVENTION FRAMEWORK FOCUS AREAS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        console.log("here12", getLookupDescriptions(params.row?.focusArea?.lookup_FocusAreas));

        if (params.row?.focusArea?.lookup_FocusAreas != null) {
          return getLookupDescriptions(params.row?.focusArea?.lookup_FocusAreas).join(" | ");
        }
      },
      renderCell(params) {
        if (params.row?.focusArea?.lookup_FocusAreas != null) {
          return getLookupDescriptions(params.row?.focusArea?.lookup_FocusAreas).join(" | ");
        }
      },
      hide: true,
    },
    {
      field: "focusAreaAssessment",
      headerName: "ASSESSMENT ELEMENTS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Assessment != null) {
          params.row?.focusArea?.lookup_FocusAreas_Assessment.forEach((fa: string, idx: any) => {
            if (fa === "FA_Assessment_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.assessment_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      renderCell(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Assessment != null) {
          params.row?.focusArea?.lookup_FocusAreas_Assessment.forEach((fa: string, idx: any) => {
            if (fa === "FA_Assessment_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.assessment_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },

    {
      field: "focusAreaCapacity",
      headerName: "CAPACITY ELEMENTS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Capacity != null) {
          params.row?.focusArea?.lookup_FocusAreas_Capacity.forEach((fa: string, idx: any) => {
            if (fa === "FA_Capacity_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.capacity_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      renderCell(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Capacity != null) {
          params.row?.focusArea?.lookup_FocusAreas_Capacity.forEach((fa: string, idx: any) => {
            if (fa === "FA_Capacity_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.capacity_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },

    {
      field: "focusAreaEvaluation",
      headerName: "EVALUATION ELEMENTS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Evaluation != null) {
          params.row?.focusArea?.lookup_FocusAreas_Evaluation.forEach((fa: string, idx: any) => {
            if (fa === "FA_Evaluation_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.evaluation_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      renderCell(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Evaluation != null) {
          params.row?.focusArea?.lookup_FocusAreas_Evaluation.forEach((fa: string, idx: any) => {
            if (fa === "FA_Evaluation_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.evaluation_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },
    {
      field: "focusAreaImplementation",
      headerName: "IMPLEMENTATION ELEMENTS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Implementation != null) {
          params.row?.focusArea?.lookup_FocusAreas_Implementation.forEach((fa: string, idx: any) => {
            if (fa === "FA_Implementation_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.implementation_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      renderCell(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Implementation != null) {
          params.row?.focusArea?.lookup_FocusAreas_Implementation.forEach((fa: string, idx: any) => {
            if (fa === "FA_Implementation_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.implementation_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },
    {
      field: "focusAreaPlanning",
      headerName: "PLANNING ELEMENTS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Planning != null) {
          params.row?.focusArea?.lookup_FocusAreas_Planning.forEach((fa: string, idx: any) => {
            if (fa === "FA_Planning_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.planning_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      renderCell(params) {
        const tmpFocusVals: string[] = [];
        if (params.row?.focusArea?.lookup_FocusAreas_Planning != null) {
          params.row?.focusArea?.lookup_FocusAreas_Planning.forEach((fa: string, idx: any) => {
            if (fa === "FA_Planning_Other") {
              tmpFocusVals.push(`Other: ${params.row?.focusArea?.planning_Other}`);
            } else {
              tmpFocusVals.push(getLookupDescription(fa));
            }
          });
          return tmpFocusVals.join(" | ");
        }
      },
      hide: true,
    },
    // {
    //   field: "lookup_ServiceAudience_Populations",
    //   flex: 1,
    //   headerName: "POPULATION",
    //   cellClassName: "wrap-text cell-align-top",
    //   renderCell: function (params: any) {
    //     return <>{getLookupDescriptions(params.row.lookup_ServiceAudience_Populations).join(" | ")}</>;
    //   },
    //   valueGetter(params) {
    //     return getLookupDescriptions(params.row.lookup_ServiceAudience_Populations).join(" | ");
    //   },
    // },

    {
      field: "approvalWorkflowState",
      flex: 1,
      headerName: "APPROVAL STATUS",
      cellClassName: "cell-align-top",
      minWidth: 200,
      renderCell: function (params: any) {
        return <ApprovalBadge status={params.row.approvalWorkflowState?.currentStatus} />;
      },
      valueGetter(params) {
        return parseCamelCaseToSpaces(params.row.approvalWorkflowState?.currentStatus);
      },
    },
  ];

  useEffect(() => {
    setServiceRequestData(data); //initially set to "full" data set
  }, [data]);

  return (
    <>
      
      
      
      <ExportDataGrid rows={serviceRequestData} columns={columns} noRowsLabel={NO_ROWS_LABEL} fileName={"servicerequestreport"} 
      sortModel={[{field: 'id', sort: 'asc'}]}/>
    </>
  );
};