import React, { useState, useEffect } from "react";
import { BaseRecord } from "@refinedev/core";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";
//Local Components
import { API_URL } from "App";
import { axiosInstance } from "index";
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { useParams } from "react-router-dom";
import { EvaluationsReport } from "components/reports/evaluationsReport";

export const EvaluationsReportPage: React.FC = () => {
  const params = useParams();
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const activityId: number = Number(params.id ?? 0);

  const [evaluations, setEvaluations] = useState<BaseRecord[]>([]);
  //const activityId: number = Number(decryptData(decodeURIComponent(params.id ?? "")));

  const [ids, setIds] = useState([activityId]);
  //  const { data, isLoading, isError } = useMany<any, HttpError>({
  //  resource: "evaluations/shows",
  //  ids,

  //});

  const getEvaluations = async () => {
    const response = await axiosInstance.get(`${API_URL}/activities/${activityId}/evaluations`, {
      params: {
        id: activityId,
        // $orderby: "title",
        // $top: 10,
        // $skip: 0,
      },
    });

    setBreadCrumbsTitle("Reports");

    let activityTitle = "";
    if (evaluations != null) {
      activityTitle = response?.data?.activityTitle;
    }

    setBreads([
      { label: activityTitle, path: `/activities/read/${activityId}` },
      { label: "Evaluation Report", path: "" },
    ]);

    setEvaluations(response?.data);
  };

  //const evaluations: BaseRecord[] = data?.data ?? [];

  useEffect(() => {

    



    setBreadCrumbsTitle("Reports");

    let activityTitle = "";
    setBreads([
      { label: activityTitle, path: `/activities/read/${activityId}` },
      { label: "Evaluation Report", path: "" },
    ]);

    getEvaluations();
  }, []);

  if (isLoading) {
    return (
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <Box sx={{ display: "flex" }}>
            <CircularProgress color="primary" />
          </Box>
        </div>
      </main>
    );
  }

  if (isError) {
    return (
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <div>Error: Could not load T/TA Activity data.</div>;
        </div>
      </main>
    );
  }
  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <div className="content flex-row-fluid" id="content">
            <EvaluationsReport data={evaluations} />
          </div>
        </div>
      </main>
    </>
  );
};
