import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";


import { HSStatFormat, RecordStatus } from "types/enums";
import { LookupsContext } from "contexts/lookupsContext";
import { Control } from "react-hook-form";
import { ActivityTotals } from "types/chartData";
import HighchartsReact from "highcharts-react-official";
import { HSStat } from "components/charts/HSStat";

type ActivityChartProps = {
  activityTotals?: ActivityTotals[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const ActivityHoursStat: React.FC<ActivityChartProps> = ({ activityTotals, filterForm, isLoading }) => {
  const [totalHours, setTotalHours] = useState<number>(0.0);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const { getLookupDescription } = useContext(LookupsContext);


  
  
  
  
  useEffect(() => {
  
    let val = 0;



    if (activityTotals != null)
    {
    
      val = activityTotals?.find(at => at?.type === "StaffHours")?.value ?? 0;
    }



    setTotalHours(val);
  }, [activityTotals, isLoading]);



 
  return <HSStat reportCode="ACTIVITYHOURSSTAT" val={totalHours} isLoading={isLoading} hsFormat={HSStatFormat.HSFormattedInteger} />;

};