import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { FormGroup, FormControlLabel, Switch, Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import ServerSideDataGrid from "components/datagrid/serverSideDataGrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { FilterCriteria, HSFilterCriteria } from "types/filterCriteria";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { BreadcrumbContext } from "contexts/breadcrumbContext";
import { GranteeSelectionFilterType } from "types/enums";

const NO_ROWS_LABEL = "No T/TA Activities.";
const NO_ROWS_LABEL_FILTERED = "No T/TA Activities match the filter criteria: ";
const NO_ROWS_LABEL_FILTERED_APPROVALS = "You have no additional T/TA Activities to Approve. Please deselect the filter in order to see all T/TA Activities.";

type ActivityListProps = {
  isFilterExpanded?: boolean;
  onFilterResults?: () => void;
};

export const ActivityList: React.FC<ActivityListProps> = ({ isFilterExpanded, onFilterResults }) => {
  const [activityData, setActivityData] = useState<BaseRecord[]>([]);
  const [filteredApprovalsData, setFilteredApprovalsActivityData] = useState<BaseRecord[]>([]);
  const [filteredDataFromOdata, setFilteredActivityDataFromOdata] = useState<BaseRecord[] | null>(null);
  const [filteredDataForGrid, setFilteredActivityDataForGrid] = useState<BaseRecord[]>([]);
  const [noRowsLabel, setNoRowsLabel] = useState<string>(NO_ROWS_LABEL);
  const { filterActvitiesByPendingApprovals, 
    setFilterActvitiesByPendingApprovals: setShouldFilterPendingApprovals, 
    getRecordStatusesForUserApproval,
    userPermissions
  } = useContext(LoggedInUserContext);
  const { getLookupDescription, getLookupDescriptions } = useContext(LookupsContext);

  const { setBreadcrumbs } = useContext(BreadcrumbContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);

  const [hsFilters, setHSFilters] = useState<HSFilterCriteria>({});
  const [searchVal, setSearchVal] = useState<string | undefined>("");
  const [totalNumRows, setTotalNumRows] = useState<number>(1);

  const [columnOrderActive, setColumnOrderActive] = useState<string>("createDate");
  const [columnSortDir, setColumnSortDir] = useState<string>("desc");
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      flex: 0.75,
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return (
          <Link to={`/activities/read/${encodeURIComponent(encryptData(params.id))}`} className="text-gray-700 text-hover-primary mb-1">
            TA-{params.row.id}
          </Link>
        );
      },
      valueGetter(params) {
        return "TA-" + params.row.id;
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      headerClassName: "fw-bold",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: (params: any) => {
        return <div>{params.formattedValue}</div>;
      },
      sortable: true,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      minWidth: 300,
      cellClassName: "wrap-text cell-align-top",
      renderCell: (params: any) => {
        return <div>{params.formattedValue}</div>;
      },
      sortable: true,
    },
    {
      field: "activityStartDate",
      headerName: "DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {(params.row.activityStartDate ? dayjs(params.row.activityStartDate).format("MM/DD/YYYY") : "") +
              (params.row.activityEndDate ? " - " : "") +
              (params.row.activityEndDate ? dayjs(params.row.activityEndDate).format("MM/DD/YYYY") : "")}
          </>
        );
      },
      valueGetter(params) {
        return params.row.activityStartDate ? dayjs(params.row.activityStartDate).format("MM/DD/YYYY") : "";
      },
      sortable: true,

      sortComparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    },
    {
      field: "serviceRequest_Lookup_ServiceTopics",
      headerName: "TOPIC",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return <>{getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ")}</>;
      },
      valueGetter(params) {
        return getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ");
      },
      sortable: true,
    },
    {
      field: "taMethods_VirtualCode",
      headerName: "TA METHOD",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {(params.row.taMethods_VirtualCode ? getLookupDescription(params.row.taMethods_VirtualCode) : "") +
              (params.row.taMethods_FaceToFaceCode ? getLookupDescription(params.row.taMethods_FaceToFaceCode) : "")}
          </>
        );
      },
      valueGetter(params) {
        return (
          (params.row.taMethods_VirtualCode ? getLookupDescription(params.row.taMethods_VirtualCode) : "") +
          (params.row.taMethods_FaceToFaceCode ? getLookupDescription(params.row.taMethods_FaceToFaceCode) : "")
        );
      },
      sortable: true,
    },
    {
      field: "approvalStatus",
      flex: 1,
      headerName: "APPROVAL STATUS",
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return <ApprovalBadge status={params.row.approvalStatus} />;
      },
      valueGetter(params) {
        return params.row.approvalStatus;
      },
      sortable: true,
    },

    {
      field: "createDate",
      flex: 1,
      headerName: "CREATE DATE",
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return params.row.createDate;
      },
      valueGetter(params) {
        return params.row.createDate;
      },
      sortable: true,
      hide: true,
    },
  ];

  
  const toggleFilterForUserApproval = (event: ChangeEvent<HTMLInputElement>) => {
    setShouldFilterPendingApprovals(event.target.checked);

    let pendingCSV = "";

    if (event.target.checked === true) {
      pendingCSV = getRecordStatusesForUserApproval().join(",");
    } else {
      pendingCSV = "";
    }

    filterResults({
      ...hsFilters,
      searchVal: searchVal,
      pageNumber: page,
      pendingStatusCSV: pendingCSV,
      orderColumn: "createDate",
      orderDirection: "desc"
    });
  };

  
    const filterResults = (filterCriteria: HSFilterCriteria) => {
    if (isLoading === false) {
      setIsLoading(true);

      if (onFilterResults != null) {
        onFilterResults();
      }

      if (filterCriteria.orderColumn == null || filterCriteria.orderDirection === "") {
    
        filterCriteria.orderColumn = "createDate";
      }
      if (filterCriteria.orderDirection == null || filterCriteria.orderDirection === "") {

        filterCriteria.orderDirection = "desc";
      }
      
      
           axiosInstance.get(`${API_URL}/activities/filter/`, {
                   headers: {
                "Content-Type": "application/json", // or the required content type
              },
              params: {
                ...filterCriteria,
      
                isGranteeAttendee: false,
                //$filter: filterCriteria.oDataFilter,
                // $orderby: "title",
                // $top: 10,
                // $skip: 0,
              },
            }).then((response) => {
              //console.log("here130", response.data);
      
           
           
           
           
              setFilteredActivityDataFromOdata(response?.data?.activities);
           
              setHSFilters(filterCriteria);
        
              setFilteredActivityDataForGrid(response?.data?.activities);
        
              setTotalNumRows(response?.data?.totalNumActivities);

              
              
              setIsLoading(false);





              setFilterExpanded(false);
      window.scrollTo(0, 0);
            }).catch((error) => {
              console.error("here132", error);
            });

        

        
      };

      

      //console.log("here20", filteredDataForCharts);

      
    
  };

  useEffect(() => {}, [isLoading]);

  const clearFilter = () => {
    setBreadcrumbs([{ label: "T/TA Activities", path: "/activities" }]);

    setSearchVal("");

    setPage(0);
    setColumnOrderActive("createDate");
    setColumnSortDir("desc");
    setShouldFilterPendingApprovals(false);
    let tmpHSFilters: HSFilterCriteria = {};
    filterResults(tmpHSFilters);
    window.scrollTo(0, 0);
    if (onFilterResults != null) {
      onFilterResults();
    }
  };
  useEffect(() => {
    if (isFilterExpanded != null) {
      setFilterExpanded(isFilterExpanded);
    }



  }, [isFilterExpanded]);

  useEffect(() => {
    let pendingCSV: string = "";
    
    if (filterActvitiesByPendingApprovals != null && filterActvitiesByPendingApprovals === true) {
      pendingCSV = getRecordStatusesForUserApproval().join(",");
    }
   
   
    
    filterResults({
      ...hsFilters,
      searchVal: searchVal,
      pageNumber: page,
      pendingStatusCSV: pendingCSV,
      orderColumn: columnOrderActive,
      orderDirection: columnSortDir,
    });
  }, [searchVal, page, columnOrderActive, columnSortDir]);

  const onColumnOrderChange = (orderColumnName?: string, orderDir?: string) => {
    setColumnOrderActive(orderColumnName ?? "");

    setColumnSortDir(orderDir ?? "");
  };

  const filterResultsEvent = (filters: HSFilterCriteria) => {
    let pendingCSV: string = "";

    if (filterActvitiesByPendingApprovals != null && filterActvitiesByPendingApprovals === true) {
      pendingCSV = getRecordStatusesForUserApproval().join(",");
    }

    filterResults({ ...filters, searchVal: searchVal, pageNumber: page, pendingStatusCSV: pendingCSV });
  };
  return (
    <>
      <div className="content flex-row-fluid" id="content">
        <ActivityFilterCriteria
          filterResults={filterResultsEvent}
          clearFilter={clearFilter}
          granteeSelectionFilterType={GranteeSelectionFilterType.Invited}
          isFilterExpanded={filterExpanded}
          setHSFilters={setHSFilters}
        />

        <div className="card mb-5 mb-xl-10">
          {/* begin::Card header */}
          <div className="card-header border-0 pt-6">
            {/* begin::Card title */}
            <div className="card-title">
              {/* begin::Search */}
              {/* <div className="d-flex align-items-center position-relative my-1"> */}
              {/* begin::Icon */}
              {/* <span className="position-absolute ms-7">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </span> */}
              {/* end::Icon */}

              {/* <label htmlFor="search" className="visually-hidden">
                    Search:{" "}
                  </label>
                  <input type="text" name="search" id="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search Service Requests" />
                </div> */}
              {/* end::Search */}
            </div>
            {/* begin::Card title */}

            {/* begin::Card toolbar */}
            <div className="card-toolbar">
              {/* begin::Toolbar */}
              <div className="d-flex justify-content-end" data-kt-subscription-table-toolbar="base">
                {/* begin::Filter Button */}
                {/* <a href="/" className="btn btn-secondary  me-5">
                    <i className="fa-solid fa-filter mb-1"></i>
                    Filter
                  </a> */}
                {/* end::Filter Button */}

                {/* begin::Add Button */}
                <div className="form-check">

                  
                  {(userPermissions.canApproveCore === true || userPermissions.canApproveFiscal === true || userPermissions.canApproveSamhsa === true) && (

                  
                  
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch checked={filterActvitiesByPendingApprovals} onChange={toggleFilterForUserApproval} />}
                      label="My Pending Approvals"
                      style={{ fontWeight: "bold" }}
                    />
                  </FormGroup>
                  )}
                  
                  
                </div>
                {/* end::Add Button */}
              </div>
              {/* end::Toolbar */}
            </div>
            {/* end::Card toolbar */}
          </div>
          {/* end::Card header */}

          {/* begin::Card body */}
          <div className="card-body pt-0" style={{ marginTop: -50 }}>
            {/* begin::Table */}

            <div className="table-responsive">
              {/* <DataTable data={filteredData} columns={activityColumns} /> */}

              <ServerSideDataGrid
                rows={filteredDataForGrid}
                columns={columns}
                pageSize={25}
                noRowsLabel={noRowsLabel}
                sortModel={[{ field: "createDate", sort: "desc" }]}
                setPage={setPage}
                onSearch={setSearchVal}
                searchVal={searchVal}
                totalNumRows={totalNumRows}
                page={page}
                setOrder={onColumnOrderChange}
                isLoading={isLoading}
              />
            </div>
            {/* end::Table */}
          </div>
          {/* end::Card body */}
        </div>
      </div>
    </>
  );
};
