import React, { useState, useEffect, useContext } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import ExportDataGrid from "components/datagrid/exportDataGrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { LookupsContext } from "contexts/lookupsContext";

import { BreadcrumbContext } from "contexts/breadcrumbContext";
import { parseCamelCaseToSpaces } from "utils/strings";
const NO_ROWS_LABEL = "No T/TA Activities.";

type ActivityListProps = {
  data: BaseRecord[];
};

/* type AttendeesRow = {
  activityId: any,
  title: string,
  startDate: string,
  endDate: string,
  taMethodsVirtual: string,
  taMethodsFaceToFace: string,
  samhsaPriorities: string,
  region: string,
  granteeType: string,
  grantee: string,
  grantProgram: string,
  numOfAttendees: string
} */

export const ActivityAttendeesReport: React.FC<ActivityListProps> = ({ data }) => {
  const [activityData, setActivityData] = useState<BaseRecord[]>([]);
  const [filteredApprovalsData, setFilteredApprovalsActivityData] = useState<BaseRecord[]>([]);
  const [filteredDataFromOdata, setFilteredActivityDataFromOdata] = useState<BaseRecord[] | null>(null);
  const [filteredDataForGrid, setFilteredActivityDataForGrid] = useState<BaseRecord[]>([]);
  const [noRowsLabel, setNoRowsLabel] = useState<string>(NO_ROWS_LABEL);
  const { filterActvitiesByPendingApprovals, setFilterActvitiesByPendingApprovals: setShouldFilterPendingApprovals, getRecordStatusesForUserApproval } = useContext(LoggedInUserContext);
  const { getLookupDescription, getLookupDescriptions } = useContext(LookupsContext);
  const { setBreadcrumbs } = useContext(BreadcrumbContext);
  const [attendeesData, setAttendeesData] = useState<BaseRecord[]>([]);

  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      flex: 0.75,
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return (
          <Link to={`/activities/read/${encodeURIComponent(encryptData(params.row.activityId))}?attendeeId=${params.row.id}`} className="text-gray-700 text-hover-primary mb-1">
            TA-{params.row.activityId}
          </Link>
        );
      },
      valueGetter(params) {
        return "TA-" + params.row.activityId;
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      headerClassName: "fw-bold",
      flex: 1,
      minWidth: 200,
      cellClassName: "wrap-text cell-align-top",
      renderCell: (params) => {
        return <div>{params.row.title}</div>;
      },
    },

    {
      field: "serviceRequest_Lookup_ServiceTopics",
      headerName: "TOPIC AREA",
      flex: 1,
      cellClassName: "cell-align-top",
      minWidth: 200,
      renderCell: function (params: any) {
        return <div>{getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ")}</div>;
      },
      valueGetter: function (params: any) {
        return getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ");
      },
      hide: true,
    },

    {
      field: "taMethodsVirtual",
      headerName: "T/TA METHOD (VIRTUAL)",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescription(params.row.taMethodsVirtual);
      },
      hide: true,
    },
    {
      field: "taMethodsFaceToFace",
      headerName: "T/TA METHOD (FACE TO FACE)",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescription(params.row.taMethodsFaceToFace);
      },
      hide: true,
    },
    {
      field: "samhsaPriorities",
      headerName: "SAMHSA PRIORITIES",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.samhsaPriorities).join(" | ");
      },
      hide: true,
    },

    {
      field: "lookup_ServiceCategories",
      headerName: "ACTIVITY CATEGORY",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_ServiceCategories).join(" | ");
      },
      hide: true,
    },
    {
      field: "startDate",
      headerName: "ACTIVITY START DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,

      renderCell: (params) => {
        if (dayjs(params.row.startDate).isValid() === true) {
          return <div>{dayjs(params.row.startDate).format("MM/DD/YYYY")}</div>;
        }
        return <div></div>;
      },
      valueGetter(params) {
        if (dayjs(params.row.startDate).isValid() === true) {
          return dayjs(params.row.startDate).format("MM/DD/YYYY");
        }
      },
      hide: true,
    },
    {
      field: "endDate",
      headerName: "ACTIVITY END DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      renderCell: (params) => {
        if (dayjs(params.row.endDate).isValid() === true) {
          return <div>{dayjs(params.row.endDate).format("MM/DD/YYYY")}</div>;
        }
        return <div></div>;
      },
      valueGetter(params) {
        if (dayjs(params.row.endDate).isValid() === true) {
          return dayjs(params.row.endDate).format("MM/DD/YYYY");
        }
      },
      hide: true,
    },
    {
      field: "populations",
      headerName: "POPULATIONS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      renderCell: (params) => {
        
        return getLookupDescriptions(params.row.lookup_ServiceAudience_Populations).join(" | ");
      },
      valueGetter(params) {
        
        return getLookupDescriptions(params.row.lookup_ServiceAudience_Populations).join(" | ");
      },
      

      

      hide: true
    },
    {
      field: "additonialAudience",
      headerName: "ADDITIONAL AUDIENCE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      renderCell: (params) => {
        


        return getLookupDescriptions(params.row.lookup_ServiceAudience_Additional).join(" | ");



      },
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_ServiceAudience_Additional).join(" | ");
      },
      



      hide: true
    },
    {
      field: "coachingForSuccess",
      headerName: "COACHING FOR SUCCESS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,
      renderCell: (params) => {
        
        return params.row.coachingForSuccess === true ? "YES" : "NO";
        
      },
      valueGetter(params) {
        return params.row.coachingForSuccess === true ? "YES" : "NO";
      },
     



      hide: true
    },
    {
      field: "region",
      headerName: "REGION",
      headerClassName: "fw-bold",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",

      renderCell: (params) => {
        return <div>{getLookupDescription(params.row.region)}</div>;
      },
      valueGetter(params) {
        return getLookupDescription(params.row.region);
      },
    },
    {
      field: "granteeType",
      headerName: "GRANTEE TYPE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",

      renderCell: (params) => {
        return <div>{getLookupDescription(params.row.granteeType)}</div>;
      },
      valueGetter(params) {
        return getLookupDescription(params.row.granteeType);
      },
    },
    {
      field: "grantee",
      headerName: "GRANTEE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: (params) => {
        return <div>{getLookupDescription(params.row.grantee)}</div>;
      },
      valueGetter(params) {
        return getLookupDescription(params.row.grantee);
      },
    },

    {
      field: "grantProgram",
      headerName: "GRANT PROGRAM",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,

      renderCell: (params) => {
        return <div>{getLookupDescription(params.row.grantProgram)}</div>;
      },
    },
    {
      field: "numOfAttendees",
      headerName: "# OF CLIENTS ATTENDED",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      minWidth: 200,

      renderCell: (params) => {
        return <div>{params.row.numOfAttendees}</div>;
      },
    },

    {
      field: "approvalWorkflowState",
      flex: 1,
      headerName: "APPROVAL STATUS",
      cellClassName: "cell-align-top",
      minWidth: 200,
      valueGetter(params) {
        return parseCamelCaseToSpaces(params.row?.currentStatus);
      },
    },
  ];

  useEffect(() => {
    let result: BaseRecord[] = prepareAttendeesData(data);

    setAttendeesData(result);
    //setAttendeesData(data);


    
  }, [data]);

  const prepareAttendeesData = (dta: BaseRecord[]): BaseRecord[] => {
    let result: BaseRecord[] = [];
    if (dta != null) {
      let i = 0;
      for (const acc of dta) {
        //if (acc.id === 1053)
        //{

        if (acc.granteeAttendancesConsultation != null) {
          for (const sel of acc.granteeAttendancesConsultation) {
            let newRow: BaseRecord = {
              id: i,
              activityId: acc.id,
              typeCode: "CONSULTATION",
              title: acc.title,
              startDate: acc.activityStartDate,
              endDate: acc.actitvityEndDate,
              taMethodsVirtual: acc.taMethods_VirtualCode,
              taMethodsFaceToFace: acc.taMethods_FaceToFaceCode,
              samhsaPriorities: acc.lookup_SamhsaPriorities,
              region: sel.regionCode,
              granteeType: sel.granteeTypeCode,
              grantee: sel.granteeCode,
              grantProgram: sel.grantProgramCode,
              numOfAttendees: sel.numberOfAttendees,
              serviceRequest_Lookup_ServiceTopics: acc.serviceRequest_Lookup_ServiceTopics,
              lookup_ServiceCategories: acc.lookup_ServiceCategories,
              currentStatus: acc?.approvalWorkflowState?.currentStatus,
              lookup_ServiceAudience_Populations: acc?.lookup_ServiceAudience_Populations,
              lookup_ServiceAudience_Additional: acc?.lookup_ServiceAudience_Additional,
              coachingForSuccess: acc?.coachingForSuccess
            };
            result.push(newRow);
            i++;
          }
        }
        /* if (acc.granteeAttendancesEvent != null) {
          for (const sel of acc.granteeAttendancesEvent) {
            
            let newRow: BaseRecord = {
              id: i,
              activityId: acc.id,
              typeCode: "EVENT",
              title: acc.title,
              startDate: acc.activityStartDate,
              endDate: acc.actitvityEndDate,
              taMethodsVirtual: acc.taMethods_VirtualCode,
              taMethodsFaceToFace: acc.taMethods_FaceToFaceCode,
              samhsaPriorities: acc.lookup_SamhsaPriorities,
              region: sel.regionCode,
              granteeType: sel.granteeTypeCode,
              grantee: sel.granteeCode,
              grantProgram: sel.grantProgramCode,
              numOfAttendees: sel.numberOfAttendees
              
            };
            i++;
            result.push(newRow);
          }
       
        }   */
        //}

        if (acc.granteeAttendancesEvent != null) {
          for (const sel of acc.granteeAttendancesEvent) {
            let existingRow = result.findIndex(
              (r) =>
                r.activityId === acc.id &&
                r.typeCode === "EVENT" &&
                r.region === sel.regionCode &&
                r.granteeType === sel.granteeTypeCode &&
                r.grantee === sel.granteeCode &&
                r.grantProgram === sel.grantProgramCode
            );
            //let existingRow = result.findIndex(r => r.id === acc.id && r.typeCode === "EVENT" && r.region === sel.regionCode
            //&& r.granteeType === sel.granteeTypeCode && r.grantee === sel.granteeCode && );

            if (existingRow > -1) {
              let newRow: any = {
                id: result[existingRow].id,
                activityId: acc.id,
                typeCode: "EVENT",
                title: acc.title,
                startDate: acc.startDate,
                endDate: acc.endDate,
                taMethodsVirtual: acc.taMethods_VirtualCode,
                taMethodsFaceToFace: acc.taMethods_FaceToFaceCode,
                samhsaPriorities: acc.lookup_SamhsaPriorities,
                region: sel.regionCode,
                granteeType: sel.granteeTypeCode,
                grantee: sel.granteeCode,
                grantProgram: sel.grantProgramCode,
                numOfAttendees: parseInt(result[existingRow].numOfAttendees) + parseInt(sel.numberOfAttendees),
                serviceRequest_Lookup_ServiceTopics: acc.serviceRequest_Lookup_ServiceTopics,
                lookup_ServiceCategories: acc.lookup_ServiceCategories,
                currentStatus: acc?.approvalWorkflowState?.currentStatus,
                lookup_ServiceAudience_Populations: acc?.lookup_ServiceAudience_Populations,
                lookup_ServiceAudience_Additional: acc?.lookup_ServiceAudience_Additional,
                coachingForSuccess: acc?.coachingForSuccess
              };

              result[existingRow] = newRow;
            } else {
              let newRow: any = {
                id: i,
                activityId: acc.id,
                typeCode: "EVENT",
                title: acc.title,
                startDate: acc.startDate,
                endDate: acc.endDate,
                taMethodsVirtual: acc.taMethods_VirtualCode,
                taMethodsFaceToFace: acc.taMethods_FaceToFaceCode,
                samhsaPriorities: acc.lookup_SamhsaPriorities,
                region: sel.regionCode,
                granteeType: sel.granteeTypeCode,
                grantee: sel.granteeCode,
                grantProgram: sel.grantProgramCode,
                numOfAttendees: sel.numberOfAttendees,
                serviceRequest_Lookup_ServiceTopics: acc.serviceRequest_Lookup_ServiceTopics,
                lookup_ServiceCategories: acc.lookup_ServiceCategories,
                currentStatus: acc?.approvalWorkflowState?.currentStatus,
                lookup_ServiceAudience_Populations: acc?.lookup_ServiceAudience_Populations,
                lookup_ServiceAudience_Additional: acc?.lookup_ServiceAudience_Additional,
                coachingForSuccess: acc?.coachingForSuccess
              };
              i++;
              result.push(newRow);
            }
          }
        }
      }
    }

    return result;
  };

  return (
    <>
      <ExportDataGrid rows={attendeesData} columns={columns} noRowsLabel={noRowsLabel} fileName={"attendeesreport"} sortModel={[{ field: "id", sort: "asc" }]} />
    </>
  );
};
