import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useOne, BaseRecord } from "@refinedev/core";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";
//Local Components
import { BreadcrumbContext } from "contexts/breadcrumbContext";
import { ActivityCreateUpdate } from "components";
import { decryptData, encryptData } from "utils/crypto";
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { canEditAtCurrentApprovalStep } from "utils/recordStatuses";

export const ActivityEditPage: React.FC = () => {
  const params = useParams();
  const activityId: number = Number(decryptData(decodeURIComponent(params.id ?? "")));
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);

  const [id, setId] = useState(activityId);
  const { data, isLoading, isError } = useOne({
    resource: "activities",
    id,
  });

  // Change this line
  const activityData: BaseRecord | undefined = data?.data ?? undefined; // Change from [] to null
  const { userPermissions } = useContext(LoggedInUserContext);
  const navigate = useNavigate();

  // Handle breadcrumbs
  useEffect(() => {
    if (activityData) {
      setBreadCrumbsTitle("T/TA Activity");
      setBreads([
        {
          label: activityData?.serviceRequestTitle,
          path: `/servicerequests/read/${encodeURIComponent(encryptData(activityData?.serviceRequestId))}`,
        },
        { label: `${activityData?.title} (TA-${id})`, path: "" },
      ]);
    }
  }, [activityData, id]);

  // Handle permission check
  useEffect(() => {
    if (!isLoading && activityData && userPermissions) {
      const canEdit = canEditAtCurrentApprovalStep(activityData?.approvalWorkflowState?.currentStatus, userPermissions);

      if (!canEdit) {
        navigate("/error");
      }
    }
  }, [activityData, userPermissions, navigate, isLoading]);

  if (isLoading) {
    return (
      <>
        <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
        <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="flex-row-fluid" id="content">
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="primary" />
            </Box>
          </div>
        </main>
      </>
    );
  }

  if (isError) {
    return (
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <div>Error: Could not load T/TA Activity data.</div>
        </div>
      </main>
    );
  }

  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <ActivityCreateUpdate activityId={activityId} activityData={activityData} />
        </div>
      </main>
    </>
  );
};
