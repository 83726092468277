import React, { useState, useEffect, useContext } from "react";
import { BaseRecord, useCreate } from "@refinedev/core";
import { Link, useNavigate } from "react-router-dom";

//Material Design UI + dependencies
import { Tabs, Tab, Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

//Local Components
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { LookupsContext } from "contexts/lookupsContext";
import { parseCamelCaseToSpaces } from "utils/strings";
import { ApprovalWorkflowStatus, ApprovalWorkflowStep, RecordStatus, LookupCode } from "types/enums";
import { ApprovalDialogProps } from "components/approvalWorflow/approvalDialog";
import { ApprovalBadge, ApprovalDialog, FocusAreaView, Comments, HourlyStaffExpenseView, GeneralStaffExpensesView } from "components";
import { ExpenseSummary } from "components/generalStaffExpenses/expenseSummary";
import { aggregateGranteeAttendanceDataByRegion } from "utils/aggregateData";
import { isActivityApproved, canEditAtCurrentApprovalStep } from "utils/recordStatuses";

//DateTime Conversion/Formatting
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

interface ExpenseTotalsByStaffUserName {
  [key: string]: { estimatedTotal: number; actualTotal: number };
}

interface IActivityShowProps {
  data: BaseRecord;
  editUri: string;
}

export const ActivityRead: React.FC<IActivityShowProps> = ({ data, editUri }) => {
  const [activityData, setActivityData] = useState<BaseRecord | undefined>();
  const [approvalDialogProps, setApprovalDialogProps] = useState<ApprovalDialogProps>();
  const [notApprovalDialogProps, setNotApprovalDialogProps] = useState<ApprovalDialogProps>();
  const [canUseApprovalDialog, setCanUseApprovalDialog] = useState<boolean | undefined>(false);
  const [canUseNotApprovalDialog, setCanUseNotApprovalDiaglog] = useState<boolean | undefined>(false);
  const [approvalWorkflowsSorted, setApprovalWorkflowsSorted] = useState<any[]>();
  const [staffDisabled, setStaffDisabled] = useState<boolean>(true);
  const [expensesDisabled, setExpensesDisabled] = useState<boolean>(true);
  const [commentsDisabled, setCommentsDisabled] = useState<boolean>(true);
  const [aggregatedGranteeAttendanceData, setAggregatedGranteeAttendanceData] = useState<BaseRecord[]>([
    {
      regionCode: "",
      numberOfAttendees: 0,
    },
  ]);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const { userPermissions } = useContext(LoggedInUserContext);
  const { getLookupDescription, getLookupDescriptions } = useContext(LookupsContext);
  const navigate = useNavigate();

  const [canEditActivity, setCanEditActivity] = useState<boolean>(false);

  const [notesTabIdx, setNotesTabIdx] = useState<number>(3);
  const [approvalTabIdx, setApprovalTabIdx] = useState<number>(4);

  const userCanPerformStep = (step: ApprovalWorkflowStep, nextSteps: ApprovalWorkflowStep[], acc: BaseRecord): boolean | undefined => {
    if (acc?.isServiceReqAllowNextStep == null || acc?.isServiceReqAllowNextStep === false) {
      return false;
    }

    switch (step) {
      case ApprovalWorkflowStep.SubmitToCore:
        return userPermissions.canSubmitToCore;
      case ApprovalWorkflowStep.ApproveAndSubmitToFiscal:
        return userPermissions.canApproveCore;
      case ApprovalWorkflowStep.ApproveAndSubmitToSAMHSA:
        return userPermissions.canApproveFiscal;
      case ApprovalWorkflowStep.Approve:
        return userPermissions.canApproveSamhsa;
      case ApprovalWorkflowStep.NotApprove:
        if (nextSteps?.includes(ApprovalWorkflowStep.ApproveAndSubmitToFiscal) === true) {
          return userPermissions.canApproveCore;
        } else if (nextSteps?.includes(ApprovalWorkflowStep.ApproveAndSubmitToSAMHSA) === true) {
          return userPermissions.canApproveFiscal;
        } else if (nextSteps?.includes(ApprovalWorkflowStep.Approve) === true) {
          return userPermissions.canApproveSamhsa;
        }
        return false;
      case ApprovalWorkflowStep.Complete:
        return userPermissions.canComplete;
      default:
        return false;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

    if (userPermissions.canViewCostBreakdownTab === true) {
      setNotesTabIdx(4);
      setApprovalTabIdx(5);
    }
  }, []);

  useEffect(() => {
    if (data != null) setActivityData(data);
  }, [data]);

  useEffect(() => {
    if (activityData !== null) {
      const sortedApprovalWorkflows = activityData?.approvalWorkflows ? [...activityData?.approvalWorkflows].reverse() : [];
      setApprovalWorkflowsSorted(sortedApprovalWorkflows);

      //clear props then initialize
      setApprovalDialogProps(undefined);
      setNotApprovalDialogProps(undefined);

      const dialogMessage = "You may add an optional message below to be sent in the email notification to the next staff member in the workflow.";

      activityData?.approvalWorkflowState?.nextSteps &&
        activityData?.approvalWorkflowState?.nextSteps.forEach((step: ApprovalWorkflowStep) => {
          const actionText = parseCamelCaseToSpaces(step, true) ?? step;
          const actionTextError = "You cannot " + actionText + " until all required fields are completed.";

          switch (step) {
            case ApprovalWorkflowStep.SubmitToCore:
              setApprovalDialogProps({
                buttonText: actionText,
                buttonIcon: <i className="fa-solid fa-share-from-square" style={{ paddingLeft: 5, paddingBottom: 3, paddingRight: 0 }} />,
                dialogTitle: "Submit Activity to Core Team",
                dialogContentText: dialogMessage,
                submitButtonText: actionText,
                submissionStatus: ApprovalWorkflowStatus.SubmittedToCore,
                hasValidationErrors: activityData?.approvalWorkflowState?.hasValidationErrors,
                validationErrors: activityData?.approvalWorkflowState?.validationErrors,
                dialogContentErrorText: actionTextError,
                validationDetailMessage: "Please edit the T/TA Activity and complete the following fields:",
                onSubmit: () => {},
              });
              setCanUseApprovalDialog(userCanPerformStep(step, activityData?.approvalWorkflowState?.nextSteps, activityData));
              break;
            case ApprovalWorkflowStep.ApproveAndSubmitToFiscal:
              setApprovalDialogProps({
                statusStyle: "success",
                buttonText: actionText,
                buttonIcon: <></>,
                dialogTitle: actionText,
                dialogContentText: dialogMessage,
                submitButtonText: actionText,
                submissionStatus: ApprovalWorkflowStatus.ApprovedAndSubmittedToFiscal,
                redirectToPostSubmit: "/activities",
                hasValidationErrors: activityData?.approvalWorkflowState?.hasValidationErrors,
                validationErrors: activityData?.approvalWorkflowState?.validationErrors,
                dialogContentErrorText: actionTextError,
                validationDetailMessage: "Please edit the T/TA Activity and complete the following fields:",
                onSubmit: () => {},
              });

              setCanUseApprovalDialog(userCanPerformStep(step, activityData?.approvalWorkflowState?.nextSteps, activityData));
              break;
            case ApprovalWorkflowStep.ApproveAndSubmitToSAMHSA:
              setApprovalDialogProps({
                statusStyle: "success",
                buttonText: actionText,
                buttonIcon: <></>,
                dialogTitle: actionText,
                dialogContentText: dialogMessage,
                submitButtonText: actionText,
                submissionStatus: ApprovalWorkflowStatus.ApprovedAndSubmittedToSAMHSA,
                redirectToPostSubmit: "/activities",
                hasValidationErrors: activityData?.approvalWorkflowState?.hasValidationErrors,
                validationErrors: activityData?.approvalWorkflowState?.validationErrors,
                dialogContentErrorText: actionTextError,
                validationDetailMessage: "Please edit the T/TA Activity and complete the following fields:",
                onSubmit: () => {},
              });
              setCanUseApprovalDialog(userCanPerformStep(step, activityData?.approvalWorkflowState?.nextSteps, activityData));
              break;
            case ApprovalWorkflowStep.Approve:
              setApprovalDialogProps({
                statusStyle: "success",
                buttonText: actionText,
                buttonIcon: <></>,
                dialogTitle: actionText,
                dialogContentText: dialogMessage,
                submitButtonText: actionText,
                submissionStatus: ApprovalWorkflowStatus.Approved,
                redirectToPostSubmit: "/activities",
                hasValidationErrors: activityData?.approvalWorkflowState?.hasValidationErrors,
                validationErrors: activityData?.approvalWorkflowState?.validationErrors,
                dialogContentErrorText: actionTextError,
                validationDetailMessage: "Please edit the T/TA Activity and complete the following fields:",
                onSubmit: () => {},
              });
              setCanUseApprovalDialog(userCanPerformStep(step, activityData?.approvalWorkflowState?.nextSteps, activityData));
              break;
            case ApprovalWorkflowStep.NotApprove:
              setNotApprovalDialogProps({
                statusStyle: "danger",
                buttonText: actionText,
                buttonIcon: <></>,
                dialogTitle: `Do ${actionText}`,
                dialogContentText: dialogMessage,
                submitButtonText: `Do ${actionText}`,
                submissionStatus: ApprovalWorkflowStatus.NotApproved,
                redirectToPostSubmit: "/activities",
                validationDetailMessage: "Please edit the T/TA Activity and complete the following fields:",
                onSubmit: () => {},
              });
              setCanUseNotApprovalDiaglog(userCanPerformStep(step, activityData?.approvalWorkflowState?.nextSteps, activityData));
              break;
            case ApprovalWorkflowStep.Complete:
              setApprovalDialogProps({
                statusStyle: "success",
                buttonText: actionText,
                buttonIcon: <></>,
                dialogTitle: actionText,
                dialogContentText: dialogMessage,
                submitButtonText: actionText,
                submissionStatus: ApprovalWorkflowStatus.Completed,
                hasValidationErrors: activityData?.approvalWorkflowState?.hasValidationErrors,
                validationErrors: activityData?.approvalWorkflowState?.validationErrors,
                dialogContentErrorText: actionTextError,
                validationDetailMessage: "Please edit the T/TA Activity and complete the following fields:",
                onSubmit: () => {},
              });

              setCanUseApprovalDialog(userCanPerformStep(step, activityData?.approvalWorkflowState?.nextSteps, activityData));
              break;
          }
        });

      setAggregatedGranteeAttendanceData(aggregateGranteeAttendanceDataByRegion(activityData?.granteeAttendancesEvent));
    }

    if (activityData?.hourlyExpenses != null && activityData?.hourlyExpenses.length > 0 && activityData?.hourlyExpenses[0].staffUserId != null && activityData?.hourlyExpenses[0].staffUserId != "") {
      setStaffDisabled(false);
    } else {
      setStaffDisabled(true);
    }

    if (
      (activityData?.dailyExpenses != null && activityData?.dailyExpenses.length > 0 && activityData?.dailyExpenses[0].staffUserId != null && activityData?.dailyExpenses[0].staffUserId != "") ||
      (activityData?.transportationExpenses != null &&
        activityData?.transportationExpenses.length > 0 &&
        activityData?.transportationExpenses[0].staffUserId != null &&
        activityData?.transportationExpenses[0].staffUserId != "") ||
      (activityData?.mileageExpenses != null && activityData?.mileageExpenses.length > 0 && activityData?.mileageExpenses[0].staffUserId != null && activityData?.mileageExpenses[0].staffUserId != "")
    ) {
      setExpensesDisabled(false);
    } else {
      setExpensesDisabled(true);
    }

    if (activityData?.comments == null || activityData?.comments.length <= 0) {
      setCommentsDisabled(true);
    } else {
      setCommentsDisabled(false);
    }
    let tmpCanEdit: boolean = canEditAtCurrentApprovalStep(activityData?.approvalWorkflowState?.currentStatus, userPermissions);

    setCanEditActivity(tmpCanEdit);
  }, [activityData]);

  // const isActivityApproved = (): boolean => {
  //   return (
  //     //activityData?.approvalWorkflowState?.currentStatus && activityData?.approvalWorkflowState?.currentStatus !== RecordStatus.Draft && activityData?.approvalWorkflowState?.currentStatus !== RecordStatus.Submitted && activityData?.approvalWorkflowState?.currentStatus !== RecordStatus.NotApproved
  //     activityData?.approvalWorkflowState?.currentStatus && activityData?.approvalWorkflowState?.currentStatus === RecordStatus.Approved
  //   );
  // };

  // ---- START TABS ---- (TODO: move tabs into it's own component)
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setActiveTab(newIndex);
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div className="tab-pane active show" id={"simple-tabpanel-" + index} role="tabpanel" aria-labelledby={"simple-tab-" + index}>
        {value === index && children}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  // ---- END TABS ----

  const createMethod = useCreate();

  const submitApprovalWorkflow = (submissionStatus: ApprovalWorkflowStatus, message: string, redirectTo?: string) => {
    setDisableButtons(true);
    createMethod.mutate(
      {
        resource: "approvalworkflows/activity",
        values: {
          requestedParentId: activityData?.id,
          approvalStatus: submissionStatus,
          notes: message,
        },
        successNotification: (data, values, resource) => {
          return {
            message: "Successfully updated Activity status: " + parseCamelCaseToSpaces(submissionStatus, true), //TODO: get this message from the API and/or reword
            description: "Success",
            type: "success",
          };
        },
        errorNotification: false,
      },
      {
        /* onError: (error, variables, context) => {
          // An error occurred!
        }, */
        onSuccess: (data, variables, context) => {
          redirectTo ? navigate(redirectTo) : setActivityData(data?.data);
          setDisableButtons(false);
          //setActivityData(data?.data); //refresh form data (remember: updating state forces a component rerender)
        },
      }
    );
  };

  return (
    <>
      {/* begin::Card */}
      <div className="card mb-5 mb-xl-10">
        {/* begin::Card Body */}
        <div className="card-body pt-9 pb-9">
          {/* begin::Title & Actions */}
          <div className="row">
            <div className={`${approvalDialogProps && notApprovalDialogProps ? "col-lg-7" : "col-lg-9"}`}>
              <div className="d-flex align-items-center mb-2 order-lg-1">
                <h2 className="text-gray-900 fs-2 fw-bold me-2 my-0">
                  {activityData?.title}
                  &nbsp;
                  <ApprovalBadge status={activityData?.approvalWorkflowState?.currentStatus} />
                </h2>
              </div>
            </div>
            <div className={`${approvalDialogProps && notApprovalDialogProps ? "col-lg-5" : "col-lg-3"}  order-lg-2`}>
              <div className="float-lg-end float-md-start mb-xs-3">
                {canEditActivity === true && (
                  <Link to={editUri} className="btn btn-sm btn-secondary me-2">
                    Edit
                  </Link>
                )}

                {approvalDialogProps && canUseApprovalDialog && (
                  <ApprovalDialog
                    statusStyle={approvalDialogProps?.statusStyle}
                    buttonText={approvalDialogProps?.buttonText ?? ""}
                    dialogTitle={approvalDialogProps?.dialogTitle ?? ""}
                    dialogContentText={approvalDialogProps?.dialogContentText ?? ""}
                    buttonIcon={approvalDialogProps?.buttonIcon}
                    submitButtonText={approvalDialogProps?.submitButtonText ?? ""}
                    submissionStatus={approvalDialogProps?.submissionStatus ?? ""}
                    redirectToPostSubmit={approvalDialogProps?.redirectToPostSubmit}
                    hasValidationErrors={approvalDialogProps?.hasValidationErrors}
                    validationErrors={approvalDialogProps?.validationErrors}
                    dialogContentErrorText={approvalDialogProps?.dialogContentErrorText ?? ""}
                    validationDetailMessage={approvalDialogProps?.validationDetailMessage ?? ""}
                    onSubmit={submitApprovalWorkflow}
                    disableButtons={disableButtons}
                  />
                )}

                {notApprovalDialogProps && canUseNotApprovalDialog && (
                  <ApprovalDialog
                    statusStyle={notApprovalDialogProps?.statusStyle}
                    buttonText={notApprovalDialogProps?.buttonText ?? ""}
                    dialogTitle={notApprovalDialogProps?.dialogTitle ?? ""}
                    dialogContentText={notApprovalDialogProps?.dialogContentText ?? ""}
                    buttonIcon={notApprovalDialogProps?.buttonIcon}
                    submitButtonText={notApprovalDialogProps?.submitButtonText ?? ""}
                    submissionStatus={notApprovalDialogProps?.submissionStatus ?? ""}
                    redirectToPostSubmit={notApprovalDialogProps?.redirectToPostSubmit}
                    validationDetailMessage={approvalDialogProps?.validationDetailMessage ?? ""}
                    onSubmit={submitApprovalWorkflow}
                    disableButtons={disableButtons}
                  />
                )}
              </div>
            </div>
          </div>
          {/* end::Title & Actions */}

          {/* begin::Key Info */}
          <div className="row">
            <div className="col-lg-9">
              <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                <span className="font-weight-bold text-gray-700 me-5 mb-2">
                  <i aria-hidden="true" className="fa-solid fa-calendar-days text-gray-700 icon-nm mr-2 me-1" title="Activity Date"></i>
                  <span className="sr-only">Activity Date:</span>
                  <span>
                    {(activityData?.activityStartDate != null || activityData?.activityEndDate != null) &&
                      (activityData?.activityStartDate != null &&
                      activityData?.activityEndDate != null &&
                      dayjs(activityData?.activityStartDate).isSame(dayjs(activityData?.activityEndDate), "day") === true
                        ? dayjs(activityData?.activityStartDate).format("MM/DD/YYYY")
                        : (activityData?.activityStartDate != null ? activityData?.activityStartDate && dayjs(activityData?.activityStartDate).format("MM/DD/YYYY") : "N/A") +
                          (activityData?.activityEndDate != null ? activityData?.activityEndDate && " - " + dayjs(activityData?.activityEndDate).format("MM/DD/YYYY") : " - N/A"))}
                  </span>
                </span>

                <span className="font-weight-bold text-gray-700 me-5 mb-2">
                  <i aria-hidden="true" className="fas fa-map-marker-alt text-gray-700 icon-nm mr-2 me-1" title="T/TA Method"></i>
                  <span className="sr-only">T/TA Method:</span>
                  <span>
                    {(activityData?.taMethods_VirtualCode ? getLookupDescription(activityData?.taMethods_VirtualCode) + " (Virtual)" : "") +
                      (activityData?.taMethods_FaceToFaceCode ? getLookupDescription(activityData?.taMethods_FaceToFaceCode) + " (Face to Face)" : "")}
                  </span>
                </span>

                <span className="font-weight-bold text-gray-700 me-5 mb-2">
                  <i aria-hidden="true" className="fas fa-globe text-gray-700 icon-nm mr-2 me-1" title="T/TA Activity Category"></i>
                  <span className="sr-only">T/TA Activity Category:</span>
                  {getLookupDescriptions(activityData?.lookup_ServiceCategories).map((item: string, index: number) => (
                    <React.Fragment key={index}>
                      <span>{item}</span>
                      {index < activityData?.lookup_ServiceCategories.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                  ))}
                </span>

                <span className="font-weight-bold text-gray-700 me-5 mb-2">
                  <i aria-hidden="true" className="fa-solid fa-user text-gray-700 icon-nm mr-2 me-1" title="Staff Planning and Delivering the Activity"></i>
                  <span className="sr-only">SPTAC Staff Manager:</span>
                  <span>
                    {activityData?.managingStaffUserName}
                    {activityData?.teamCode != null && activityData?.teamCode === "COR" && " (Core)"}
                    {activityData?.teamCode != null && activityData?.teamCode === "REG" && " (Regional)"}
                  </span>
                </span>
              </div>
            </div>
          </div>
          {/* end::Key Info */}

          {/* begin::Details */}
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            {/* begin::Info */}
            <div className="flex-grow-1">
              <div className="row">
                <div className="col">
                  <p className="h5 text-black">Need or Problem</p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="text-black fs-6 p-0 m-0">{activityData?.descriptionNeedProblem}</p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="h5 mt-5 text-black">Activity Description</p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="text-black fs-6 p-0 m-0">{activityData?.description}</p>
                </div>
              </div>
            </div>
            {/* end::Info */}
          </div>
          {/* end::Details */}
        </div>
      </div>
      {/* end::Card */}

      {/* begin::Card */}
      <div className="card hstaread-details">
        {/* begin::Header */}
        <div className="card-header card-header-stretch overflow-auto">
          {/* begin::Tabs */}
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Activity Tabs"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
                classes={{ root: "d-inline-block" }}
              >
                <Tab label="Clients & Audience" {...a11yProps(0)} className="fs-4 text-dark" />
                <Tab label="Staff" {...a11yProps(1)} className={`fs-4 text-dark`} />
                <Tab label="Expenses" {...a11yProps(2)} className={`fs-4 text-dark`} />
                {userPermissions.canViewCostBreakdownTab === true && <Tab label="Cost Breakdown" {...a11yProps(3)} className="fs-4 text-dark" />}
                <Tab label="Comments" {...a11yProps(notesTabIdx)} className={`fs-4 text-dark`} />
                <Tab label="Approval Workflow" {...a11yProps(approvalTabIdx)} className="fs-4 text-dark" />
              </Tabs>
              {activityData?.qualtricsId && (
                <div className="d-inline-block hsactivityevaluationsbtn">
                  <Link to={`/activities/evaluations/${activityData?.id}`} className="btn btn-sm btn-secondary" target="_blank">
                    Evaluation Results
                  </Link>
                </div>
              )}
            </Box>
          </Box>
        </div>

        <div className="card-body fs-6 pt-5">
          <TabPanel value={activeTab} index={0}>
            <h3 className="h4 mb-3 py-2 text-bg-light">T/TA Details</h3>

            <div className="row">
              <div className="col-lg-6 mb-3">
                <span className="fw-bold d-flex mb-2">Topic Area</span>

                {getLookupDescriptions(activityData?.serviceRequest_Lookup_ServiceTopics).map((item: string, index: number) => (
                  <React.Fragment key={index}>
                    <span>{item}</span>
                    {index < activityData?.serviceRequest_Lookup_ServiceTopics.length - 1 ? <span> | </span> : ""}
                  </React.Fragment>
                ))}
              </div>

              <div className="col-lg-6 mb-3">
                <span className="fw-bold d-flex mb-2">SAMHSA Priorities</span>

                {getLookupDescriptions(activityData?.lookup_SamhsaPriorities).map((item: string, index: number) => (
                  <React.Fragment key={index}>
                    <span>{item}</span>
                    {index < activityData?.lookup_SamhsaPriorities.length - 1 ? <span> | </span> : ""}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <span className="fw-bold d-flex mb-2">Deadline for Project Officer Approval</span>

                {dayjs(activityData?.projectOfficerApprovalDeadline).format("MM/DD/YYYY")}
              </div>
            </div>

            <h3 className="h4 mb-3 mt-3 py-2 text-bg-light">Clients Invited</h3>

            <div className="row">
              <div className="col-lg-6 mb-3">
                <span className="fw-bold d-flex mb-2">Region & Grantee</span>
                {activityData?.granteeSelections &&
                  activityData?.granteeSelections.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                      <p className="mb-2">
                        {getLookupDescription(item.regionCode)}&nbsp;&gt;&nbsp;
                        {getLookupDescription(item.granteeTypeCode)}&nbsp;&gt;&nbsp;
                        {getLookupDescription(item.granteeCode)}&nbsp;&gt;&nbsp;
                        {item.grantProgramCode.startsWith("All") ? "All Grant Programs" : item.grantProgramCode}
                      </p>
                    </React.Fragment>
                  ))}
              </div>

              <div className="col-lg-6 mb-3">
                <span className="fw-bold d-flex mb-2">Populations</span>
                {getLookupDescriptions(activityData?.lookup_ServiceAudience_Populations).map((item: string, index: number) => (
                  <React.Fragment key={index}>
                    <span>{item}</span>
                    {index < activityData?.lookup_ServiceAudience_Populations.length - 1 ? <span> | </span> : ""}
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 mb-3">
                <span className="fw-bold d-flex mb-2">Additional Audience</span>
                {getLookupDescriptions(activityData?.lookup_ServiceAudience_Additional).map((item: string, index: number) => {
                  if (item.toLowerCase().includes("other") === true) {
                    return (
                      <React.Fragment key={index}>
                        <span>Other ({activityData?.additionalAudienceOther})</span>
                        {index < activityData?.lookup_ServiceAudience_Additional.length - 1 ? <span> | </span> : ""}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={index}>
                        <span>{item}</span>
                        {index < activityData?.lookup_ServiceAudience_Additional.length - 1 ? <span> | </span> : ""}
                      </React.Fragment>
                    );
                  }
                })}
              </div>
              <div className="col-lg-6 mb-3">
                <span className="fw-bold d-flex mb-2">Coaching for Success</span>
                {activityData?.coachingForSuccess === true ? <span className="">Yes</span> : "No"}
              </div>
            </div>

            {(isActivityApproved(activityData) || activityData?.approvalWorkflowState?.currentStatus === RecordStatus.Withdrawn) && (
              <Box>
                <h3 className="h4 mt-3 mb-3 py-2 text-bg-light">Clients Attended ({activityData?.typeCode === LookupCode.ActivityConsultation ? "Consultation" : "Event"})</h3>

                {/* Consultation */}
                {activityData?.typeCode === LookupCode.ActivityConsultation && (
                  <div className="row">
                    <div className="col-lg-12">
                      <table className="table table-sm">
                        <thead>
                          <tr className="fw-bold mb-2">
                            <th scope="col">Region</th>
                            <th scope="col">Grantee Type</th>
                            <th scope="col">Grantee</th>
                            <th scope="col">Grant Program</th>
                            <th scope="col"># of Clients Attended</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activityData?.granteeAttendancesConsultation?.map((attendance: any, index: number) => (
                            <tr key={index}>
                              <td>{getLookupDescription(attendance.regionCode)}</td>
                              <td>{getLookupDescription(attendance.granteeTypeCode)}</td>
                              <td>{getLookupDescription(attendance.granteeCode)}</td>
                              <td>
                                <Tooltip title={getLookupDescription(attendance.grantProgramCode)} arrow>
                                  <span>{attendance.grantProgramCode}</span>
                                </Tooltip>
                              </td>
                              <td>{attendance.numberOfAttendees}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* Event */}
                {activityData?.typeCode === LookupCode.ActivityEvent && (
                  <div className="row">
                    <div className="col-lg-12">
                      <table className="table table-sm">
                        <thead>
                          <tr className="fw-bold mb-2">
                            <th scope="col">Region</th>
                            <th scope="col"># of Clients Attended</th>
                          </tr>
                        </thead>
                        <tbody>
                          {aggregatedGranteeAttendanceData?.map((attendance: any, index: number) => (
                            <tr key={index}>
                              <td>{getLookupDescription(attendance.regionCode)}</td>
                              <td>{attendance.numberOfAttendees}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </Box>
            )}
            <h3 className="h4 mt-3 mb-3 py-2 text-bg-light">Additional Info</h3>
            <div className="row">
              <div className="col">
                <p className="fw-bold mb-2">Grantee Involvement</p>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <p className="">{activityData?.granteeInvolvement}</p>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <p className="fw-bold mb-2">Special Needs</p>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <p className="">{activityData?.specialNeeds}</p>
              </div>
            </div>

            <FocusAreaView data={activityData} />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <HourlyStaffExpenseView hourlyExpenses={activityData?.hourlyExpenses} activityData={[activityData]} isActivityView={true} />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <GeneralStaffExpensesView data={activityData} />
          </TabPanel>
          {userPermissions.canViewCostBreakdownTab === true && (
            <TabPanel value={activeTab} index={3}>
              <ExpenseSummary data={activityData} />
            </TabPanel>
          )}
          <TabPanel value={activeTab} index={notesTabIdx}>
            <div id="Comments" className="card-body p-0 tab-pane fade active show" role="tabpanel">
              <h3 className="h4 mb-5 py-2 text-bg-light">Notes & Comments</h3>
              <Comments comments={activityData?.comments} />
            </div>
          </TabPanel>
          <TabPanel value={activeTab} index={approvalTabIdx}>
            {/* TODO: abstract out 'Approval Workflow View' into it's own component */}
            <h3 className="h4 py-2 text-bg-light">Approval Workflow</h3>

            <table className="table table-sm">
              <thead>
                <tr className="fw-bold">
                  <th scope="col" className="w-15">
                    Date
                  </th>

                  <th scope="col" className="w-70">
                    Status
                  </th>

                  <th scope="col" className="w-15">
                    Created By
                  </th>
                </tr>
              </thead>
              <tbody>
                {approvalWorkflowsSorted?.map((approval: any, index: number) => (
                  <tr key={index}>
                    <td>{dayjs(approval.createDate) && dayjs.utc(approval.createDate).tz("America/New_York").format("MM/DD/YYYY hh:mm A")} EST</td>
                    <td>
                      {parseCamelCaseToSpaces(approval.approvalStatus, true)}{" "}
                      {(approval.approvalStatus === ApprovalWorkflowStatus.NotApproved || approval.approvalStatus === ApprovalWorkflowStatus.Withdrawn) &&
                        (approval.notes ? "(Reason: " + approval.notes + ")" : "(No Reason Provided)")}
                    </td>
                    <td>{approval.createUserName}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <h3 className="h4 mb-3 mt-5 py-2 text-bg-light ">Edit History</h3>

            <table className="table table-sm">
              <thead>
                <tr className="fw-bold">
                  <th scope="col" className="w-15">
                    Created By
                  </th>
                  <th scope="col" className="w-55">
                    Created On
                  </th>
                  <th scope="col" className="w-15">
                    Last Edited By
                  </th>
                  <th scope="col" className="w-15">
                    Last Edited On
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{activityData?.createUserName}</td>
                  <td>{dayjs.utc(activityData?.createDate).tz("America/New_York").format("MM/DD/YYYY hh:mm A")} EST</td>
                  <td>{activityData?.updateUserName}</td>
                  <td>{activityData?.updateDate && dayjs.utc(activityData?.updateDate).tz("America/New_York").format("MM/DD/YYYY hh:mm A") + " EST"}</td>
                </tr>
              </tbody>
            </table>
          </TabPanel>

          {/* end::Tabs */}
        </div>
        {/* end::Header */}
      </div>
      {/* end::Card */}
    </>
  );
};
