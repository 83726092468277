import React, { useContext, useEffect, useState } from "react";
import { BaseRecord, useMany, HttpError, useCreate, useUpdate } from "@refinedev/core";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

//Local Components

import { LookupsContext } from "contexts/lookupsContext";
import { encryptData } from "utils/crypto";
import { LookupCheckboxes, FiscalYear, GranteeSelections, FocusAreaCard, Comments, AlertDialog } from "components";
import { API_URL } from "../../App";
import { useMutation } from "react-query";
import { axiosInstance } from "index";
import { canEditServiceRequestAtStep } from "utils/recordStatuses";

type ServiceRequestCreateUpdateProps = {
  serviceRequestId?: number;
  serviceRequestData?: BaseRecord;
};

export const ServiceRequestCreateUpdate: React.FC<ServiceRequestCreateUpdateProps> = ({ serviceRequestId, serviceRequestData }) => {
  const isForUpdate = serviceRequestId !== undefined;
  const [sendEvaluation, setSendEvaluation] = useState<boolean | undefined>(undefined);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const { lookups } = useContext(LookupsContext);
  const [topicOtherChecked, setTopicOtherChecked] = useState<boolean>(false);
  const [additionalAudienceOtherChecked, setAdditionalAudienceOtherChecked] = useState<boolean>(false);
  const { data, isLoading, isError } = useMany<any, HttpError>({
    resource: "users/staff",
    ids: [],
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  let staff = data?.data ?? [];
  if (data != null) {
    staff = data?.data.sort((a, b) => {
      let aName = `${a.firstName} ${a.lastName}`;
      let bName = `${b.firstName} ${b.lastName}`;

      return aName.localeCompare(bName, undefined, { sensitivity: "base" });
    });
  }

  useEffect(() => {
    if (serviceRequestData !== undefined) {
      //console.log("setting eval with: ", serviceRequestData.sendEvaluation);
      setSendEvaluation(serviceRequestData?.sendEvaluation?.toUpperCase() === "TRUE" ? true : false);
      if (serviceRequestData?.lookup_ServiceTopics?.includes("ST_36") === true) {
        setTopicOtherChecked(true);
      }

      if (serviceRequestData?.lookup_ServiceAudience_Additional?.includes("SAADD_7") === true) {
        setAdditionalAudienceOtherChecked(true);
      }
    }

    
  }, [serviceRequestData]);

  const updateMethod = useUpdate();
  const createMethod = useCreate();
  const navigate = useNavigate();

  const { register, control, handleSubmit, formState, setValue, getValues } = useForm({
    defaultValues: isForUpdate ? serviceRequestData : {}, //Load existing ServiceRequest data is it was supplied (aka "Edit") -or- set to empty (aka "Create")
  });

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(deleteDialogOpen === true ? false : true);
  };

  const deleteMutation = useMutation(
    (dta) => {
      return axiosInstance.delete(`${API_URL}/servicerequests/${serviceRequestData?.id}`);
    },
    {
      onSuccess: (result) => {
        // Handle a successful deletion

        navigate("/servicerequests");
      },
      onError: (error) => {
        // Handle an error during deletion
        navigate("/error");
        handleDeleteDialog();
      },
    }
  );

  const deleteServiceRequest = () => {
    deleteMutation.mutate();
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (isError) {
    return <div>Error: Could not load Staff data.</div>;
  }

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (data: BaseRecord) => {
    setSubmitDisabled(true);
    //alert(JSON.stringify(data));
    if (isForUpdate) {
      updateMethod.mutate(
        {
          resource: "servicerequests",
          values: data,
          id: serviceRequestId,
        },
        {
          /* onError: (error, variables, context) => {
            // An error occurred!
          }, */
          onSuccess: (data, variables, context) => {
            navigate(`/servicerequests/read/${encodeURIComponent(encryptData(serviceRequestId))}`);

            window.scrollTo(0, 0);
          },
        }
      );
    } else {
      createMethod.mutate(
        {
          resource: "servicerequests",
          values: data,
        },
        {
          /* onError: (error, variables, context) => {
            // An error occurred!
          }, */
          onSuccess: (data, variables, context) => {
            navigate(`/servicerequests/read/${encodeURIComponent(encryptData(data?.data.id ?? ""))}`);
          },
        }
      );
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && e.target.type !== "submit") {
      e.preventDefault();
    }
  };

  const handleSendEvalOnChange = (event: any) => {
    setSendEvaluation(event.target.value.toUpperCase() === "TRUE" ? true : false);
  };

  return (
    <>
      <AlertDialog
        title="Delete Service Request"
        content="Are you sure you want to delete this Service Request?"
        open={deleteDialogOpen}
        confirmButtonText="Delete"
        onConfirm={deleteServiceRequest}
        onCancel={handleDeleteDialog}
      />
      <form id="service_request_form" onSubmit={handleSubmit(onSubmit)} className="form d-flex flex-column flex-lg-row" onKeyDown={handleKeyDown}>
        {/* begin::Main column */}
        <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
          {/* begin::Service Request details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>Service Request Details</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::Service Request Details */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}
                  <div className="col-12">
                    <label htmlFor="title" className="required form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      {...register("title", {
                        required: true,
                      })}
                    />
                    {formState.errors?.title && <span className="text-danger">Title is required</span>}
                  </div>

                  <div className="col-12">
                    <label htmlFor="need" className="form-label">
                      Description of Need or Problem
                    </label>
                    <textarea className="form-control" rows={3} id="need" {...register("descriptionNeedProblem")}></textarea>
                  </div>

                  <div className="col-12">
                    <label htmlFor="service" className="form-label">
                      Description of Service
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      id="service"
                      {...register("description", {
                        required: false,
                      })}
                    ></textarea>
                  </div>
                </div>
                {/* end::Row */}
              </div>
              {/* end::Service Request Details */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Service Request details */}

          {/* begin::Service Request Methods & Topics */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>Service Request Methods & Topics</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::Service Request Methods & Topics */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>T/TA Methods (Virtual)</legend>

                      <LookupCheckboxes register={register} lookupType="TAMethods_Virtual" formFieldName="lookup_TAMethods_Virtual" />
                    </fieldset>
                    {/* end::fieldset */}
                  </div>

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>T/TA Methods (Face to Face)</legend>
                      <LookupCheckboxes register={register} lookupType="TAMethods_FaceToFace" formFieldName="lookup_TAMethods_FaceToFace" />
                    </fieldset>
                    {/* end::fieldset */}
                  </div>

                  {/* begin::fieldset */}
                  <fieldset>
                    <legend>Topic Area</legend>
                    {/* <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}> */}
                    <div className="two-column-list" style={{ marginRight: "-1.225rem" }}>
                      <LookupCheckboxes
                        register={register}
                        lookupType="ServiceTopics"
                        formFieldName="lookup_ServiceTopics"
                        onFieldChanged={(val, isChecked) => {
                          if (val === "ST_36") {
                            setTopicOtherChecked(isChecked);
                          }
                        }}
                      />
                      <div className={topicOtherChecked === false ? "topicothertextbox sr-only" : "topicothertextbox"}>
                        <label className="sr-only" htmlFor="topicother">
                          Other:{" "}
                        </label>
                        <input type="text" {...register("topicOther")} className="form-control" id="topicother" />
                      </div>
                    </div>
                    {/*  */}
                  </fieldset>
                  {/* end::fieldset */}

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>SAMHSA Priorities</legend>
                      <LookupCheckboxes register={register} lookupType="SamhsaPriorities" formFieldName="lookup_SamhsaPriorities" />
                    </fieldset>
                    {/* end::fieldset */}
                  </div>

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>Service Category</legend>
                      <LookupCheckboxes register={register} lookupType="ServiceCategories" formFieldName="lookup_ServiceCategories" />
                    </fieldset>
                    {/* end::fieldset */}
                  </div>
                </div>
                {/* end::Row */}
              </div>
              {/* end::Service Request Methods & Topics */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Methods & Topics details */}

          {/* begin::Dates details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>Service Request Dates</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::Service Request Dates */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}
                  <div className="col-sm-6">
                    <label htmlFor="startdate" className="form-label">
                      Service Year Start Date
                    </label>
                    <div className="input-group">
                      <Controller
                        name="serviceYearStartDate"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value }, ...props }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              openTo="year"
                              views={["year"]}
                              onChange={onChange}
                              value={value ? dayjs(value) : null}
                              className="form-control"
                              slotProps={{
                                textField: {
                                  id: "startdate",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="enddate" className="form-label">
                      Service Year End Date
                    </label>
                    <div className="input-group">
                      <Controller
                        name="serviceYearEndDate"
                        control={control}
                        defaultValue={null}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              openTo="year"
                              views={["year"]}
                              onChange={onChange}
                              value={value ? dayjs(value) : null}
                              className="form-control"
                              slotProps={{
                                textField: {
                                  id: "enddate",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                  </div>

                  <FiscalYear register={register} control={control} />
                </div>
                {/* end::Row */}
              </div>
              {/* end::Service Request Dates */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Order details */}

          {/* begin::Client details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>Service Request Clients</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::Service Request Grantees */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <GranteeSelections register={register} control={control} setValue={setValue} getValues={getValues} formFieldName="granteeSelections" />
              </div>
              {/* end::Service Request Grantees */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Client details */}

          {/* begin::Client details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>Service Request Audience</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::Service Request Dates */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>Populations</legend>
                      <LookupCheckboxes register={register} lookupType="ServiceAudience_Populations" formFieldName="lookup_ServiceAudience_Populations" />
                    </fieldset>
                    {/* end::Input */}
                  </div>

                  {/* begin::Inputs */}
                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>Additional Audience</legend>
                      <LookupCheckboxes
                        register={register}
                        lookupType="ServiceAudience_Additional"
                        formFieldName="lookup_ServiceAudience_Additional"
                        onFieldChanged={(val, isChecked) => {
                          if (val === "SAADD_7") {
                            setAdditionalAudienceOtherChecked(isChecked);
                          }
                        }}
                      />
                      <div className={additionalAudienceOtherChecked === false ? "topicothertextbox sr-only" : "topicothertextbox"}>
                        <label className="sr-only" htmlFor="additionalaudienceother">
                          Other:
                        </label>
                        <input type="text" {...register("additionalAudienceOther")} className="form-control" id="additionalaudienceother" />
                      </div>
                    </fieldset>
                    {/* end::Input */}
                  </div>
                </div>
                <div className="row g-5">
                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>Coaching for Success?</legend>

                      {/* begin::Input */}
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="coachingForSuccess" {...register("coachingForSuccess")} />
                        <label className="form-check-label" htmlFor="coachingForSuccess">
                          Yes, this is a Coaching for Success Service Request
                        </label>
                      </div>
                    </fieldset>

                    {/* end::Input */}
                  </div>
                </div>
              </div>
              {/* end::Service Request Dates */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Client details */}

          {/* begin::Client details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>Service Request Evaluations</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::Service Request Dates */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  <div className="col-sm-6">
                    <label htmlFor="PrimaryName" className="form-label">
                      Primary Grantee Service Recipient Name
                    </label>
                    <div className="input-group">
                      <input id="PrimaryName" type="text" className="form-control" {...register("primaryGranteeName")} />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label htmlFor="PrimaryEmail" className="form-label">
                      Primary Grantee Service Recipient Email
                    </label>
                    <div className="input-group">
                      <input
                        id="PrimaryEmail"
                        type="text"
                        className="form-control"
                        {...register("primaryGranteeEmail", {
                          validate: (value, formValues) => {
                            let isValid: boolean = true;

                            let allEmails: string[] = [];

                            if (value != null && value != "") {
                              if (value.includes(";") === true) {
                                allEmails = value.split(";");
                              } else {
                                allEmails.push(value);
                              }
                            }

                            if (allEmails != null && allEmails.length > 0) {
                              allEmails.forEach((te, index) => {
                                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                if (re.test(String(te.trim()).toLowerCase()) === false) {
                                  isValid = false;
                                }
                              });
                            }

                            return isValid;
                          },
                        })}
                      />
                    </div>
                    {formState?.errors.primaryGranteeEmail != null && (
                      <span className="text-danger">You must enter a valid email address. If you are entering multiple email addresses, please separate each with a semicolon.</span>
                    )}
                  </div>

                  {/* begin::Inputs */}
                  {/* <div className="col-sm-6">
                  <label className="form-label" htmlFor="granteeRecipient">
                    Primary Grantee Service Recipient
                  </label>
                  <select id="granteeRecipient" {...register("primaryGranteeId", { required: false })} className="form-select">
                    <option value="">Select...</option>
                    <option value="b449d716-ded8-4491-84e5-8e6eb0d568c9">Alex Vineyard</option>
                    <option value="0036676f-2b57-471f-bfeb-7b553d0eb0f7">Barbara Canzano</option>
                  </select>
                </div> */}

                  <div className="col-sm-6">
                    <fieldset>
                      <legend className="mb-5">Send 60 Day Evaluations?</legend>
                      <div className="form-check form-check-inline">
                        {/* <input className="form-check-input" type="radio" id="yes_radio1" value="true" {...register("sendEvaluation" as const)} onChange={(e) => setValue("sendEvaluation", true, { shouldValidate: true })} checked={watch("sendEvaluation") === control?._formValues?.sendEvaluation} /> */}
                        <input className="form-check-input" type="radio" id="yes_radio1" {...register("sendEvaluation")} value="True" onChange={handleSendEvalOnChange} />
                        <label className="form-check-label" htmlFor="yes_radio1">
                          Yes
                        </label>
                        {/* {watch("sendEvaluation")} */}
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="no_radio1" {...register("sendEvaluation")} value="False" onChange={handleSendEvalOnChange} />
                        <label className="form-check-label" htmlFor="no_radio1">
                          No
                        </label>
                      </div>
                      {/* <input type="hidden" value={watch("sendEvaluation")} {...register("sendEvaluation")} /> */}
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    {sendEvaluation === true && (
                      <div>
                        <label htmlFor="qualtricsurveyurl" className="form-label">Qualtrics Evaluation Survey URL </label>
                        <div className="">
                          {/* <input className="form-check-input" type="radio" id="yes_radio1" value="true" {...register("sendEvaluation" as const)} onChange={(e) => setValue("sendEvaluation", true, { shouldValidate: true })} checked={watch("sendEvaluation") === control?._formValues?.sendEvaluation} /> */}
                          <input type="text" id="qualtricssurveyurl" className="form-control" {...register("qualtricsSurveyUrl")} />

                          {/* {watch("sendEvaluation")} */}
                        </div>

                        {/* <input type="hidden" value={watch("sendEvaluation")} {...register("sendEvaluation")} /> */}
                      </div>
                    )}
                  </div>

                  {/* <div className="col-sm-6">
                  <label htmlFor="qID" className="form-label">
                    Qualtrics Survey ID for T/TA Consultations
                  </label>
                  <div className="input-group">
                    <input id="qID" type="text" className="form-control" {...register("externalSurveyId" as const, { required: false })} />
                  </div>
                </div> */}
                </div>
                {/* end::Row */}
              </div>
              {/* end::Service Request Dates */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Client details */}

          {/* begin::Client details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>SPTAC Team</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::Service Request Dates */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}

                  <div className="col-sm-6">
                    <label htmlFor="sptacmgr" className="form-label">
                      SPTAC Staff Managing the Service
                    </label>
                    <select id="sptacmgr" {...register("managingStaffUserId" as const, { required: false })} className="form-select">
                      <option value="">Select...</option>
                      {staff &&
                        staff?.map((user, index) => (
                          <option key={index} value={user.userId}>
                            {user.firstName} {user.lastName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="granteeRecipient" className="form-label">
                      Initiating Team
                    </label>
                    <select id="granteeRecipient" {...register("initiatingRegionalTeamCode", { required: false })} className="form-select mb-3">
                      <option value="">Select...</option>
                      {lookups
                        .filter((lookup) => {
                          return lookup.lookupType === "RegionalTeams";
                        })
                        .map((lookup, index) => (
                          <option key={index} value={lookup.lookupCode}>
                            {lookup.lookupDescription}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>Other teams involved in service delivery</legend>
                      <LookupCheckboxes register={register} lookupType="RegionalTeams" formFieldName="lookup_RegionalTeams" />
                    </fieldset>
                    {/* end::Input */}
                  </div>

                  {/* <div className="col-md-6">
                  
                  <fieldset>
                    <legend>Strategic Prevention Framework Focus Areas</legend>
                    <LookupCheckboxes register={register} lookupType="FocusAreas" formFieldName="lookup_FocusAreas" />
                  </fieldset>
                  
                </div> */}
                </div>
                {/* end::Row */}
              </div>
              {/* end::Service Request Dates */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Client details */}

          <FocusAreaCard register={register} setValue={setValue} getValues={getValues} />

          {/* begin::Service Request details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>Notes & Comments</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::Service Request Details */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}
                  <div className="col-12">
                    <label htmlFor="comment" className="form-label">
                      Notes & Comments
                    </label>
                    <textarea className="form-control" rows={3} id="comment" {...register("comment" as const, { required: false })}></textarea>
                  </div>
                  <div id="Comments" className="card-body p-0 tab-pane fade active show" role="tabpanel">
                    <Comments comments={serviceRequestData?.comments} />
                  </div>
                </div>
                {/* end::Row */}
              </div>
              {/* end::Service Request Details */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Service Request details */}

          <div className="d-flex justify-content-between bd-highlight mb-3">
            <div>
              <button type="submit" id="" className="btn btn-primary me-5" disabled={submitDisabled}>
                <span className="indicator-label">Save &amp; Close</span>
              </button>

              <a onClick={goBack} className="btn btn-bg-light btn-active-color-gray-900 me-5">
                Cancel
              </a>
            </div>

            {serviceRequestData?.id != null && serviceRequestData?.canDelete === true && (
              <div>
                <button type="button" id="delete_button" className="btn btn-danger" onClick={handleDeleteDialog}>
                  <span className="indicator-label">Delete</span>
                </button>
              </div>
            )}
          </div>
        </div>
        {/* end::Main column */}
      </form>
    </>
  );
};
