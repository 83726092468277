import React, { useContext, useState, useEffect } from "react";
import { useMany, HttpError, BaseRecord, useCreate, usePermissions, useDataProvider } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { Tabs, Tab, Box, CircularProgress } from "@mui/material";

//Local Components
import { parseCamelCaseToSpaces } from "utils/strings";
import { ActivityType, ApprovalWorkflowStatus, ApprovalWorkflowStep, RecordStatus, LookupCode } from "types/enums";
import { encryptData } from "utils/crypto";
import { ApprovalDialogProps } from "components/approvalWorflow/approvalDialog";
import { ApprovalBadge, ApprovalDialog, FocusAreaView, Comments, HourlyStaffExpenseView, GeneralStaffExpensesView } from "components";

import { LookupsContext } from "contexts/lookupsContext";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { canEditServiceRequestAtStep } from "utils/recordStatuses";
import { ExpenseSummary } from "components/generalStaffExpenses/expenseSummary";
//DateTime Conversion/Formatting
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

interface IServiceRequestShowProps {
  data: BaseRecord;
  editUri: string;
}

export const ServiceRequestRead: React.FC<IServiceRequestShowProps> = ({ data, editUri }) => {
  const [serviceRequestData, setServiceRequestData] = useState<BaseRecord | undefined>();
  const [approvalDialogProps, setApprovalDialogProps] = useState<ApprovalDialogProps>();
  const [notApprovalDialogProps, setNotApprovalDialogProps] = useState<ApprovalDialogProps>();
  const [canUseApprovalDialog, setCanUseApprovalDialog] = useState<boolean | undefined>(false);
  const [approvalWorkflowsSorted, setApprovalWorkflowsSorted] = useState<any[]>();
  const [canCreateActivity, setCanCreateActivity] = useState<boolean>(false);

  const { userPermissions } = useContext(LoggedInUserContext);
  const { getLookupDescription, getLookupDescriptions, getParentLookupCode } = useContext(LookupsContext);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [ids, setIds] = useState([]);
  const {
    data: activityDataResult,
    isLoading,
    isError,
  } = useMany<any, HttpError>({
    resource: `activities/servicerequest/${data?.id}`,
    ids,
  });
  const childActivityData = activityDataResult?.data ?? [];
  const [canEditServiceRequest, setCanEditServiceRequest] = useState<boolean>(false);
  const [anyActiveStaff, setAnyActiveStaff] = useState<boolean>(false);
  const [anyActiveExpense, setAnyActiveExpense] = useState<boolean>(false);
  const [notesTabIdx, setNotesTabIdx] = useState<number>(4);
  const [approvalTabIdx, setApprovalTabIdx] = useState<number>(5);

  const userCanPerformStep = (step: ApprovalWorkflowStep): boolean | undefined => {
    switch (step) {
      case ApprovalWorkflowStep.SubmitToCore:
        return userPermissions.canSubmitToCore;
      case ApprovalWorkflowStep.Approve:
      case ApprovalWorkflowStep.NotApprove:
        return userPermissions.canApproveCore;
      case ApprovalWorkflowStep.Complete:
        return userPermissions.canComplete;
      default:
        return false;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

    if (userPermissions.canViewCostBreakdownTab === true) {
      setNotesTabIdx(5);
      setApprovalTabIdx(6);
    }
  }, []);

  useEffect(() => {
    if (data != null) setServiceRequestData(data);


    
  }, [data]);

  useEffect(() => {
    if (serviceRequestData != null) {
      setCanCreateActivity(serviceRequestData?.approvalWorkflowState?.currentStatus === RecordStatus.Approved || serviceRequestData?.approvalWorkflowState?.currentStatus === RecordStatus.InProgress);
      const sortedApprovalWorkflows = serviceRequestData?.approvalWorkflows ? [...serviceRequestData?.approvalWorkflows].reverse() : [];
      setApprovalWorkflowsSorted(sortedApprovalWorkflows);

      //clear props then initialize
      setApprovalDialogProps(undefined);
      setNotApprovalDialogProps(undefined);

      serviceRequestData?.approvalWorkflowState?.nextSteps &&
        serviceRequestData?.approvalWorkflowState?.nextSteps.forEach((step: ApprovalWorkflowStep) => {
          const actionText = parseCamelCaseToSpaces(step, true) ?? step;
          const actionTextError = `You cannot ${actionText} until all required fields are completed.`;
          switch (step) {
            case ApprovalWorkflowStep.SubmitToCore:
              setApprovalDialogProps({
                buttonText: actionText,
                buttonIcon: <i className="fa-solid fa-share-from-square" style={{ paddingLeft: 5, paddingBottom: 3, paddingRight: 0 }} />,
                dialogTitle: actionText,
                dialogContentText: "You may add an optional message below to be sent in the email notification to the next staff member in the workflow.",
                submitButtonText: actionText,
                submissionStatus: ApprovalWorkflowStatus.SubmittedToCore,
                hasValidationErrors: serviceRequestData?.approvalWorkflowState?.hasValidationErrors,
                validationErrors: serviceRequestData?.approvalWorkflowState?.validationErrors,
                dialogContentErrorText: actionTextError,
                validationDetailMessage: "Please edit the Service Request and complete the following fields:",
                onSubmit: () => {},
              });
              setCanUseApprovalDialog(userCanPerformStep(step));
              break;
            case ApprovalWorkflowStep.Approve:
              setApprovalDialogProps({
                statusStyle: "success",
                buttonText: actionText,
                buttonIcon: <></>,
                dialogTitle: actionText,
                dialogContentText: "You may add an optional message below to be sent in the email notification to the next staff member in the workflow.",
                submitButtonText: actionText,
                submissionStatus: ApprovalWorkflowStatus.Approved,
                hasValidationErrors: serviceRequestData?.approvalWorkflowState?.hasValidationErrors,
                validationErrors: serviceRequestData?.approvalWorkflowState?.validationErrors,
                dialogContentErrorText: actionTextError,
                validationDetailMessage: "Please edit the Service Request and complete the following fields:",
                onSubmit: () => {},
              });
              setCanUseApprovalDialog(userCanPerformStep(step));
              break;
            case ApprovalWorkflowStep.NotApprove:
              setNotApprovalDialogProps({
                statusStyle: "danger",
                buttonText: actionText,
                buttonIcon: <></>,
                dialogTitle: `Do ${actionText}`,
                dialogContentText: "You may add an optional message below to be sent in the email notification to the next staff member in the workflow.",
                submitButtonText: `Do ${actionText}`,
                submissionStatus: ApprovalWorkflowStatus.NotApproved,
                validationDetailMessage: "Please edit the Service Request and complete the following fields:",
                onSubmit: () => {},
              });
              setCanUseApprovalDialog(userCanPerformStep(step));
              break;
            case ApprovalWorkflowStep.Complete:
              setApprovalDialogProps({
                statusStyle: "success",
                buttonText: actionText,
                buttonIcon: <></>,
                dialogTitle: actionText,
                dialogContentText: "You may add an optional message below to be sent in the email notification to the next staff member in the workflow.",
                submitButtonText: actionText,
                submissionStatus: ApprovalWorkflowStatus.Completed,
                hasValidationErrors: serviceRequestData?.approvalWorkflowState?.hasValidationErrors,
                validationErrors: serviceRequestData?.approvalWorkflowState?.validationErrors,
                dialogContentErrorText: actionTextError,
                validationDetailMessage: "Please edit the Service Request and complete the following fields:",
                onSubmit: () => {},
              });
              setCanUseApprovalDialog(userCanPerformStep(step));
              break;
          }
        });
      let tmpCanEdit: boolean = canEditServiceRequestAtStep(serviceRequestData?.approvalWorkflowState?.currentStatus, userPermissions);

      setCanEditServiceRequest(tmpCanEdit);
    }
  }, [serviceRequestData]);

  const { data: permissionsData } = usePermissions();
  let permissions = permissionsData as string[]; //TODO: set permissions around 'edit' & 'create new' buttons (may end up using for show/hide areas and content)

  // ---- START TABS ---- (TODO: move tabs into it's own component)
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setActiveTab(newIndex);
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const getActivityTypeFromCode = (code: string): ActivityType => {
    const activityType = getParentLookupCode(code);

    switch (activityType) {
      case LookupCode.ActivityConsultation:
        return ActivityType.Consultation;
      case LookupCode.ActivityEvent:
        return ActivityType.Event;
      default:
        break;
    }
    return ActivityType.Base;
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div className="tab-pane active show" id={"simple-tabpanel-" + index} role="tabpanel" aria-labelledby={"simple-tab-" + index}>
        {value === index && children}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  // ---- END TABS ----

  const createMethod = useCreate();
  const dataProvider = useDataProvider();
  const defaultDataProvider = dataProvider();

  function fiscalYearLabel(fiscalYear: any) {
    if (!fiscalYear?.fiscalYear) return "";
    const quarters: string[] = [];
    fiscalYear.q1 && quarters.push("Q1");
    fiscalYear.q2 && quarters.push("Q2");
    fiscalYear.q3 && quarters.push("Q3");
    fiscalYear.q4 && quarters.push("Q4");
    const quartersText: string = quarters.length > 0 ? "(" + quarters.join(", ") + ")" : "";
    return dayjs(fiscalYear.fiscalYear).year() + " " + quartersText;
  }

  const submitApprovalWorkflow = (submissionStatus: ApprovalWorkflowStatus, message: string) => {
    //console.log("log message called!", message);
    setDisableButtons(true);
    createMethod.mutate(
      {
        resource: "approvalworkflows/servicerequest",
        values: {
          requestedParentId: serviceRequestData?.id,
          approvalStatus: submissionStatus,
          notes: message,
        },
      },
      {
        /* onError: (error, variables, context) => {
          // An error occurred!
        }, */
        onSuccess: (data, variables, context) => {
          setServiceRequestData(data?.data);
          setDisableButtons(false);
        },
      }
    );
  };

  const totalActivityAttendees = (activity: any) => {
    const activityTypeCode = getActivityTypeFromCode(activity?.taMethods_VirtualCode ? activity?.taMethods_VirtualCode : activity?.taMethods_FaceToFaceCode);

    if (activityTypeCode === ActivityType.Consultation) {
      return activity?.granteeAttendancesConsultation?.reduce((accumulator: any, atn: any) => accumulator + atn.numberOfAttendees, 0);
    } else if (activityTypeCode === ActivityType.Event) {
      return activity?.granteeAttendancesEvent?.reduce((accumulator: any, atn: any) => accumulator + atn.numberOfAttendees, 0);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (isError) {
    return <div>Error: Could not load T/TA Activity data.</div>;
  }

  return (
    <>
      {/* begin::Card */}
      <div className="card mb-5 mb-xl-10">
        {/* begin::Card Body */}
        <div className="card-body pt-9 pb-9">
          {/* begin::Title & Actions */}
          <div className="row">
            <div className={`${(canCreateActivity && approvalDialogProps) || (approvalDialogProps && notApprovalDialogProps) ? "col-lg-7" : "col-lg-9"}`}>
              <div className="d-flex align-items-center mb-2 order-lg-1">
                <h2 className="text-gray-900 fs-2 fw-bold me-2 my-0">{serviceRequestData?.title}</h2>
                <ApprovalBadge status={serviceRequestData?.approvalWorkflowState?.currentStatus} />
              </div>
            </div>
            <div className={`${(canCreateActivity && approvalDialogProps) || (approvalDialogProps && notApprovalDialogProps) ? "col-lg-5" : "col-lg-3"}  order-lg-2`}>
              <div className="float-lg-end float-md-start mb-xs-3">
                {canEditServiceRequest === true && (
                  <Link to={editUri} className="btn btn-sm btn-secondary me-2">
                    Edit
                  </Link>
                )}

                {approvalDialogProps && canUseApprovalDialog && (
                  <ApprovalDialog
                    statusStyle={approvalDialogProps?.statusStyle}
                    buttonText={approvalDialogProps?.buttonText ?? ""}
                    dialogTitle={approvalDialogProps?.dialogTitle ?? ""}
                    dialogContentText={approvalDialogProps?.dialogContentText ?? ""}
                    buttonIcon={approvalDialogProps?.buttonIcon}
                    submitButtonText={approvalDialogProps?.submitButtonText ?? ""}
                    submissionStatus={approvalDialogProps?.submissionStatus ?? ""}
                    hasValidationErrors={approvalDialogProps?.hasValidationErrors}
                    validationErrors={approvalDialogProps?.validationErrors}
                    dialogContentErrorText={approvalDialogProps?.dialogContentErrorText ?? ""}
                    validationDetailMessage={approvalDialogProps?.validationDetailMessage}
                    onSubmit={submitApprovalWorkflow}
                    disableButtons={disableButtons}
                  />
                )}

                {notApprovalDialogProps && canUseApprovalDialog && (
                  <ApprovalDialog
                    statusStyle={notApprovalDialogProps?.statusStyle}
                    buttonText={notApprovalDialogProps?.buttonText ?? ""}
                    dialogTitle={notApprovalDialogProps?.dialogTitle ?? ""}
                    dialogContentText={notApprovalDialogProps?.dialogContentText ?? ""}
                    buttonIcon={notApprovalDialogProps?.buttonIcon}
                    submitButtonText={notApprovalDialogProps?.submitButtonText ?? ""}
                    submissionStatus={notApprovalDialogProps?.submissionStatus ?? ""}
                    validationDetailMessage={approvalDialogProps?.validationDetailMessage}
                    onSubmit={submitApprovalWorkflow}
                    disableButtons={disableButtons}
                  />
                )}
                {userPermissions.canCreate && canCreateActivity && (
                  <Link
                    to={`/activities/create/${encodeURIComponent(encryptData(serviceRequestData?.id))}?topics=${encodeURIComponent(
                      JSON.stringify(serviceRequestData?.lookup_ServiceTopics)
                    )}&title=${encodeURIComponent(serviceRequestData?.title)}`}
                    className="btn btn-sm btn-primary me-2"
                  >
                    <i className="fa-regular fa-plus" style={{ paddingBottom: 3, paddingRight: 5 }} />
                    Add New T/TA Activity
                  </Link>
                )}
              </div>
            </div>
          </div>
          {/* end::Title & Actions */}

          {/* begin::Key Info */}
          <div className="row">
            <div className="col-lg-9">
              <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                <span className="font-weight-bold text-gray-700 me-5 mb-2">
                  <i aria-hidden="true" className="fa-solid fa-calendar-days text-gray-700 icon-nm mr-2 me-1" title="Fiscal Year"></i>
                  <span className="sr-only">Fiscal Year:</span>
                  <span>FY </span>
                  {serviceRequestData?.fiscalYears.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                      <span>{fiscalYearLabel(item)}</span>
                      {index < serviceRequestData?.fiscalYears.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                  ))}
                </span>

                <span className="font-weight-bold text-gray-700 me-5 mb-2">
                  <i aria-hidden="true" className="fas fa-map-marker-alt text-gray-700 icon-nm mr-2 me-1" title="Initiating Team"></i>
                  <span className="sr-only">SPTAC Team:</span>
                  <span>{getLookupDescription(serviceRequestData?.initiatingRegionalTeamCode)}</span>
                </span>

                <span className="font-weight-bold text-gray-700 me-5 mb-2">
                  <i aria-hidden="true" className="fas fa-globe text-gray-700 icon-nm mr-2 me-1" title="Service Category"></i>
                  <span className="sr-only">Service Category:</span>
                  {getLookupDescriptions(serviceRequestData?.lookup_ServiceCategories).map((item: string, index: number) => (
                    <React.Fragment key={index}>
                      <span>{item}</span>
                      {index < serviceRequestData?.lookup_ServiceCategories.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                  ))}
                </span>

                <span className="font-weight-bold text-gray-700 me-5 mb-2">
                  <i aria-hidden="true" className="fa-solid fa-user text-gray-700 icon-nm mr-2 me-1" title="SPTAC Staff Managing Service"></i>
                  <span className="sr-only">SPTAC Staff Managing Service:</span>
                  <span>{serviceRequestData?.managingStaffUserName}</span>
                </span>
              </div>
            </div>
          </div>
          {/* end::Key Info */}

          {/* begin::Details */}
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            {/* begin::Info */}
            <div className="flex-grow-1">
              <div className="row">
                <div className="col">
                  <p className="h5 text-black">Need or Problem</p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="text-black fs-6 p-0 m-0">{serviceRequestData?.descriptionNeedProblem}</p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="h5 mt-5 text-black">Description of Service</p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="text-black fs-6 p-0 m-0">{serviceRequestData?.description}</p>
                </div>
              </div>
            </div>
            {/* end::Info */}
          </div>
          {/* end::Details */}
        </div>
      </div>
      {/* end::Card */}

      {/* begin::Card */}
      <div className="card">
        {/* begin::Header */}
        <div className="card-header card-header-stretch overflow-auto">
          {/* begin::Tabs */}
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="Service Request Tabs" variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile={true}>
                <Tab label="Service Request" {...a11yProps(0)} className="fs-4 text-dark" />
                <Tab
                                    label="T/TA Activities"
                  {...a11yProps(1)}
                  className="fs-4 text-dark"
                />
                
                
                <Tab label="Staff" {...a11yProps(2)} className="fs-4 text-dark" />
                <Tab
                                    label="Expenses"
                  {...a11yProps(3)}
                  className="fs-4 text-dark"
                />
                {userPermissions.canViewCostBreakdownTab === true && (
                  <Tab label="Cost Breakdown" 
                  {...a11yProps(4)} 
                  className="fs-4 text-dark" />
                )}
                <Tab label="Comments" {...a11yProps(notesTabIdx)} className="fs-4 text-dark" />
                <Tab label="Approval Workflow" {...a11yProps(approvalTabIdx)} className="fs-4 text-dark" />
              </Tabs>
            </Box>
          </Box>
        </div>
        <div className="card-body fs-6">
          <TabPanel value={activeTab} index={0}>
            <h3 className="h5 mb-3 py-2 text-bg-light">Service Request Details</h3>

            <div className="row">
              <div className="col-lg-6 ">
                <span className="fw-bold d-flex mb-2">Service Year</span>
                <p className="mb-2">
                  {serviceRequestData?.serviceYearStartDate != null &&
                    serviceRequestData?.serviceYearEndDate != null &&
                    dayjs(serviceRequestData?.serviceYearStartDate).format("YYYY") === dayjs(serviceRequestData?.serviceYearEndDate).format("YYYY") &&
                    dayjs(serviceRequestData?.serviceYearStartDate).format("YYYY")}
                  {serviceRequestData?.serviceYearStartDate != null &&
                    serviceRequestData?.serviceYearEndDate != null &&
                    dayjs(serviceRequestData?.serviceYearStartDate).format("YYYY") != dayjs(serviceRequestData?.serviceYearEndDate).format("YYYY") &&
                    dayjs(serviceRequestData?.serviceYearStartDate).format("YYYY") +
                      (serviceRequestData?.serviceYearEndDate ? " - " + dayjs(serviceRequestData?.serviceYearEndDate).format("YYYY") : "")}{" "}
                </p>
              </div>

              <div className="col-lg-6 ">
                <span className="fw-bold d-flex mb-2">Topic Area</span>
                <p>
                  {getLookupDescriptions(serviceRequestData?.lookup_ServiceTopics).map((item: string, index: number) => {
                    if (item.toLowerCase().includes("other") == false) {
                      return (
                        <React.Fragment key={index}>
                          <span>{item}</span>
                          {index < serviceRequestData?.lookup_ServiceTopics.length - 1 ? <span> | </span> : ""}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={index}>
                          <span>{"Other" + (serviceRequestData?.topicOther != null ? " (" + serviceRequestData?.topicOther + ")" : "")}</span>
                          {index < data?.lookup_ServiceTopics.length - 1 ? <span> | </span> : ""}
                        </React.Fragment>
                      );
                    }
                  })}{" "}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 ">
                <span className="fw-bold d-flex mb-2">T/TA Methods (Virtual)</span>
                <p>
                  {getLookupDescriptions(serviceRequestData?.lookup_TAMethods_Virtual).map((item: string, index: number) => (
                    <React.Fragment key={index}>
                      <span>{item}</span>
                      {index < serviceRequestData?.lookup_TAMethods_Virtual.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                  ))}{" "}
                </p>
              </div>

              <div className="col-lg-6 ">
                <span className="fw-bold d-flex mb-2">T/TA Methods (Face to Face)</span>
                <p>
                  {getLookupDescriptions(serviceRequestData?.lookup_TAMethods_FaceToFace).map((item: string, index: number) => (
                    <React.Fragment key={index}>
                      <span>{item}</span>
                      {index < serviceRequestData?.lookup_TAMethods_FaceToFace.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                  ))}{" "}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 ">
                <span className="fw-bold d-flex mb-2">SAMHSA Priorities</span>
                <p>
                  {getLookupDescriptions(serviceRequestData?.lookup_SamhsaPriorities).map((item: string, index: number) => (
                    <React.Fragment key={index}>
                      <span>{item}</span>
                      {index < serviceRequestData?.lookup_SamhsaPriorities.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                  ))}{" "}
                </p>
              </div>

              <div className="col-lg-6 ">
                <span className="fw-bold d-flex mb-2">Service Category</span>
                <p className="mb-2">
                  {getLookupDescriptions(serviceRequestData?.lookup_ServiceCategories).map((item: string, index: number) => (
                    <React.Fragment key={index}>
                      <span>{item}</span>
                      {index < serviceRequestData?.lookup_ServiceCategories.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                  ))}{" "}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 ">
                <span className="fw-bold d-flex mb-2">Other teams involved in service delivery</span>
                <p className="mb-2">
                  {getLookupDescriptions(serviceRequestData?.lookup_RegionalTeams).map((item: string, index: number) => (
                    <React.Fragment key={index}>
                      <span>{item}</span>
                      {index < serviceRequestData?.lookup_RegionalTeams.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                  ))}{" "}
                </p>
              </div>

              <div className="col-lg-6 ">
                <span className="fw-bold d-flex mb-2">Coaching for Success?</span>
                <p className="mb-2">{serviceRequestData?.coachingForSuccess !== null ? (serviceRequestData?.coachingForSuccess ? "Yes" : "No") : "N/A"}</p>
              </div>

              {/* <div className="col-lg-6 ">
                    <span className="fw-bold d-flex mb-2">Strategic Prevention Framework Focus Areas</span>
                    <p className="mb-2">
                      {getLookupDescriptions(serviceRequestData?.lookup_FocusAreas).map((item: string, index: number) => (
                        <>
                          <span>{item}</span>
                          {index < serviceRequestData?.lookup_FocusAreas.length - 1 ? <span> | </span> : ""}
                        </>
                      ))}{" "}
                    </p>
                  </div> */}
            </div>

            <h3 className="h5 mb-3 py-2 text-bg-light mt-3">Client Details</h3>

            <div className="row">
              <div className="col-lg-6">
                <span className="fw-bold d-flex mb-2">Region & Grantee</span>
                {serviceRequestData?.granteeSelections.map((item: any, index: number) => (
                  <React.Fragment key={index}>
                    <p className="mb-2">
                      {getLookupDescription(item.regionCode)} &gt;&nbsp;
                      {getLookupDescription(item.granteeTypeCode)} &gt;&nbsp;
                      {getLookupDescription(item.granteeCode)} &gt;&nbsp;
                      {item.grantProgramCode.startsWith("All") ? "All Grant Programs" : item.grantProgramCode}
                    </p>
                  </React.Fragment>
                ))}
              </div>

              <div className="col-lg-6">
                <span className="fw-bold d-flex mb-2">Primary Service Recipient Contact Name</span>
                <p>
                  {serviceRequestData?.primaryGranteeName} {serviceRequestData?.primaryGranteeEmail ? " (" + serviceRequestData?.primaryGranteeEmail + ")" : ""}
                  {serviceRequestData?.sendEvaluation === "True" && <i aria-hidden="true" className="fas fa-envelope text-gray-700 icon-nm mr-2 me-1 ml-3" title="Send 60 Day Evaluation"></i>}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <span className="fw-bold d-flex mb-2">Populations</span>
                <p>
                  {getLookupDescriptions(serviceRequestData?.lookup_ServiceAudience_Populations).map((item: string, index: number) => (
                    <React.Fragment key={index}>
                      <span>{item}</span>
                      {index < serviceRequestData?.lookup_ServiceAudience_Populations.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                  ))}{" "}
                </p>
              </div>
              <div className="col-lg-6">
                <span className="fw-bold d-flex mb-2">Additional Audience</span>
                {getLookupDescriptions(serviceRequestData?.lookup_ServiceAudience_Additional).map((item: string, index: number) => {
                  if (item.toLowerCase().includes("other") == false) {
                    return (
                      <React.Fragment key={index}>
                        <span>{item}</span>
                        {index < serviceRequestData?.lookup_ServiceAudience_Additional.length - 1 ? <span> | </span> : ""}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={index}>
                        <span>{"Other" + (serviceRequestData?.additionalAudienceOther != null ? " (" + serviceRequestData?.additionalAudienceOther + ")" : "")}</span>
                        {index < data?.lookup_ServiceAudience_Additional.length - 1 ? <span> | </span> : ""}
                      </React.Fragment>
                    );
                  }
                })}
              </div>
            </div>

            <FocusAreaView data={serviceRequestData} />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <h3 className="h5 mb-3 py-2 text-bg-light">T/TA Activities</h3>

            <table className="table table-sm">
              <thead>
                <tr className="fw-bold mb-2">
                  <th scope="col">Title</th>
                  <th scope="col">T/TA Method</th>
                  <th scope="col">Activity Dates</th>
                  <th scope="col">Status</th>
                  <th scope="col"># of Clients Attended</th>
                </tr>
              </thead>
              <tbody>
                {childActivityData?.map((activity) => (
                  <tr key={activity?.id}>
                    <td>
                      <Link to={`/activities/read/${encodeURIComponent(encryptData(activity.id))}`}>{activity.title}</Link>
                    </td>
                    <td>
                      {(activity.taMethods_VirtualCode ? getLookupDescription(activity.taMethods_VirtualCode) + " (Virtual)" : "") +
                        (activity.taMethods_FaceToFaceCode ? getLookupDescription(activity.taMethods_FaceToFaceCode) + " (Face to Face)" : "")}
                    </td>
                    <td>
                      {(activity.activityStartDate !== null ? dayjs(activity.activityStartDate).format("MM/DD/YYYY") : "") +
                        (activity.activityEndDate !== null ? " - " + dayjs(activity.activityEndDate).format("MM/DD/YYYY") : "")}
                    </td>
                    <td>{parseCamelCaseToSpaces(activity.approvalWorkflowState.currentStatus)}</td>
                    <td>{totalActivityAttendees(activity)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <HourlyStaffExpenseView hourlyExpenses={serviceRequestData?.hourlyExpenses} activityData={childActivityData} />
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <GeneralStaffExpensesView data={serviceRequestData} activityData={childActivityData} />
          </TabPanel>

          {userPermissions.canViewCostBreakdownTab === true && (
            <TabPanel value={activeTab} index={4}>
              <ExpenseSummary data={serviceRequestData} activityData={childActivityData} isActivityView={false} />
            </TabPanel>
          )}
          <TabPanel value={activeTab} index={notesTabIdx}>
            <div id="Comments" className="card-body p-0 tab-pane fade active show" role="tabpanel">
              <h3 className="h5 mb-5 py-2 text-bg-light">Notes & Comments</h3>
              <Comments comments={serviceRequestData?.comments} />
            </div>
          </TabPanel>
          <TabPanel value={activeTab} index={approvalTabIdx}>
            <h3 className="h5 mb-5 py-2 text-bg-light">Approval Workflow</h3>

            <table className="table table-sm">
              <thead>
                <tr className="fw-bold mb-2">
                  <th scope="col" className="w-15">
                    Date
                  </th>
                  <th scope="col">Status</th>
                  <th scope="col" className="w-25">
                    Created By
                  </th>
                </tr>
              </thead>
              <tbody>
                {approvalWorkflowsSorted?.map((approval: any, index: number) => (
                  <tr key={index}>
                    <td>{dayjs(approval.createDate) && dayjs.utc(approval.createDate).tz("America/New_York").format("MM/DD/YYYY hh:mm A") + " EST"}</td>
                    <td>
                      {parseCamelCaseToSpaces(approval.approvalStatus)}{" "}
                      {approval.approvalStatus === ApprovalWorkflowStatus.NotApproved && (approval.notes ? "(Reason: " + approval.notes + ")" : "(No Reason Provided)")}
                    </td>
                    <td>{approval.createUserName}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <h3 className="h5 mb-3 mt-10 py-2 text-bg-light ">Edit History</h3>

            
            <table className="table table-sm">
              
              <thead>
                <tr className="fw-bold">
            <th scope="col" className="w-15">Created By</th>
            <th scope="col" className="w-55">Created On</th>
            <th scope="col" className="w-15">Last Edited By</th>
            <th scope="col" className="w-15">Last Edited On</th>
                  </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{serviceRequestData?.createUserName}</td>
                  <td>{dayjs.utc(serviceRequestData?.createDate).tz("America/New_York").format("MM/DD/YYYY hh:mm A")} EST</td>
                  <td>{serviceRequestData?.updateUserName}</td>
                  <td>{serviceRequestData?.updateDate && dayjs.utc(serviceRequestData?.updateDate).tz("America/New_York").format("MM/DD/YYYY hh:mm A") + " EST"}</td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </div>
      </div>

      {/* end::Tabs */}

      {/* end::Header */}

      {/* begin::Footer */}
      <div className="card-footer py-8"></div>
      {/* end::Footer */}

      {/* end::Card */}
    </>
  );
};
