import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { Collapse } from "@mui/material";
// import { GridColDef } from "@mui/x-data-grid";
// import dayjs from "dayjs";

//Local Components
// import CustomDataGrid from "components/datagrid";
// import { encryptData } from "utils/crypto";

import { ActivityFilterCriteria } from "components/form/activityFilterCriteria";
// import { LookupsContext } from "contexts/lookupsContext";
import { Control } from "react-hook-form";
import { HSFilterCriteria } from "types/filterCriteria";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { BreadcrumbContext } from "contexts/breadcrumbContext";

// import { string } from "yargs";
import { ActivityRegionChart } from "components/charts/ActivityRegionChart";
import { ActivityTopicChart } from "components/charts/ActivityTopicChart";
import { ActivityPopulationChart } from "components/charts/ActivityPopulationChart";
import { ActivityFToFMethodsChart } from "components/charts/ActivityFToFMethodsChart";
import { ActivityVMethodsChart } from "components/charts/ActivityVMethods";
import { ActivityPrioritiesChart } from "components/charts/ActivityPrioritiesChart";
import { ActivitySPFFocusChart } from "components/charts/ActivitySPFFocusChart";
import { ActivityNumStat } from "components/charts/ActivityNumStat";
import { ActivityRecipientsStat } from "components/charts/ActivityRecipientsStat";
import { ActivityHoursStat } from "components/charts/ActivityHoursStat";
import { ActivityStaffExpensesStat } from "components/charts/ActivityStaffExpensesStat";
import { ActivityNonStaffExpensesStat } from "components/charts/ActivityNonStaffExpensesStat";
import { ActivityTotalExpensesStat } from "components/charts/ActivityTotalExpensesStat";
import { ActivityListChart } from "components/charts/ActivityListChart";
import { ActivityActualHoursStat } from "components/charts/ActivityActualHoursStat";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { ActivityList } from "components/activity/list";
import { GranteeSelectionOptionType, GranteeSelectionFilterType, RecordStatus } from "types/enums";

type ActivityDashboardProps = {
  isFilterExpanded?: boolean;

  isExpenseExpanded?: boolean;
  onFilterResults?: () => void;
};

export const DashboardList: React.FC<ActivityDashboardProps> = ({ isFilterExpanded, isExpenseExpanded, onFilterResults }) => {
  const [activityData, setActivityData] = useState<BaseRecord[]>([]);
  const [filteredDataForCharts, setFilteredActivityDataForCharts] = useState<BaseRecord[]>([]);
  const [filterForm, setFilterForm] = useState<Control>();
  const [dashboardIsLoading, setDashboardIsLoading] = useState<boolean>(false);
  const { setBreadcrumbs } = useContext(BreadcrumbContext);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);

  const [activityTotals, setActivityTotals] = useState([]);
  const [activityGroupTotals, setActivityGroupTotals] = useState([]);

  const defaultHSFilters: HSFilterCriteria = {
    statusCodesCSV: RecordStatus.Approved + "," + RecordStatus.Completed,
  };

  const [hsFilters, setHSFilters] = useState<HSFilterCriteria>(defaultHSFilters);
  const [activitiesGridLoading, setActivitiesGridLoading] = useState<boolean>(false);
  const [totalNumActivities, setTotalNumActivities] = useState<number>(0);
  const [groupTotalsLoading, setGroupTotalsLoading] = useState<boolean>(false);

  const [totalsIsLoading, setTotalsIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setFilterExpanded(false);

    setExpanded(false);

    filterResults(hsFilters);
  }, []);

  useEffect(() => {
    if (isFilterExpanded != null) {
      setFilterExpanded(isFilterExpanded);
    }
  }, [isFilterExpanded]);

  useEffect(() => {
    if (isExpenseExpanded != null) {
      setExpanded(isExpenseExpanded);
    }
  }, [isExpenseExpanded]);

  const { userPermissions } = useContext(LoggedInUserContext);

  const filterResults = (filterCriteria: HSFilterCriteria) => {
    //if (dashboardIsLoading === false) {
    setDashboardIsLoading(true);
    setGroupTotalsLoading(true);
    setTotalsIsLoading(true);
    setHSFilters({ ...filterCriteria, isGranteeAttendee: true });

    const getFilteredData = async () => {
      if (filterCriteria.orderColumn == null || filterCriteria.orderDirection === "") {
        filterCriteria.orderColumn = "createDate";
      }
      if (filterCriteria.orderDirection == null || filterCriteria.orderDirection === "") {
        filterCriteria.orderDirection = "desc";
      }

      axiosInstance
        .get(`${API_URL}/activities/totals`, {
          headers: {
            "Content-Type": "application/json", // or the required content type
          },
          params: {
            ...filterCriteria,

            isGranteeAttendee: true,
            //$filter: filterCriteria.oDataFilter,
            // $orderby: "title",
            // $top: 10,
            // $skip: 0,
          },
        })
        .then((response) => {
          //console.log("here80", response.data);

          setTotalsIsLoading(false);
          setActivityTotals(response?.data);

          setActivityGroupTotals(response?.data);

          setGroupTotalsLoading(false);
          setDashboardIsLoading(false);
        })
        .catch((error) => {
          console.error("here90", error);
        });

      //axiosInstance.get(`${API_URL}/activities/grouptotals/`, {
      //  headers: {
      //    "Content-Type": "application/json", // or the required content type
      //  },
      //  params: {
      //    ...filterCriteria,
      //
      //    isGranteeAttendee: true,
      //    //$filter: filterCriteria.oDataFilter,
      //    // $orderby: "title",
      //    // $top: 10,
      //    // $skip: 0,
      //  },
      //}).then((response) => {
      //  //console.log("here110", response.data);
      //  setGroupTotalsLoading(false);
      //  setDashboardIsLoading(false);
      //
      //
      //
      //  setActivityGroupTotals(response.data);
      //
      //}).catch((error) => {
      //  console.error("here120", error);
      //});

      filterActivityGrid(filterCriteria);
    };

    getFilteredData();

    if (onFilterResults != null) {
      onFilterResults();
    }
    setFilterExpanded(false);
    window.scrollTo(0, 0);
    //}
  };

  const clearFilter = () => {
    //let emptyFilters: HSFilterCriteria = {};

    filterResults(defaultHSFilters);

    //setFilteredActivityDataForCharts(activityData);
    if (onFilterResults != null) {
      onFilterResults();
    }

    // Clear last breadcrumb to remove the filter desciption label
    setBreadcrumbs([{ label: "Dashboard", path: "/dashboard" }]);
    window.scrollTo(0, 0);
  };

  const filterActivityGrid = async (filterCriteria: HSFilterCriteria) => {
    if (activitiesGridLoading === false) {
      setActivitiesGridLoading(true);

      axiosInstance
        .get(`${API_URL}/activities/filter/`, {
          headers: {
            "Content-Type": "application/json", // or the required content type
          },
          params: {
            ...filterCriteria,

            isGranteeAttendee: true,
            //$filter: filterCriteria.oDataFilter,
            // $orderby: "title",
            // $top: 10,
            // $skip: 0,
          },
        })
        .then((response) => {
          //console.log("here130", response.data);

          setFilteredActivityDataForCharts(response?.data?.activities);

          setTotalNumActivities(response?.data?.totalNumActivities);

          setActivitiesGridLoading(false);
        })
        .catch((error) => {
          console.error("here132", error);
        });
    }
  };
  const updateGrid = (searchVal: string, page: number, columnOrderActive: string, columnSortDir: string) => {
    filterActivityGrid({
      ...hsFilters,
      searchVal: searchVal,
      pageNumber: page,
      orderColumn: columnOrderActive,
      orderDirection: columnSortDir,
    });
  };

  useEffect(() => {}, [hsFilters]);

  const defaultFilterValues = {
    granteeSelections: [
      {
        regionCode: GranteeSelectionOptionType.AllRegions,
        granteeTypeCode: GranteeSelectionOptionType.AllGranteeTypes,
        granteeCode: GranteeSelectionOptionType.AllGrantees,
        grantProgramCode: GranteeSelectionOptionType.AllGrantPrograms,
      },
    ],
    population: "",
    audience: "",
    focusArea: "",
    coaching: "",
    statuses: [RecordStatus.Approved, RecordStatus.Completed],

    lookup_ServiceTopics: [],
    lookup_TAMethods_Virtual: [],
    lookup_TAMethods_FaceToFace: [],
    includeApprovedNotComplete: false,
  };

  return (
    <div className="content flex-row-fluid" id="exp_content">
      <ActivityFilterCriteria
        filterResults={filterResults}
        clearFilter={clearFilter}
        isFilterExpanded={filterExpanded}
        granteeSelectionFilterType={GranteeSelectionFilterType.Invited}
        defaultFilterOverride={defaultFilterValues}
      />
      <div className="row gy-5 g-xl-10">
        <div className="col-md-3 mb-xl-10 mb-sm-5">
          <div className="card h-lg-100">
            <div className="card-body d-flex justify-content-between align-items-start flex-column">
              <div className="m-0">
                <i className="fa-solid fa-chalkboard-user fs-1" style={{ color: "#4c5f90" }}></i>
              </div>

              <div className="d-flex flex-column my-7">
                <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                  <ActivityNumStat activityTotals={activityTotals} filterForm={filterForm} isLoading={totalsIsLoading} />
                </span>

                <div className="m-0">
                  <span className="fw-semibold fs-6 text-gray-700">Activities</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-xl-10 mb-sm-5">
          <div className="card h-lg-100">
            <div className="card-body d-flex justify-content-between align-items-start flex-column">
              <div className="m-0">
                <i className="fa-solid fa-users fs-1" style={{ color: "#4c5f90" }}></i>
              </div>

              <div className="d-flex flex-column my-7">
                <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                  <ActivityRecipientsStat activityTotals={activityTotals} filterForm={filterForm} isLoading={totalsIsLoading} />
                </span>

                <div className="m-0">
                  <span className="fw-semibold fs-6 text-gray-700">Recipients</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-xl-10 mb-sm-5">
          <div className="card h-lg-100">
            <div className="card-body d-flex justify-content-between align-items-start flex-column">
              <div className="m-0">
                <i className="fa-solid fa-clock fs-1" style={{ color: "#4c5f90" }}></i>
              </div>

              <div className="d-flex flex-column my-7">
                <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                  <ActivityHoursStat activityTotals={activityTotals} filterForm={filterForm} isLoading={totalsIsLoading} />
                </span>

                <div className="m-0">
                  <span className="fw-semibold fs-6 text-gray-700">Staff Hours</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-xl-10 mb-sm-5 mb-xs-5">
          <div className="card h-lg-100">
            <div className="card-body d-flex justify-content-between align-items-start flex-column">
              <div className="m-0">
                <i className="fa-regular fa-clock fs-1" style={{ color: "#4c5f90" }}></i>
              </div>

              <div className="d-flex flex-column my-7">
                <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                  <ActivityActualHoursStat activityTotals={activityTotals} filterForm={filterForm} isLoading={totalsIsLoading} />
                </span>

                <div className="m-0">
                  <span className="fw-semibold fs-6 text-gray-700">Delivery Hours</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {userPermissions.canViewStaffRates === true && (
        <Collapse in={expanded}>
          <div className="" id="collapseExpenses">
            <div className="card mb-5 mb-xl-10">
              <div className="card-header">
                <h2 className="card-title align-items-start flex-column">
                  <span className="card-label fw-semibold text-dark">Staff Rates & Expenses</span>
                </h2>
              </div>

              <div className="card-body py-10">
                <div className="row">
                  <div className="col">
                    <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                      <span className="fs-4 fw-semibold text-dark pb-1 px-2">Staff Rates</span>
                      <span className="fs-lg-2x fw-semibold d-flex justify-content-center">
                        <ActivityStaffExpensesStat activityTotals={activityTotals} filterForm={filterForm} isLoading={totalsIsLoading} />
                      </span>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                      <span className="fs-4 fw-semibold text-dark pb-1 px-2">Expenses</span>
                      <span className="fs-lg-2x fw-semibold d-flex justify-content-center">
                        <ActivityNonStaffExpensesStat activityTotals={activityTotals} filterForm={filterForm} isLoading={totalsIsLoading} />
                      </span>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                      <span className="fs-4 fw-semibold text-dark pb-1 px-2">Total Expenses</span>
                      <span className="fs-lg-2x fw-semibold d-flex justify-content-center">
                        <ActivityTotalExpensesStat activityTotals={activityTotals} filterForm={filterForm} isLoading={totalsIsLoading} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      )}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-md-12">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Activities by Region</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityRegionChart activityGroupTotals={activityGroupTotals} filterForm={filterForm} isLoading={groupTotalsLoading} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-md-12">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Activities by Topic</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityTopicChart activityGroupTotals={activityGroupTotals} filterForm={filterForm} isLoading={groupTotalsLoading} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-md-12">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Activities by Population</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityPopulationChart activityGroupTotals={activityGroupTotals} filterForm={filterForm} isLoading={groupTotalsLoading} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-md-3">
          <div className="card card-flush dashboardpiecard">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Methods (Face)</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityFToFMethodsChart activityGroupTotals={activityGroupTotals} filterForm={filterForm} isLoading={groupTotalsLoading} />
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card card-flush h-100">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Methods (Virtual)</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityVMethodsChart activityGroupTotals={activityGroupTotals} filterForm={filterForm} isLoading={groupTotalsLoading} />
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card card-flush h-100">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">Priorities</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityPrioritiesChart activityGroupTotals={activityGroupTotals} filterForm={filterForm} isLoading={groupTotalsLoading} />
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card card-flush h-100">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">SPF Focus Area</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivitySPFFocusChart activityGroupTotals={activityGroupTotals} filterForm={filterForm} isLoading={groupTotalsLoading} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-sm-12">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">Activities</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityListChart
                  hsFilters={hsFilters}
                  isLoading={activitiesGridLoading}
                  filteredDataForGrid={filteredDataForCharts}
                  updateGrid={updateGrid}
                  totalNumActivities={totalNumActivities}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
