import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { ActivityGroupTotals } from "types/chartData";
import { API_URL } from "App";
import { Control } from "react-hook-form";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { HSPieChart } from "components/charts/HSPieChart";


type ActivityChartProps = {
  activityGroupTotals?: ActivityGroupTotals[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const ActivityPrioritiesChart: React.FC<ActivityChartProps> = ({ activityGroupTotals, filterForm, isLoading }) => {
  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const { getLookupDescription } = useContext(LookupsContext);
  
  useEffect(() => {
    

    let tmpChartData: { name: string; y: number }[] = [];
    if (activityGroupTotals != null)
    {
      

      for(var i = 0; i < activityGroupTotals.length; i++) {

        if (activityGroupTotals[i].type === "Priorities") {

          tmpChartData.push(
            {
              name: activityGroupTotals[i].itemDesc ?? "",
              y: activityGroupTotals[i].value ?? 0
            });
        }
      }
    }
setChartData(tmpChartData);

  }, [activityGroupTotals, isLoading]);









  if ((chartData == null || chartData.length <=0) && isLoading === false)
    {
return (<div>No Activities matching this filter criteria.</div>);
    }



  
  
  
    return <HSPieChart reportCode="ACTIVITYMETHODSFTOF" chartData={chartData} isLoading={isLoading} />;

};