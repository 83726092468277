import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import ServerSideDataGrid from "components/datagrid/serverSideDataGrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ServiceRequestFilterCriteria } from "components";
import { FilterCriteria, HSFilterCriteria } from "types/filterCriteria";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { BreadcrumbContext } from "contexts/breadcrumbContext";
import { GranteeSelectionFilterType } from "types/enums";

const NO_ROWS_LABEL = "No Service Requests.";
const NO_ROWS_LABEL_FILTERED = "No Service Requests match the filter criteria: ";
const NO_ROWS_LABEL_FILTERED_APPROVALS = "You have no additional Service Requests to Approve. Please deselect the filter in order to see all Service Requests.";

type ServiceRequestListProps = {
  isFilterExpanded?: boolean;
  onFilterResults?: () => void;
};

export const ServiceRequestList: React.FC<ServiceRequestListProps> = ({ isFilterExpanded, onFilterResults }) => {
  const [serviceRequestData, setServiceRequestData] = useState<BaseRecord[]>([]);
  const [filteredDataFromOdata, setFilteredDataFromOdata] = useState<BaseRecord[] | null>(null);
  const [filteredDataForGrid, setFilteredDataForGrid] = useState<BaseRecord[]>([]);
  //const [filteredData, setFilteredData] = useState<BaseRecord[]>([]);
  const [noRowsLabel, setNoRowsLabel] = useState<string>(NO_ROWS_LABEL);
  const { 
    filterServiceRequestsByPendingApprovals, 
    setFilterServiceRequestsByPendingApprovals, 
    getRecordStatusesForUserApproval,
  userPermissions
  } = useContext(LoggedInUserContext);
  const { getLookupDescriptions } = useContext(LookupsContext);
  const [page, setPage] = useState<number>(0);
  const { setBreadcrumbs } = useContext(BreadcrumbContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string | undefined>("");
  const [totalNumRows, setTotalNumRows] = useState<number>(1);


  const [hsFilters, setHSFilters] = useState<HSFilterCriteria>();

  const [columnOrderActive, setColumnOrderActive] = useState<string>("createDate");
  const [columnSortDir, setColumnSortDir] = useState<string>("desc");

  const MAX_HEIGHT = 200;
  const ellipsisStyle = {
    display: "-webkit-box",
    //"-webkit-line-clamp": 2, // Define the number of lines to show before applying ellipsis
    //"-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: `${MAX_HEIGHT}px`,
  };

  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      flex: 0.75,
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return (
          <Link to={`/servicerequests/read/${encodeURIComponent(encryptData(params.id))}`} className="text-gray-700 text-hover-primary mb-1">
            SR-{params.row.id}
          </Link>
        );
      },
      valueGetter(params) {
        return "SR-" + params.row.id;
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      headerClassName: "fw-bold",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return <div>{params.row.title}</div>;
      },
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      minWidth: 300,
      cellClassName: "wrap-text cell-align-top",
      renderCell: (params) => <div style={ellipsisStyle}>{params.value}</div>,
    },
    {
      field: "fiscalYearsCombined",
      headerName: "FISCAL YEAR",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return <>{params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ")}</>;
      },
      valueGetter(params) {
        return params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
      },
    },
    {
      field: "lookup_ServiceTopics",
      headerName: "TOPIC",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return <>{getLookupDescriptions(params.row.lookup_ServiceTopics).join(" | ")}</>;
      },
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_ServiceTopics).join(" | ");
      },
    },
    {
      field: "lookup_TAMethods_Virtual",
      headerName: "TA METHOD",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        let finalLookupDescriptions: string[] = [];

        finalLookupDescriptions = [...getLookupDescriptions(params.row.lookup_TAMethods_FaceToFace), ...getLookupDescriptions(params.row.lookup_TAMethods_Virtual)];

        return <>{finalLookupDescriptions.join(" | ")}</>;
      },

      valueGetter(params) {
        let finalLookupDescriptions: string[] = [];

        finalLookupDescriptions = [...getLookupDescriptions(params.row.lookup_TAMethods_FaceToFace), ...getLookupDescriptions(params.row.lookup_TAMethods_Virtual)];

        return finalLookupDescriptions.join(" | ");
      },
    },
    // {
    //   field: "lookup_ServiceAudience_Populations",
    //   flex: 1,
    //   headerName: "POPULATION",
    //   cellClassName: "wrap-text cell-align-top",
    //   renderCell: function (params: any) {
    //     return <>{getLookupDescriptions(params.row.lookup_ServiceAudience_Populations).join(" | ")}</>;
    //   },
    //   valueGetter(params) {
    //     return getLookupDescriptions(params.row.lookup_ServiceAudience_Populations).join(" | ");
    //   },
    // },
    {
      field: "approvalStatus",
      flex: 1,
      headerName: "APPROVAL STATUS",
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return <ApprovalBadge status={params.row.approvalWorkflowState?.currentStatus} />;
      },
      valueGetter(params) {
        return params.row.approvalWorkflowState?.currentStatus;
      },
    },
    {
      field: "createDate",
      flex: 1,
      headerName: "APPROVAL STATUS",
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return params.row.createDate;
      },
      valueGetter(params) {
        return params.row.createDate;
      },
      hide: true
    },
  ];

  //useEffect(() => {
  //  setServiceRequestData(data);
  //  if (filterServiceRequestsByPendingApprovals) {
  //    setFilteredDataForGrid(getFilteredDataForUserApproval(data));
  //    setNoRowsLabel(NO_ROWS_LABEL_FILTERED_APPROVALS);
  //  } else {
  //    setFilteredDataForGrid(data); //initially set to "full" data set
  //  }
  //}, [data]);






    const handleFilterApprovalsChange = (event: ChangeEvent<HTMLInputElement>) => {
    //console.log("handleFilterApprovalsChange", event.target.checked);
    let pendingCSV = "";
    
        if (event.target.checked === true) {
          pendingCSV = getRecordStatusesForUserApproval().join(",");
          setFilterServiceRequestsByPendingApprovals(true);
        } else {
          pendingCSV = "";

          setFilterServiceRequestsByPendingApprovals(false);
        }
    


        filterResults({
          ...hsFilters,
          searchVal: searchVal,
          pageNumber: page,
          pendingStatusCSV: pendingCSV,
        });
      };
  

  const getFilteredDataForUserApproval = (data: BaseRecord[]) => {
    return data?.filter((activity: any) => getRecordStatusesForUserApproval().includes(activity.approvalWorkflowState?.currentStatus));
  };


  const filterResults = (filterCriteria: HSFilterCriteria) => {


    setIsLoading(true);
    const getFilteredData = async () => {
      
      

      axiosInstance.get(`${API_URL}/servicerequests/newfilters`, {
              headers: {
                "Content-Type": "application/json", // or the required content type
              },
              params: {
                ...filterCriteria,
      
                isGranteeAttendee: true,
                //$filter: filterCriteria.oDataFilter,
                // $orderby: "title",
                // $top: 10,
                // $skip: 0,
              },
            }).then((response) => {
              //console.log("here130", response.data);
      
              
              
              setFilteredDataForGrid(response?.data?.serviceRequests);
      
      setTotalNumRows(response?.data?.totalNumServiceRequests);

      setIsLoading(false);
            }).catch((error) => {
              console.error("here132", error);
            });

      
      
      
      //if (filterServiceRequestsByPendingApprovals) {
      //  setFilteredDataForGrid(getFilteredDataForUserApproval(response?.data));
      //} else {
      //  setFilteredDataForGrid(response?.data);
      //}
      //setNoRowsLabel(`${filterServiceRequestsByPendingApprovals ? "Within your Pending Approvals: " : ""} ${NO_ROWS_LABEL_FILTERED} ${filterCriteria.filterDescription}`);

      // Set last breadcrumb to be the filter desciption label
      //if (filterCriteria.filterDescription) {
      //  setBreadcrumbs([
      //    { label: "Service Requests", path: "/servicerequests" },
      //    { label: filterCriteria.filterDescription, path: "" },
      //  ]);
      //} else {
      //  setBreadcrumbs([{ label: "Service Requests", path: "/servicerequests" }]);
      //}
    };
    getFilteredData();
    window.scrollTo(0, 0);
    if (onFilterResults != null) {
      onFilterResults();
    }
  };

  const clearFilter = () => {
    //setFilteredDataFromOdata(null);
    //if (filterServiceRequestsByPendingApprovals) {
    //  setFilteredDataForGrid(getFilteredDataForUserApproval(serviceRequestData));
    //  setNoRowsLabel(NO_ROWS_LABEL_FILTERED_APPROVALS);
    //} else {
    //  setFilteredDataForGrid(serviceRequestData);
    //  setNoRowsLabel(NO_ROWS_LABEL);
    //}
    //// Clear last breadcrumb to remove the filter desciption label
    //setBreadcrumbs([{ label: "Service Requests", path: "/servicerequests" }]);
    //window.scrollTo(0, 0);
    //if (onFilterResults != null) {
    //  onFilterResults();
    //}






    const hsFilters: HSFilterCriteria = {};

    filterResults(hsFilters);
  };
  
  const onColumnOrderChange = (orderColumnName?: string, orderDir?: string) => {
    setColumnOrderActive(orderColumnName ?? "");

    setColumnSortDir(orderDir ?? "");
  };


 //useEffect(() => {
//
 // filterResults(hsFilters ?? {});
 //}, []);
//


  
 useEffect(() => {
  let pendingCSV: string = "";

  if (filterServiceRequestsByPendingApprovals != null && filterServiceRequestsByPendingApprovals === true) {
    pendingCSV = getRecordStatusesForUserApproval().join(",");
  }

  filterResults({
    ...hsFilters,
    searchVal: searchVal,
    pageNumber: page,
    pendingStatusCSV: pendingCSV,
    orderColumn: columnOrderActive,
    orderDirection: columnSortDir,
  });
}, [searchVal, page, columnOrderActive, columnSortDir]);


const filterResultsEvent = (filterCriteria: HSFilterCriteria) => {

let pendingCSV: string = "";



  if (filterServiceRequestsByPendingApprovals != null && filterServiceRequestsByPendingApprovals === true) {
    pendingCSV = getRecordStatusesForUserApproval().join(",");
  }

  filterResults({
    ...filterCriteria,
    searchVal: searchVal,
    pageNumber: page,
    pendingStatusCSV: pendingCSV,
    orderColumn: columnOrderActive,
    orderDirection: columnSortDir,
  });

}

  return (
    <>
      {/* begin::Post */}
      <div className="content flex-row-fluid" id="content">
        <ServiceRequestFilterCriteria 
        filterResults={filterResultsEvent}
        clearFilter={clearFilter} 
        granteeSelectionFilterType={GranteeSelectionFilterType.Invited} 
        isFilterExpanded={isFilterExpanded} 
        setHSFilters={setHSFilters}
        />
        {/* begin::Card */}
        <div className="card mb-5 mb-xl-10">
          {/* begin::Card header */}
          <div className="card-header border-0 pt-6">
            {/* begin::Card title */}
            <div className="card-title">
              {/* begin::Search */}

              {/* end::Search */}
            </div>
            {/* begin::Card title */}

            {/* begin::Card toolbar */}
            <div className="card-toolbar">
              {/* begin::Toolbar */}
              <div className="d-flex justify-content-end" data-kt-subscription-table-toolbar="base">
                {/* begin::Filter Button */}
                {/* <a href="/" className="btn btn-secondary  me-5">
                    <i className="fa-solid fa-filter mb-1"></i>
                    Filter
                  </a> */}
                {/* end::Filter Button */}

                {/* begin::Add Button */}
                <div className="form-check">





                {(userPermissions.canApproveCore === true || userPermissions.canApproveFiscal === true || userPermissions.canApproveSamhsa === true) &&
                  (

                  <FormGroup>
                    
                    <FormControlLabel control={<Switch checked={filterServiceRequestsByPendingApprovals} onChange={handleFilterApprovalsChange} />} label="My Pending Approvals" />
                  </FormGroup>
                  )}
                </div>
                {/* end::Add Button */}
              </div>
              {/* end::Toolbar */}
            </div>
            {/* end::Card toolbar */}
          </div>
          {/* end::Card header */}

          {/* begin::Card body */}
          <div className="card-body pt-0" style={{ marginTop: -50 }}>
            {/* begin::Table */}
            <div className="table-responsive">
              
            <ServerSideDataGrid
                rows={filteredDataForGrid}
                columns={columns}
                pageSize={25}
                noRowsLabel={noRowsLabel}
                setPage={setPage}
                onSearch={setSearchVal}
                searchVal={searchVal}
                totalNumRows={totalNumRows}
                page={page}
                setOrder={onColumnOrderChange}
                isLoading={isLoading}
              />
              
              
            </div>
            {/* end::Table */}
          </div>
          {/* end::Card body */}
        </div>
        {/* end::Card */}
      </div>
      {/* end::Post */}
    </>
  );
};
