import React, { useState, useEffect, useContext } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { GridColDef } from "@mui/x-data-grid";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { LookupsContext } from "contexts/lookupsContext";

const NO_ROWS_LABEL = "No Staff records.";

type StaffListProps = {
  data: BaseRecord[];
};

export const StaffList: React.FC<StaffListProps> = ({ data }) => {
  const [staffData, setStaffData] = useState<BaseRecord[]>([]);

  const { userPermissions } = useContext(LoggedInUserContext);
  const { getLookupDescriptions } = useContext(LookupsContext);

  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting
  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "NAME",
      flex: 1,
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {userPermissions.canUpdateStaff ? (
              <Link to={`/staff/edit/${encodeURIComponent(encryptData(params.row.userId))}`} className="text-gray-700 text-hover-primary mb-1">
                {params?.row?.firstName != null && params?.row?.firstName != "" ? (
                  <span>
                    {params.row.firstName} {params.row.lastName}
                  </span>
                ) : (
                  ""
                )}
              </Link>
            ) : (
              <span>
                {params.row.firstName} {params.row.lastName}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      cellClassName: "cell-align-top",
      renderCell: (params: any) => {
        return <div>{params.row.email}</div>;
      },
    },
    {
      field: "activityStartDate",
      headerName: "APPROVAL NOTIFICATIONS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {params.row.isCoreApprover ? "CORE" : ""}
            {params.row.isCoreApprover && params.row.isFiscalApprover ? " | " : ""}
            {params.row.isFiscalApprover ? "Fiscal" : ""}
            {(params.row.isCoreApprover || params.row.isFiscalApprover) && params.row.isSamhsaApprover ? " | " : ""}
            {params.row.isSamhsaApprover ? "SAMHSA" : ""}
          </>
        );
      },
      valueGetter(params) {
        return params.row.isCoreApprover
          ? "CORE"
          : "" + params.row.isCoreApprover && params.row.isFiscalApprover
          ? " | "
          : "" + params.row.isFiscalApprover
          ? "Fiscal"
          : "" + (params.row.isCoreApprover || params.row.isFiscalApprover) && params.row.isSamhsaApprover
          ? " | "
          : "" + params.row.isSamhsaApprover
          ? "SAMHSA"
          : "";
      },
    },
    {
      field: "lookup_RegionalTeams",
      headerName: "ASSOCIATED REGIONS",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return <>{getLookupDescriptions(params.row.lookup_RegionalTeams).join(" | ")}</>;
      },
      valueGetter(params) {
        return getLookupDescriptions(params.row.lookup_RegionalTeams).join(" | ");
      },
    },

    {
      field: "isConsultant",
      headerName: "CONSULTANT",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
                 if (params.row.isConsultant != null && params.row.isConsultant == true)
          {
            return "Yes";
          }
        return "";
      },
      valueGetter(params) {
        if (params.row.isConsultant != null && params.row.isConsultant == true)
          {
            return "Yes";
          }
        return "";
      },
    },
    
  ];

  useEffect(() => {
    let tmpData: BaseRecord[] = [];

    if (data != null) {
      tmpData = [...data].sort((a, b) => a.firstName != null && a.firstName.localeCompare(b.firstName));
    }

    setStaffData(tmpData);

  }, [data]);

  return (
    <>
      {/* begin::Post */}
      <div className="content flex-row-fluid" id="content">
        {/* begin::Card */}
        <div className="card mb-5 mb-xl-10">
          {/* begin::Card header */}
          <div className="card-header border-0 pt-6">
            {/* begin::Card title */}
            <div className="card-title">
              {/* begin::Search */}
              {/* <div className="d-flex align-items-center position-relative my-1"> */}
              {/* begin::Icon */}
              {/* <span className="position-absolute ms-7">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </span> */}
              {/* end::Icon */}

              {/* <label htmlFor="search" className="visually-hidden">
                    Search:{" "}
                  </label>
                  <input type="text" name="search" id="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search Service Requests" />
                </div> */}
              {/* end::Search */}
            </div>
            {/* begin::Card title */}

            {/* begin::Card toolbar */}
            <div className="card-toolbar">
              {/* begin::Toolbar */}
              <div className="d-flex justify-content-end" data-kt-subscription-table-toolbar="base">
                {/* begin::Filter Button */}
                {/* <a href="/" className="btn btn-secondary  me-5">
                    <i className="fa-solid fa-filter mb-1"></i>
                    Filter
                  </a> */}
                {/* end::Filter Button */}

                {/* begin::Add Button */}

                {/* end::Add Button */}
              </div>
              {/* end::Toolbar */}
            </div>
            {/* end::Card toolbar */}
          </div>
          {/* end::Card header */}

          {/* begin::Card body */}
          <div className="card-body pt-0" style={{ marginTop: -50 }}>
            {/* begin::Table */}
            <div className="table-responsive">
              {/* <DataTable data={filteredData} columns={activityColumns} /> */}
              <CustomDataGrid rows={staffData} columns={columns} pageSize={25} noRowsLabel={NO_ROWS_LABEL} />
            </div>
            {/* end::Table */}
          </div>
          {/* end::Card body */}
        </div>
        {/* end::Card */}
      </div>
      {/* end::Post */}
    </>
  );
};
