import React, { useEffect, useState } from "react";
import { useGetIdentity, useLogout, useMenu } from "@refinedev/core";
import { Link } from "react-router-dom";

import logo from "assets/media/logos/sptac-logo.png";

type IUser = {
  id: number;
  name: string;
  avatar: string;
  given_name: string;
  family_name: string;
  idTokenClaims?: any;
};

export const Header: React.FC = () => {
  const { data: user } = useGetIdentity<IUser>();
  const { menuItems, selectedKey } = useMenu();

  const [firstName, setFirstName] = useState<string>("");

  const SignOutButton = () => {
    const { mutate: logout } = useLogout();
    return (
      <button className="btn btn-link hssignout" onClick={() => logout({})}>
        Sign Out
      </button>
    );
  };

  useEffect(() => {
    if (firstName !== user?.idTokenClaims?.given_name) {
      setFirstName(user?.idTokenClaims?.given_name);
    }
  }, [user]);

  // useEffect(() => {
  //   // Create a script element
  //   const script = document.createElement("script");

  //   // Set the script source to your external JavaScript file
  //   script.src = "assets/js/scripts.bundle.js"; // Replace with the correct path to your external JavaScript file

  //   // Append the script element to the document's body
  //   document.body.appendChild(script);

  //   // Clean up the script element when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      {/* begin::Header */}
      <header id="header" className="header d-print-none" data-kt-sticky="false">
        {/* begin::Container */}
        <div className="container-xxl d-flex flex-grow-1 flex-stack">
          {/* begin::Header Logo */}
          <div className="d-flex align-items-center me-5">
            {/* begin::Heaeder menu toggle */}
            <div className="d-lg-none btn btn-icon btn-active-color-primary w-30px h-30px ms-n2 me-3" id="header_menu_toggle">
              {/* begin::Svg Icon | path: icons/duotune/abstract/abs015.svg */}
              <span className="svg-icon svg-icon-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              {/* end::Svg Icon */}
            </div>
            {/* end::Heaeder menu toggle */}
            {/* begin::Logo */}
            <a href="/">
              <img alt="Strategic Prevention Technical Assistance Center Logo" src={logo} className="h-50px h-lg-70px me-2 me-lg-9" />
            </a>
            {/* end::Logo */}
          </div>
          {/* end::Header Logo */}
          {/* begin::Toolbar wrapper */}

          <div className="topbar d-flex align-items-stretch flex-shrink-0" id="topbar">
            {/* begin::User info */}
            <div className="w-auto d-flex align-items-center btn-lg px-2">
              <div className="d-flex flex-column text-right pr-3">
                <span className="text-dark">
                  <span className="fw-semibold">
                    Welcome&nbsp;
                    {firstName}
                  </span>{" "}
                  |
                  <span className="fw-semibold">
                    <SignOutButton />
                  </span>
                </span>
              </div>
            </div>
            {/* end::User info */}
          </div>

          {/* end::Toolbar wrapper */}
        </div>
        {/* end::Container */}
        {/* begin::Container */}
        <div className="header-menu-container d-flex align-items-stretch flex-stack h-lg-75px w-100" id="header_nav">
          {/* begin::Menu wrapper */}
          <div
            className="header-menu container-xxl flex-column align-items-stretch flex-lg-row"
            data-kt-drawer="true"
            data-kt-drawer-name="header-menu"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#header_menu_toggle"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#body', lg: '#header_nav'}"
          >
            {/* begin::Menu */}
            <nav
              aria-label="Primary"
              className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-700 menu-state-primary menu-arrow-gray-400 fw-semibold my-5 my-lg-0 align-items-stretch flex-grow-1 px-2 px-lg-0"
              id="#header_menu"
              data-kt-menu="true"
            >
              {menuItems
                .filter((item) => {
                  return item.meta?.label!;
                })
                .map(({ route, label }) => (
                  <React.Fragment key={label}>
                    {/* begin:Menu item */}
                    <div className={`menu-item me-0 me-lg-5 ${route === selectedKey ? "here" : ""}`}>
                      {/* begin:Menu link */}
                      <Link to={route} className="menu-link py-3">
                        <span className="menu-title">{label}</span>
                      </Link>
                      {/* end:Menu link */}
                    </div>
                    {/* end:Menu item */}
                  </React.Fragment>
                ))}
            </nav>
            {/* end::Menu */}
          </div>
          {/* end::Menu wrapper */}
        </div>
        {/* end::Container */}
      </header>
      {/* end::Header */}
    </>
  );
};
