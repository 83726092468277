import React, { createContext, useState, useEffect, ReactNode } from "react";
import { BaseRecord, usePermissions } from "@refinedev/core";
import { useMsal } from "@azure/msal-react";

import { AccountInfo } from "@azure/msal-browser";
import { TokenClaims } from "@azure/msal-common";
import dataProvider from "@refinedev/simple-rest";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { RecordStatus, AppUserRoles } from "types/enums";
import { UserPermissions } from "types/userPermissions";

interface LoggedInUserContextProps {
  user: BaseRecord;
  userPermissions: UserPermissions;
  filterActvitiesByPendingApprovals: boolean;
  setFilterActvitiesByPendingApprovals: (shouldFilter: boolean) => void;
  filterServiceRequestsByPendingApprovals: boolean;
  setFilterServiceRequestsByPendingApprovals: (shouldFilter: boolean) => void;
  getRecordStatusesForUserApproval: () => RecordStatus[];
}

export const LoggedInUserContext = createContext<LoggedInUserContextProps>({
  user: {},
  userPermissions: {},
  filterActvitiesByPendingApprovals: false,
  setFilterActvitiesByPendingApprovals: () => {},
  filterServiceRequestsByPendingApprovals: false,
  setFilterServiceRequestsByPendingApprovals: () => {},
  getRecordStatusesForUserApproval: () => [],
});

const LOCALSTORAGE_KEY_FILTER_ACTIVITY_PENDING_APPROVALS = "filterActvitiesByPendingApprovals";
const LOCALSTORAGE_KEY_FILTER_SERVICEREQUEST_PENDING_APPROVALS = "filterServiceRequestsByPendingApprovals";

type LoggedInUserProviderProps = {
  children?: ReactNode;
};

const LoggedInUserProvider: React.FC<LoggedInUserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<BaseRecord>({});
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({});
  const [filterActvitiesByPendingApprovals, setFilterActvitiesByPendingApprovals] = useState<boolean>(false);
  const [filterServiceRequestsByPendingApprovals, setFilterServiceRequestsByPendingApprovals] = useState<boolean>(false);

  const { accounts } = useMsal();
  const userAccount = accounts && accounts?.length > 0 ? accounts[0] : null;
  const userId = userAccount?.localAccountId ?? "";

  const getUserPermissions = (): UserPermissions => {
    let permissions: UserPermissions = {
      canViewExpenses: false,
      canEditAfterComplete: false,
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
      canSubmitToCore: false,
      canApproveCore: false,
      canApproveFiscal: false,
      canApproveSamhsa: false,
      canEditServiceRequestAfterComplete: false,
      canEditServiceRequestAfterSubmitToCore: false,
      canEditServiceRequestAfterApproved: false,
      canEditServiceRequestEvaluationPending: false,
      canEditServiceRequestEvaluationSent: false,
      canEditActivityAfterSubmitToCore: false,
      canEditActivityAfterSubmitToFiscal: false,
      canEditActivityAfterSubmitToSamsha: false,
      canViewCostBreakdownTab: false
    };

    const userRolesClaim = userAccount?.idTokenClaims?.sptacRoles?.toString() ?? "";

    let usrSptacRoles: string[] = [];
    if (userRolesClaim != null) {
      usrSptacRoles = userRolesClaim.split(",").map((item) => item.trim());
    }


    //Note: User may have multiple roles assigned, we need to 'turn on' a superset of permissions
    usrSptacRoles.forEach((role: string) => {
      switch (role) {
        case AppUserRoles.SystemAdmin: //Can do everything
          permissions.canViewExpenses = true;
          permissions.canEditAfterComplete = true;
          permissions.canRead = true;
          permissions.canCreate = true;
          permissions.canUpdate = true;
          permissions.canDelete = true;
          permissions.canSubmitToCore = true;
          permissions.canApproveCore = true;
          permissions.canApproveFiscal = true;
          permissions.canApproveSamhsa = true;
          permissions.canComplete = true;
          permissions.canViewStaffRates = true;
          permissions.canUpdateStaff = true;
          permissions.canDeleteStaff = true;
          permissions.canEditServiceRequestAfterComplete = true;
          permissions.canEditServiceRequestAfterSubmitToCore = true;
          permissions.canEditServiceRequestEvaluationPending = true;
          permissions.canEditServiceRequestEvaluationSent = true;
          permissions.canEditActivityAfterSubmitToCore = true;
          permissions.canEditActivityAfterSubmitToFiscal = true;
          permissions.canEditActivityAfterSubmitToSamsha = true;
          permissions.canEditServiceRequestAfterApproved = true;
          permissions.canViewCostBreakdownTab = true;
          break;
        case AppUserRoles.Admin:
          permissions.canViewExpenses = true;
          permissions.canEditAfterComplete = true;
          permissions.canRead = true;
          permissions.canCreate = true;
          permissions.canUpdate = true;
          permissions.canDelete = true;
          permissions.canSubmitToCore = true;
          permissions.canApproveCore = true;
          permissions.canApproveFiscal = true;
          permissions.canComplete = true;
          permissions.canViewStaffRates = true;
          permissions.canUpdateStaff = true;
          permissions.canDeleteStaff = true;
          permissions.canEditServiceRequestAfterComplete = true;
          permissions.canEditServiceRequestAfterSubmitToCore = true;
          permissions.canEditServiceRequestEvaluationPending = true;
          permissions.canEditServiceRequestEvaluationSent = true;
          permissions.canEditActivityAfterSubmitToCore = true;
          permissions.canEditActivityAfterSubmitToFiscal = true;
          permissions.canEditActivityAfterSubmitToSamsha = true;
          permissions.canEditServiceRequestAfterApproved = true;
          permissions.canViewCostBreakdownTab = true;
          break;
        case AppUserRoles.Editor:
          permissions.canRead = true;
          permissions.canCreate = true;
          permissions.canRead = true;
          permissions.canUpdate = true;
          permissions.canDelete = true;
          permissions.canSubmitToCore = true;
          permissions.canComplete = true;
          permissions.canEditServiceRequestAfterApproved = true;
          break;
        case AppUserRoles.SAMHSA:
          permissions.canRead = true;
          permissions.canApproveSamhsa = true;
          permissions.canViewStaffRates = true;
          permissions.canViewExpenses = true;
          permissions.canViewCostBreakdownTab = true;
          break;
        case AppUserRoles.Reader:
          permissions.canRead = true;
          break;
        default: //Can not do anything
          break;
      }
    });
    return permissions;
  };

  useEffect(() => {
    const setUserFromApi = async () => {
      const result = await dataProvider(API_URL, axiosInstance).getOne({ resource: "users", id: userId });
      setUser(result?.data ?? {});
    };
    setUserFromApi();

    setUserPermissions(getUserPermissions());

    const filterActivities = localStorage.getItem(LOCALSTORAGE_KEY_FILTER_ACTIVITY_PENDING_APPROVALS + userId);
    if (filterActivities) {
      setFilterActvitiesByPendingApprovals(JSON.parse(filterActivities));
    }

    const filterServiceRequests = localStorage.getItem(LOCALSTORAGE_KEY_FILTER_SERVICEREQUEST_PENDING_APPROVALS + userId);
    if (filterServiceRequests) {
      setFilterServiceRequestsByPendingApprovals(JSON.parse(filterServiceRequests));
    }
  }, []);

  const setShouldFilterActvitiesByPendingApprovals = (shouldFilter: boolean) => {
    localStorage.setItem(LOCALSTORAGE_KEY_FILTER_ACTIVITY_PENDING_APPROVALS + userId, JSON.stringify(shouldFilter));
    setFilterActvitiesByPendingApprovals(shouldFilter);
  };

  const setShouldFilterServiceRequestsByPendingApprovals = (shouldFilter: boolean) => {
    localStorage.setItem(LOCALSTORAGE_KEY_FILTER_SERVICEREQUEST_PENDING_APPROVALS + userId, JSON.stringify(shouldFilter));
    setFilterServiceRequestsByPendingApprovals(shouldFilter);
  };

  const getRecordStatusesForUserApproval = () => {





    

    let approvalStatuses: RecordStatus[] = [];
    if (user?.isCoreApprover) {
      approvalStatuses.push(RecordStatus.SubmittedToCore);
    }
    if (user?.isFiscalApprover) {
      approvalStatuses.push(RecordStatus.SubmittedToFiscal);
    }
    if (user?.isSamhsaApprover) {
      approvalStatuses.push(RecordStatus.SubmittedToSAMHSA);
    }
    return approvalStatuses;
  };

  return (
    <LoggedInUserContext.Provider
      value={{
        user,
        userPermissions,
        filterActvitiesByPendingApprovals,
        setFilterActvitiesByPendingApprovals: setShouldFilterActvitiesByPendingApprovals,
        filterServiceRequestsByPendingApprovals,
        setFilterServiceRequestsByPendingApprovals: setShouldFilterServiceRequestsByPendingApprovals,
        getRecordStatusesForUserApproval,
      }}
    >
      {children}
    </LoggedInUserContext.Provider>
  );
};

export default LoggedInUserProvider;
