import React, { useContext, useEffect, useState } from "react";
import { BaseRecord, useMany, HttpError, useCreate, useUpdate } from "@refinedev/core";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

//Local Components
import { LookupsContext } from "contexts/lookupsContext";
import { encryptData } from "utils/crypto";
import {
  ApprovalBadge,
  LookupCheckboxes,
  GranteeSelections,
  HourlyStaffExpense,
  DailyStaffExpense,
  TransportationStaffExpense,
  MileageStaffExpense,
  GranteeAttendanceConsultation,
  GranteeAttendanceEvent,
  FocusAreaCard,
  Comments,
  AlertDialog,
  ApprovalDialog,
} from "components";
import { LookupCode, ActivityType, RecordStatus } from "types/enums";
import { isActivityApproved } from "utils/recordStatuses";
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";

import { EvalImportWizard } from "components/form/evalImportWizard";
import { ApprovalWorkflowStatus, ApprovalWorkflowStep } from "types/enums";
import { axiosInstance } from "index";
import { API_URL } from "../../App";
import { useMutation } from "react-query";
import { NumericFormat } from "react-number-format";
import { ApprovalDialogProps } from "components/approvalWorflow/approvalDialog";

type ActivityCreateUpdateProps = {
  serviceRequestId?: number;
  serviceRequestTopics?: string[];
  activityId?: number;
  activityData?: BaseRecord;
};

/*TODO: replace .sort with copy-of pattern
                      {staff
                        ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
                        ?.map((user, index) => (
                          <option key={index} value={user.userId}>
                            {user.firstName} {user.lastName}
                          </option>
                        ))}
*/

export const ActivityCreateUpdate: React.FC<ActivityCreateUpdateProps> = ({ serviceRequestId, serviceRequestTopics, activityId, activityData }) => {
  const [topics, setTopics] = useState<string[]>([]);
  const [selectedMethodVirtual, setSelectedMethodVirtual] = useState<string | null>(null);
  const [selectedMethodFaceToFace, setSelectedMethodFaceToFace] = useState<string | null>(null);
  const [activityType, setActivityType] = useState<ActivityType>(ActivityType.Base);
  const [openActivityTypeChangeDialog, setOpenActivityTypeChangeDialog] = useState<boolean>(false);
  const [additionalAudienceOtherChecked, setAdditionalAudienceOtherChecked] = useState<boolean>(false);

  const { lookups, getLookupDescriptions, getParentLookupCode } = useContext(LookupsContext);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [withdrawnDialogOpen, setWithdrawnDialogOpen] = useState<boolean>(false);
  useEffect(() => {
    if (serviceRequestTopics !== null) {
      setTopics(serviceRequestTopics ?? []);
    }
    if (activityData !== null && activityData !== undefined) {
      setTopics(activityData?.serviceRequest_Lookup_ServiceTopics);
      setSelectedMethodVirtual(activityData?.taMethods_VirtualCode);
      setSelectedMethodFaceToFace(activityData?.taMethods_FaceToFaceCode);
      setActivityType(getActivityTypeFromCode(activityData?.taMethods_VirtualCode ? activityData?.taMethods_VirtualCode : activityData?.taMethods_FaceToFaceCode));
    }

    if (activityData?.lookup_ServiceAudience_Additional?.includes("SAADD_7") === true) {
      setAdditionalAudienceOtherChecked(true);
    }
  }, [serviceRequestTopics, activityData]);

  const isForUpdate = activityId !== undefined;

  const { data, isLoading, isError } = useMany<any, HttpError>({
    resource: "users/staff",
    ids: [],
  });
  let staff = data?.data ?? [];

  if (data != null) {
    staff = data?.data.sort((a, b) => {
      let aName = `${a.firstName} ${a.lastName}`;
      let bName = `${b.firstName} ${b.lastName}`;

      return aName.localeCompare(bName, undefined, { sensitivity: "base" });
    });
  }

  const updateMethod = useUpdate();
  const createMethod = useCreate();
  const navigate = useNavigate();

  const { register, control, handleSubmit, formState, setValue, getValues } = useForm({
    defaultValues: isForUpdate ? activityData : { serviceRequestId: serviceRequestId }, //Load existing Activity data is it was supplied (aka "Edit") -or- set to just serviceRequestId for creating new Activity under existing parent
  });

  //TODO: move to shared function
  // const isActivityApproved = (): boolean => {
  //   return (
  //     //activityData?.approvalWorkflowState?.currentStatus && activityData?.approvalWorkflowState?.currentStatus !== RecordStatus.Draft && activityData?.approvalWorkflowState?.currentStatus !== RecordStatus.Submitted && activityData?.approvalWorkflowState?.currentStatus !== RecordStatus.NotApproved
  //     activityData?.approvalWorkflowState?.currentStatus && activityData?.approvalWorkflowState?.currentStatus === RecordStatus.Approved
  //   );
  // };

  const getActivityTypeFromCode = (code: string): ActivityType => {
    const activityType = getParentLookupCode(code);

    switch (activityType) {
      case LookupCode.ActivityConsultation:
        return ActivityType.Consultation;
      case LookupCode.ActivityEvent:
        return ActivityType.Event;
      default:
        break;
    }
    return ActivityType.Base;
  };

  const handleMethodVirtualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleActivityTypeChange(event.target.value);
    //Set selected for set
    setValue("taMethods_VirtualCode", event.target.value);
    setSelectedMethodVirtual(event.target.value);
    //Remove from other set
    setValue("taMethods_FaceToFaceCode", null);
    setSelectedMethodFaceToFace(null);
    //Set Activity Type
    setActivityType(getActivityTypeFromCode(event.target.value));
  };

  const handleMethodFaceToFaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleActivityTypeChange(event.target.value);
    //Set selected for set
    setValue("taMethods_FaceToFaceCode", event.target.value);
    setSelectedMethodFaceToFace(event.target.value);
    //Remove from other set
    setValue("taMethods_VirtualCode", null);
    setSelectedMethodVirtual(null);
    //Set Activity Type
    setActivityType(getActivityTypeFromCode(event.target.value));
    //}
  };

  const handleActivityTypeChange = (methodChangingTo: string) => {
    let shouldAlert = false;
    if (isActivityApproved(activityData)) {
      var typeChangingTo = getActivityTypeFromCode(methodChangingTo);
      shouldAlert = activityType != ActivityType.Base ? activityType != typeChangingTo : false;
    }
    setOpenActivityTypeChangeDialog(shouldAlert);
  };

  const handleActivityTypeChangeDialogConfirm = () => {
    setOpenActivityTypeChangeDialog(false);
  };

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(deleteDialogOpen === true ? false : true);
  };

  const deleteMutation = useMutation(
    (dta) => {
      return axiosInstance.delete(`${API_URL}/activities/${activityData?.id}`);
    },
    {
      onSuccess: () => {
        // Handle a successful deletion

        navigate("/activities");
      },
      onError: (error) => {
        // Handle an error during deletion
      },
    }
  );

  const submitApprovalWorkflow = (submissionStatus: ApprovalWorkflowStatus, message: string, redirectTo?: string) => {
    createMethod.mutate(
      {
        resource: "approvalworkflows/activity",
        values: {
          requestedParentId: activityData?.id,
          approvalStatus: submissionStatus,
          notes: message,
        },
        successNotification: (data, values, resource) => {
          return {
            message: "Successfully updated Activity status: ", //TODO: get this message from the API and/or reword
            description: "Success",
            type: "success",
          };
        },
        errorNotification: false,
      },
      {
        /* onError: (error, variables, context) => {
        // An error occurred!
      }, */
        onSuccess: (data, variables, context) => {
          navigate("/activities");

          //setActivityData(data?.data); //refresh form data (remember: updating state forces a component rerender)
        },
      }
    );
  };

  const deleteActivity = async () => {
    deleteMutation.mutate();

    //if (response?.status != null && response?.status === 200)
    //{
    //  navigate("/activities");
    //}
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (isError) {
    return <div>Error: Could not load Staff information.</div>;
  }

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (data: BaseRecord) => {
    setSubmitDisabled(true);

    if (isForUpdate) {
      updateMethod.mutate(
        {
          resource: "activities",
          values: data,
          id: activityId,
          successNotification: (data, values, resource) => {
            return {
              message: "Successfully updated Activity.",
              description: "Success",
              type: "success",
            };
          },
        },
        {
          /* onError: (error, variables, context) => {
            // An error occurred!
console.log("here120", error);
            navigate('/error/index/');
          }, */
          onSuccess: (filteredData, variables, context) => {
            navigate(`/activities/read/${encodeURIComponent(encryptData(activityId))}`);
            window.scrollTo(0, 0);
          },
        }
      );
    } else {
      createMethod.mutate(
        {
          resource: "activities",
          values: data,
          successNotification: (data, values, resource) => {
            return {
              message: "Successfully created Activity.",
              description: "Success",
              type: "success",
            };
          },
        },
        {
          /* onError: (error, variables, context) => {
            // An error occurred!
            ///navigate('/error/index/');
          }, */
          onSuccess: (data, variables, context) => {
            navigate(`/activities/read/${encodeURIComponent(encryptData(data?.data.id ?? ""))}`);
          },
        }
      );
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && e.target.type !== "submit") {
      e.preventDefault();
    }
  };

  return (
    <>
      <AlertDialog
        title="Activity Type Change"
        content={`Changing the Activity Type from ${
          activityType == ActivityType.Consultation ? ActivityType.Event : ActivityType.Consultation
        } to ${activityType} will remove any existing attendance data. Are you sure you want to continue?`}
        open={openActivityTypeChangeDialog}
        confirmButtonText="OK"
        onConfirm={handleActivityTypeChangeDialogConfirm}
        onCancel={handleActivityTypeChangeDialogConfirm}
      />
      <AlertDialog
        title="Delete Activity"
        content="Are you sure you want to delete this T/TA Activity?"
        open={deleteDialogOpen}
        confirmButtonText="Delete"
        onConfirm={deleteActivity}
        onCancel={handleDeleteDialog}
      />

      <form
        id="ecommerce_edit_order_form"
        onSubmit={handleSubmit(onSubmit)}
        className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework"
        data-kt-redirect="/metronic8/demo16/../demo16/apps/ecommerce/sales/listing.html"
        onKeyDown={handleKeyDown}
      >
        {/* begin::Main column */}
        <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
          {/* begin::T/TA Activity details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2 className="me-2">T/TA Activity Details</h2>
                {isForUpdate && <ApprovalBadge status={activityData?.approvalWorkflowState?.currentStatus} />}
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::T/TA Activity Details */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}
                  <div className="col-12">
                    <label htmlFor="title" className="required form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("title", {
                        required: true,
                      })}
                      id="title"
                    />
                    {formState.errors?.title && <span className="text-danger">Title is required</span>}
                  </div>

                  <div className="col-12">
                    <label htmlFor="need" className="form-label">
                      Description of Need or Problem
                    </label>
                    <textarea className="form-control" rows={3} id="need" {...register("descriptionNeedProblem")}></textarea>
                  </div>

                  <div className="col-12">
                    <label htmlFor="service" className="form-label">
                      Activity Description
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      id="service"
                      {...register("description", {
                        required: false,
                      })}
                    ></textarea>
                  </div>
                </div>
                {/* end::Row */}
              </div>
              {/* end::T/TA Activity Details */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::T/TA Activity details */}

          {/* begin::T/TA Activity Methods & Topics */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>T/TA Activity Methods & Topics</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::T/TA Activity Methods & Topics */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}
                  <div className="col-sm-12">
                    <legend className="mb-2">Topic Area</legend>
                  </div>

                  <div className="col-sm-12 mt-0">
                    <p className="list-unstyled text-gray-700 fw-semibold fs-6 p-0 m-0">
                      {getLookupDescriptions(topics).map((item: string, index: number) => (
                        <span key={index}>
                          <span>{item}</span>
                          {index < topics.length - 1 ? <span> | </span> : ""}
                        </span>
                      ))}
                    </p>
                  </div>

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>T/TA Methods (Virtual)</legend>
                      {lookups
                        .filter((lookup) => {
                          return lookup.lookupType === "TAMethods_Virtual";
                        })
                        .map((lookup, index) => (
                          <div key={index}>
                            <Controller
                              key={lookup.lookupCode}
                              control={control}
                              name="taMethods_VirtualCode"
                              render={({ field }) => (
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id={lookup.lookupCode}
                                    key={index}
                                    className="form-check-input"
                                    {...field}
                                    value={lookup.lookupCode}
                                    checked={selectedMethodVirtual === lookup.lookupCode}
                                    onChange={handleMethodVirtualChange}
                                  />
                                  <label className="form-check-label" htmlFor={lookup.lookupCode}>
                                    {lookup.lookupDescription}
                                  </label>
                                </div>
                              )}
                            />
                          </div>
                        ))}
                    </fieldset>
                    {/* end::fieldset */}
                  </div>

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>T/TA Methods (Face to Face)</legend>
                      {lookups
                        .filter((lookup) => {
                          return lookup.lookupType === "TAMethods_FaceToFace";
                        })
                        .map((lookup, index) => (
                          <div key={index}>
                            <Controller
                              key={lookup.lookupCode}
                              control={control}
                              name="taMethods_FaceToFaceCode"
                              render={({ field }) => (
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id={lookup.lookupCode}
                                    key={index}
                                    className="form-check-input"
                                    {...field}
                                    value={lookup.lookupCode}
                                    checked={selectedMethodFaceToFace === lookup.lookupCode}
                                    onChange={handleMethodFaceToFaceChange}
                                  />
                                  <label className="form-check-label" htmlFor={lookup.lookupCode}>
                                    {lookup.lookupDescription}
                                  </label>
                                </div>
                              )}
                            />
                          </div>
                        ))}
                    </fieldset>
                    {/* end::fieldset */}
                  </div>

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>SAMHSA Priorities</legend>
                      <LookupCheckboxes register={register} lookupType="SamhsaPriorities" formFieldName="lookup_SamhsaPriorities" />
                    </fieldset>
                    {/* end::fieldset */}
                  </div>

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>Activity Category</legend>
                      <LookupCheckboxes register={register} lookupType="ServiceCategories" formFieldName="lookup_ServiceCategories" />
                    </fieldset>
                    {/* end::fieldset */}
                  </div>
                </div>
                {/* end::Row */}
              </div>
              {/* end::T/TA Activity Methods & Topics */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Methods & Topics details */}

          {/* begin::Dates details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>T/TA Activity Dates</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::T/TA Activity Dates */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}
                  <div className="col-sm-6">
                    <label htmlFor="startdate" className="form-label">
                      Activity Start Date
                    </label>
                    <div className="input-group">
                      <Controller
                        name="activityStartDate"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value }, ...props }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              onChange={onChange}
                              value={value ? dayjs(value) : null}
                              className="form-control"
                              slotProps={{
                                textField: {
                                  id: "startdate",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="enddate" className="form-label">
                      Activity End Date
                    </label>
                    <Controller
                      name="activityEndDate"
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value }, ...props }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={onChange}
                            value={value ? dayjs(value) : null}
                            className="form-control"
                            slotProps={{
                              textField: {
                                id: "enddate",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </div>

                  <div className="col-sm-6">
                    <label htmlFor="projectofficerapproval" className="form-label">
                      Deadline for Project Officer Approval
                    </label>
                    <div className="input-group">
                      <Controller
                        name="projectOfficerApprovalDeadline"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value }, ...props }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              onChange={onChange}
                              value={value ? dayjs(value) : null}
                              className="form-control"
                              slotProps={{
                                textField: {
                                  id: "projectofficerapproval",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                  </div>
                </div>
                {/* end::Row */}
              </div>
              {/* end::T/TA Activity Dates */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Order details */}

          {/* begin::Audience details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>T/TA Activity Audience</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::T/TA Activity Dates */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>Populations</legend>

                      <LookupCheckboxes register={register} lookupType="ServiceAudience_Populations" formFieldName="lookup_ServiceAudience_Populations" />
                    </fieldset>

                    {/* end::Input */}
                  </div>

                  {/* begin::Inputs */}
                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset>
                      <legend>Additional Audience</legend>
                      <LookupCheckboxes
                        register={register}
                        lookupType="ServiceAudience_Additional"
                        formFieldName="lookup_ServiceAudience_Additional"
                        onFieldChanged={(val, isChecked) => {
                          if (val === "SAADD_7") {
                            setAdditionalAudienceOtherChecked(isChecked);
                          }
                        }}
                      />
                      <div className={additionalAudienceOtherChecked === false ? "topicothertextbox sr-only" : "topicothertextbox"}>
                        <label className="sr-only" htmlFor="additionalaudienceother">
                          Other:
                        </label>
                        <input {...register("additionalAudienceOther")} className="form-control" id="additionalaudienceother" />
                      </div>
                    </fieldset>
                    {/* end::Input */}
                  </div>
                </div>

                <div className="col-md-6">
                  {/* begin::fieldset */}
                  <fieldset>
                    <legend>Coaching for Success?</legend>

                    {/* begin::Input */}
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="coachingForSuccess" {...register("coachingForSuccess")} />
                      <label className="form-check-label" htmlFor="coachingForSuccess">
                        Yes, this is a Coaching for Success T/TA Activity
                      </label>
                    </div>
                  </fieldset>

                  {/* end::Input */}
                </div>
                {/* end::Row */}
              </div>
              {/* end::T/TA Activity Dates */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Audience details */}

          {/* begin::Client details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>T/TA Activity Clients Invited</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::T/TA Activity Regions & Grantees */}
              <GranteeSelections register={register} control={control} setValue={setValue} getValues={getValues} formFieldName="granteeSelections" />
              {/* end::T/TA Activity Regions & Grantees */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Client details */}

          {/* begin::Clients Attended details */}
          {(isActivityApproved(activityData) || activityData?.approvalWorkflowState.currentStatus === RecordStatus.Withdrawn) && (
            <Box className="card card-flush pb-2">
              {/* begin::Card header */}
              <div className="card-header">
                <div className="card-title">
                  <h2>T/TA Activity Clients Attended</h2>
                </div>
              </div>
              {/* end::Card header */}

              {/* begin::Card body */}
              {/* begin::T/TA Activity Clients Attended */}
              {activityType && activityType == ActivityType.Consultation && (
                <Box component="div" className="card-body">
                  <GranteeAttendanceConsultation activityId={activityId} control={control} getValues={getValues} setValue={setValue} />
                </Box>
              )}
              {activityType && activityType == ActivityType.Event && (
                <Box component="div" className="card-body">
                  <GranteeAttendanceEvent activityId={activityId} getValues={getValues} setValue={setValue} />
                </Box>
              )}
              {/* end::T/TA Activity Clients Attended */}
              {/* end::Card body */}
            </Box>
          )}
          {/* end::Clients Attended details */}

          {(activityData?.approvalWorkflowState?.currentStatus === ApprovalWorkflowStatus.Approved || activityData?.approvalWorkflowState?.currentStatus === ApprovalWorkflowStatus.Completed) && (
            <div className="card card-flush">
              {/* begin::Card header */}
              <div className="card-header">
                <div className="card-title">
                  <h2>Evaluations Qualtrics Import</h2>
                </div>
              </div>
              {/* end::Card header */}
              {/* begin::Card body */}
              <div className="card-body">
                {/* begin::Inputs */}

                <div className="d-flex flex-column 7">
                  <EvalImportWizard activityId={activityId} />
                </div>
              </div>{" "}
              {/* end::Card body */}
            </div>
          )}

          {/* begin::Client details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>T/TA Activity Staff</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::T/TA Activity Dates */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}

                  <div className="col-sm-6">
                    <label className="form-label" htmlFor="staffPlan">
                      Staff Planning and Delivering the Activity
                    </label>
                    <select id="staffPlan" {...register("managingStaffUserId" as const, { required: false })} className="form-select">
                      <option value="">Select...</option>
                      {staff?.map((user, index) => (
                        <option key={index} value={user.userId}>
                          {user.firstName} {user.lastName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-6">
                    <fieldset>
                      <legend className="mb-5">T/TA Activity Team</legend>

                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" value="COR" id="core_radio1" {...register("teamCode" as const, { required: false })} />
                        <label className="form-check-label" htmlFor="core_radio1">
                          Core Team
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" value="REG" id="reg_radio2" {...register("teamCode" as const, { required: false })} />
                        <label className="form-check-label" htmlFor="reg_radio2">
                          Regional Team
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <HourlyStaffExpense register={register} control={control} setValue={setValue} getValues={getValues} staff={staff} lookups={lookups} />
                </div>
                {/* end::Row */}
              </div>
              {/* end::T/TA Activity Dates */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Client details */}

          {/* begin::Expenses */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>T/TA Activity Expenses</h2>
              </div>

              <a href="https://www.gsa.gov/travel/plan-book/per-diem-rates" target="_blank" className="btn btn-link align-self-center">
                GSA Rates Lookup
              </a>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::T/TA Activity Dates */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  <DailyStaffExpense register={register} control={control} setValue={setValue} getValues={getValues} staff={staff} lookups={lookups} />
                  <TransportationStaffExpense register={register} control={control} setValue={setValue} getValues={getValues} staff={staff} lookups={lookups} />
                  <MileageStaffExpense register={register} control={control} setValue={setValue} getValues={getValues} staff={staff} lookups={lookups} />

                  <h3 className="h4 mb-0">Materials</h3>

                  <div className="col-sm-12">
                    <table className="table table-stripe">
                      <thead className="table-light">
                        <tr className="fw-bold">
                          <th scope="col" className="ps-1" style={{ width: "25%" }}>
                            Estimated Total
                          </th>
                          <th scope="col" className="ps-1">
                            Actual Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <div className="col-6 col-sm-3">
                      <Controller
                        name={"estimatedMaterialsCost"}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat
                            className="form-control"
                            prefix={"$"}
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            defaultValue={0}
                            value={value}
                            allowLeadingZeros
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              let { floatValue } = values;
                              //if (floatValue !== undefined && values.value === ".") {
                              //  floatValue = 0;
                              //}
                              return floatValue !== undefined ? floatValue < 1000000 : true;
                            }}
                            onValueChange={(e: any) => setValue("estimatedMaterialsCost", parseFloat(e.value.replace(/[^0-9.-]/g, "")))}
                          />
                        )}
                      />
                    </div>

                    <div className="col-6 col-sm-3">
                      <Controller
                        name={"materialsCost"}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat
                            className="form-control"
                            prefix={"$"}
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            defaultValue={0}
                            value={value}
                            allowLeadingZeros
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              let { floatValue } = values;
                              //if (floatValue !== undefined && values.value === ".") {
                              //  floatValue = 0;
                              //}
                              return floatValue !== undefined ? floatValue < 1000000 : true;
                            }}
                            onValueChange={(e: any) => setValue("materialsCost", parseFloat(e.value.replace(/[^0-9.-]/g, "")))}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                {/* end::Row */}
              </div>
              {/* end::T/TA Activity Dates */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::Expenses */}

          {/* begin::T/TA Activity Additional Info */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>T/TA Activity Additional Information</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::T/TA Activity Additional Info */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                {/* begin::Row */}
                <div className="row g-5">
                  {/* begin::Inputs */}

                  {/*<div className="col-3">
                    <label htmlFor="actualactivityhours" className="form-label">
                      Actual Delivery Hours
                    </label>
                    <input type="number" className="form-control" id="actualactivityhours" step="any" {...register("actualActivityHours" as const, { required: false })} />
                    </div>*/}
                </div>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="involvement" className="form-label">
                      Grantee Involvement
                    </label>
                    <textarea className="form-control" rows={3} id="involvement" {...register("granteeInvolvement" as const, { required: false })}></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="special" className="form-label">
                      Special Needs
                    </label>
                    <textarea className="form-control" rows={3} id="special" {...register("specialNeeds" as const, { required: false })}></textarea>
                  </div>

                  {/* end::Inputs */}
                </div>
                {/* end::Row */}
              </div>
              {/* end::T/TA Activity Additional Info */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::T/TA Activity details */}

          <FocusAreaCard register={register} setValue={setValue} getValues={getValues} />

          {/* begin::T/TA Activity details */}
          <div className="card card-flush pb-2">
            {/* begin::Card header */}
            <div className="card-header">
              <div className="card-title">
                <h2>Notes & Comments</h2>
              </div>
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body">
              {/* begin::T/TA Activity Details */}
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row g-5">
                  {/* begin::Inputs */}
                  <div className="col-12">
                    <label htmlFor="comment" className="form-label">
                      Notes & Comments
                    </label>
                    <textarea className="form-control" rows={3} id="comment" {...register("comment" as const, { required: false })}></textarea>
                  </div>
                  <div id="Comments" className="card-body p-0 tab-pane fade active show" role="tabpanel">
                    <Comments comments={activityData?.comments} />
                  </div>
                </div>
                {/* end::Row */}
              </div>
              {/* end::T/TA Activity Details */}
            </div>
            {/* end::Card body */}
          </div>
          {/* end::T/TA Activity details */}

          <div className="d-flex justify-content-between bd-highlight mb-3">
            <div className="w-100">
              <button type="submit" id="" className="btn btn-primary me-5" disabled={submitDisabled}>
                <span className="indicator-label">Save &amp; Close</span>
              </button>

              <a onClick={goBack} className="btn btn-bg-light btn-active-color-gray-900 me-5">
                Cancel
              </a>

              {activityData?.approvalWorkflowState?.currentStatus == ApprovalWorkflowStatus.Approved && (
                <ApprovalDialog
                  statusStyle={"warning"}
                  buttonText={"Mark as Withdrawn"}
                  dialogTitle={"Withdraw T/TA Activity"}
                  dialogContentText={"Are  you sure want to mark this T/TA Activity as Withdrawn"}
                  buttonIcon={""}
                  submitButtonText={"Mark as Withdrawn"}
                  submissionStatus={ApprovalWorkflowStatus.Withdrawn}
                  onSubmit={submitApprovalWorkflow}
                  buttonSizeClass="btn-lg hs-float-right"
                  buttonSpanClassName="hs-float-right"
                />
              )}
            </div>

            {activityData?.id != null && activityData?.canDelete === true && (
              <div>
                <button type="button" id="delete_button" className="btn btn-danger" onClick={handleDeleteDialog}>
                  <span className="indicator-label">Delete</span>
                </button>
              </div>
            )}
          </div>
        </div>
        {/* end::Main column */}
      </form>
    </>
  );
};
