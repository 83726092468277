import React, { useRef, useState, useEffect } from "react";
import { DataGrid, GridToolbarQuickFilter, GridFilterForm, GridToolbar, GridColDef, GridToolbarContainer, gridClasses, GridSortModel, GridSortDirection } from "@mui/x-data-grid";
import { TextField, CircularProgress } from "@mui/material";

const CustomToolbar = (props: any) => (
  <div>
    <GridToolbarContainer></GridToolbarContainer>
  </div>
);

interface ServerSideDataGridProps {
  rows: any[];
  columns: GridColDef[];
  pageSize?: number;
  noRowsLabel?: string;
  sortModel?: GridSortModel | undefined;
  page?: number;
  setPage: (newPageNum: number) => void;
  onSearch: (searchVal: string | undefined) => void;
  searchVal?: string;
  totalNumRows?: number;

  setOrder: (orderDir?: string, orderColumn?: string) => void;
  isLoading: boolean;
}

const ServerSideDataGrid: React.FC<ServerSideDataGridProps> = ({ rows, columns, noRowsLabel, sortModel, page, setPage, onSearch, searchVal, totalNumRows, setOrder, isLoading }) => {
  const [searchId, setSearchId] = useState<string>("");
  const myRef = useRef<any>();
  const [filterModel, setFilterModel] = useState(searchVal);
  const [debouncedSearchText, setDebouncedSearchText] = useState(filterModel);

  useEffect(() => {
    setSearchId(myRef?.current?.querySelector('[type="search"]')?.id);
  }, [rows]);

  useEffect(() => {
    onSearch(debouncedSearchText);
  }, [debouncedSearchText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(filterModel);
    }, 500); // Adjust delay as needed (500ms is a good start)

    return () => {
      clearTimeout(handler);
    };
  }, [filterModel]);

  const handleColumnOrderChange = (orderDetails: GridSortModel) => {
    if (orderDetails != null && orderDetails.length > 0) {
      let orderDir: GridSortDirection = orderDetails[0].sort;

      setOrder(orderDetails[0].field, orderDir?.toString());
    }
  };

  return (
    <div style={{ width: "100%" }} ref={myRef}>
      <label className="sr-only" htmlFor={searchId}>
        Search
      </label>
      <TextField
        value={filterModel}
        onChange={(e) => setFilterModel(e.target.value)} // Update filter model
        placeholder="Search"
        style={{ padding: "5px" }}
        size="small"
      />

      <DataGrid
        autoHeight
        density="comfortable"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          [`& .${gridClasses.columnHeader}`]: {
            fontSize: "1rem",
            fontWeight: 600,
            color: "#222222",
          },
          [`& .${gridClasses.row}`]: {
            minHeight: "4rem !important",
          },
          [`& .${gridClasses.row}:hover`]: {
            bgcolor: "#fff",
          },
          [`& .${gridClasses.cell}`]: {
            fontSize: "1rem",
            fontWeight: 500,
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiTablePagination-selectLabel": {
            marginTop: "1rem",
          },
          "& .MuiTablePagination-displayedRows": {
            marginTop: "1rem",
          },
          boxShadow: 0,
          border: 0,
        }}
        pagination={true}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        rowCount={totalNumRows}
        page={page}
        onSortModelChange={handleColumnOrderChange}
        sortingMode="server"
        rows={rows}
        columns={columns}
        localeText={{
          noRowsLabel: noRowsLabel ? noRowsLabel : "No rows",
          noResultsOverlayLabel: "No results found.",
        }}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 200}
        initialState={{
          pagination: {
            pageSize: 25,
          },
          sorting: {
            sortModel: sortModel,
          },
        }}
        rowsPerPageOptions={[]}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        loading={isLoading}
      />
    </div>
  );
};

export default ServerSideDataGrid;
